<template>
  <div class="modal-background small" @click.self="$emit('close')">
    <div class="modal">
      <h1 class="title-1">{{ $t('auth.newDisplayName') }}</h1>
      <Input v-model="displayName" :placeholder="$t('auth.newDisplayName')" />
      <div class="buttons-container">
        <button class="secondary-button" @click="$emit('close')">
          {{ $t('cancel') }}
        </button>
        <button class="primary-button" @click="updateDisplayName" :disabled="!displayName">
          {{ $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input.vue';

export default {
  name: 'DisplayNameModal',
  components: {
    Input,
  },
  emits: ['close', 'updateDisplayName'],
  data() {
    return {
      displayName: '',
    };
  },
  mounted() {
    document.documentElement.classList.add('no-scroll');
  },
  methods: {
    async updateDisplayName() {
      if (!this.displayName) return;
      await this.$store.dispatch('auth/updateDisplayName', {
        displayName: this.displayName,
      });
      this.$emit('close');
    },
  },
  unmounted() {
    document.documentElement.classList.remove('no-scroll');
  },
};
</script>

<style scoped lang="scss"></style>
