<template>
  <div class="view grid-view">
    <div class="content">
      <div class="title fade">
        <h1 class="title-1">
          {{ sewistaMode ? $t('projects.sewingProjects') : $t('projects.knittingProjects') }}
        </h1>
        <button
          class="filters"
          @click="openFilters"
          v-if="currentList.length || filteredResult"
          :class="{ active: filteredResult }"
        >
          <Filter />
        </button>
      </div>
      <CollectionsList v-if="currentList.length || filteredResult" />

      <div class="projects list-grid" v-if="currentList.length">
        <Card
          v-for="(project, index) in currentList"
          :key="project"
          :big="index % 5 === 0 || !index"
          :small="index % 3 === 0"
          :cardContent="project"
          :routeName="sewistaMode ? 'project' : 'knittingProject'"
          :storeName="currentStore"
        />
      </div>
      <div class="projects list-grid empty" v-else>
        <img
          :src="sewistaMode ? SewingProjectIllustration : KnittingProjectIllustration"
          alt="projects"
          class="illustration"
        />
        <Loader v-if="fetching && !filteredResult" class="loader" />
        <p class="description" v-else>
          {{ filteredResult ? $t('projects.noFilteredResult') : $t('projects.noProjects') }}
        </p>
        <button
          class="primary-button"
          @click="createProject"
          v-if="!filteredResult && !fetching"
          :class="{ disabled: offline }"
        >
          <Cross />{{ $t('projects.newProject') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { analytics } from '@/utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Loader from '@/assets/svg/loader.svg';

import Card from '@/components/Card.vue';
import Filter from '@/assets/svg/filter.svg';
import Cross from '@/assets/svg/cross.svg';
import SewingProjectIllustration from '@/assets/illustration/sewing-project.png';
import KnittingProjectIllustration from '@/assets/illustration/knitting-project.png';

import CollectionsList from '@/components/CollectionsList.vue';

export default {
  name: 'Projects',
  components: {
    Card,
    Filter,
    Cross,
    CollectionsList,
    Loader,
  },
  data() {
    return {
      SewingProjectIllustration,
      KnittingProjectIllustration,
      pageSize: 8,
      pageNumber: 0,
      fetching: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
      filteredProjects: 'projects/filteredData',
      filteredKnittingProjects: 'knittingProjects/filteredData',
      projectsFilters: 'projects/filters',
      knittingProjectsfilters: 'knittingProjects/filters',
      projectsDefaultFilters: 'projects/defaultFilters',
      knittingProjectsDefaultFilters: 'knittingProjects/defaultFilters',
      projectsEndFetching: 'projects/endFetching',
      knittingProjectsEndFetching: 'knittingProjects/endFetching',
      collectionEndFetching: 'projects/collectionEndFetching',
      offline: 'auth/offline',
    }),
    currentList() {
      return this.sewistaMode ? this.filteredProjects : this.filteredKnittingProjects;
    },
    currentFilters() {
      return this.sewistaMode ? this.projectsFilters : this.knittingProjectsfilters;
    },
    currentStore() {
      return this.sewistaMode ? 'projects' : 'knittingProjects';
    },
    filteredResult() {
      return !!Object.values(this.currentFilters).filter((filter) => filter && filter.length)
        .length;
    },
    endFetching() {
      return this.sewistaMode ? this.projectsEndFetching : this.knittingProjectsEndFetching;
    },
  },
  watch: {
    async sewistaMode() {
      if (
        !this.currentList.length ||
        (this.currentList.length < this.pageSize && !this.endFetching)
      ) {
        this.fetching = !this.endFetching;
        await this.$store.dispatch(`${this.currentStore}/getPaginatedProjects`, { pageNumber: 0 });
        this.pageNumber = 0;
        this.fetching = false;
      }

      //GA
      logEvent(analytics, 'screen_view', {
        firebase_screen: this.sewistaMode ? 'Projects' : 'KnittingProjects',
        firebase_screen_class: this.sewistaMode ? 'Projects' : 'KnittingProjects',
      });
    },
  },
  async created() {
    if (
      !this.currentList.length ||
      (this.currentList.length < this.pageSize && !this.endFetching)
    ) {
      this.fetching = !this.endFetching;
      await this.$store.dispatch(`${this.currentStore}/getPaginatedProjects`, { pageNumber: 0 });
      this.fetching = false;
    }
    if (!this.collectionEndFetching) {
      await this.$store.dispatch('projects/getAllCollections');
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);

    //GA
    logEvent(analytics, 'screen_view', {
      firebase_screen: this.sewistaMode ? 'Projects' : 'KnittingProjects',
      firebase_screen_class: this.sewistaMode ? 'Projects' : 'KnittingProjects',
    });
  },
  methods: {
    onScroll() {
      this.updateTitle();
      this.pagination();
    },
    updateTitle() {
      const statusbarHeight = Number(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--safe-area-top')
          .replace('px', '')
      );
      const titlePosition = document.querySelector('.title').getBoundingClientRect().top;

      if (
        titlePosition <= statusbarHeight &&
        !document.querySelector('.title').classList.contains('small')
      ) {
        document.querySelector('.title').classList.add('small');
      } else if (document.documentElement.scrollTop === 0) {
        document.querySelector('.title').classList.remove('small');
      }
    },
    async openFilters() {
      await this.$store.dispatch('modals/setModal', {
        modalType: 'FiltersModal',
        modalData: {
          filters: this.currentFilters,
          storeModule: this.sewistaMode ? 'projects' : 'knittingProjects',
          defaultFilters: this.sewistaMode
            ? this.projectsDefaultFilters
            : this.knittingProjectsDefaultFilters,
        },
      });
    },
    async createProject() {
      if (this.offline) return;
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'ProjectCreation' : 'KnittingProjectCreation',
        modalData: {},
      });
    },
    async pagination() {
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = documentHeight - window.innerHeight - window.scrollY;

      if (scrollPosition < 600 && !this.fetching && !this.endFetching) {
        this.fetching = true;
        this.pageNumber += 1;
        await this.$store.dispatch(`${this.currentStore}/getPaginatedProjects`, {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.fetching = false;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped></style>
