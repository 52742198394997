<template>
  <div class="view">
    <div class="content">
      <h1 class="title-1">{{ $t('auth.hello') }}</h1>
      <p class="welcome-paragraph">{{ $t('auth.welcomeBack') }}</p>
      <div class="form">
        <Input
          class="form-input"
          v-model="email"
          :placeholder="$t('auth.email')"
          :label="$t('auth.email')"
          type="email"
        />
        <Input
          class="form-input"
          v-model="password"
          :placeholder="$t('auth.password')"
          :label="$t('auth.password')"
          type="password"
          @keypress.enter="loginPassword"
        />
        <button class="link-font" @click="forgetingPassword = true">
          {{ $t('auth.forgetPassword') }}
        </button>
        <button @click="loginPassword" class="primary-button fullwidth">
          {{ $t('auth.login') }}
        </button>
      </div>

      <ProviderAuth />

      <p class="signup">
        {{ $t('auth.noAccount') }}
        <router-link :to="{ name: 'register' }" class="link-font">
          {{ $t('auth.iSignUp') }}
        </router-link>
      </p>
    </div>
    <Teleport to="body">
      <transition name="small-modal">
        <ForgetPasswordModal
          v-if="forgetingPassword"
          @close="forgetingPassword = false"
        /> </transition
    ></Teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setKeyboard, killKeyboard } from '@/utils/native';

import Input from '@/components/inputs/Input.vue';
import ProviderAuth from '@/components/ProviderAuth.vue';
import ForgetPasswordModal from '@/components/modals/ForgetPasswordModal.vue';

export default {
  name: 'Login',
  components: {
    Input,
    ProviderAuth,
    ForgetPasswordModal,
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      forgetingPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
  },
  async mounted() {
    await setKeyboard('.view .content');
  },
  methods: {
    async loginPassword() {
      try {
        await this.$store.dispatch('auth/loginPassword', {
          email: this.email,
          password: this.password,
        });
        this.$router.push({ name: 'projects' });
      } catch (e) {
        if (e.message.includes('wrong-password') || e.message.includes('user-not-found')) {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: this.$t('toasts.wrongCredential'),
          });
          this.errorMessage = this.$t('toasts.wrongCredential');
        } else if (e.message.includes('invalid-email')) {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: this.$t('toasts.invalidEmail'),
          });
          this.errorMessage = this.$t('toasts.invalidEmail');
        } else {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: e.message,
          });
          this.errorMessage = e.message;
        }
      }
    },
  },
  unmounted() {
    killKeyboard();
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  align-items: center;
  flex-direction: column;

  .link-font {
    align-self: flex-end;
    margin: -12px 0 24px;
  }

  .form-input {
    width: 100%;
  }
}

.title-1 {
  text-align: center;
}

.welcome-paragraph {
  text-align: center;
  margin-bottom: 48px;
  height: 32px;
}

.signup {
  text-align: center;
  .link-font {
    font-size: 16px;
    display: inline;
  }
}
</style>
