<template>
  <div class="input-container" :class="{ error: errorMessage }">
    <label class="label">{{ label }}</label>
    <div class="color-picker">
      <div
        v-for="color in colors"
        :key="color.name"
        :class="{
          selected: modelValue.includes(color.name),
        }"
        class="color"
        @click="
          () => {
            selectColor(color);
            errorMessage = '';
          }
        "
      >
        <div
          class="preview"
          :style="{ background: color.color }"
          :class="{
            multicoloured: color.name === 'Multicoloured',
            white: color.name === 'White',
          }"
        >
          <Check />
        </div>
      </div>
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { colorPalette } from '@/utils/colorPalette';
import Check from '@/assets/svg/check.svg';

export default {
  name: 'ColorPicker',
  components: {
    Check,
  },
  props: {
    modelValue: {
      type: [String, Array],
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      errorMessage: '',
    };
  },
  computed: {
    colors() {
      return colorPalette.filter((palette) => palette.color);
    },
  },
  watch: {
    modelValue(colors) {
      if (colors.length) {
        this.errorMessage = '';
      }
    },
  },
  methods: {
    selectColor(color) {
      let colors = [...this.modelValue];
      if (colors.includes(color.name)) {
        colors = colors.filter((c) => c !== color.name);
      } else {
        colors.push(color.name);
      }
      this.$emit('update:modelValue', colors);
    },
    check() {
      if (this.required && !this.modelValue.length) {
        this.errorMessage = this.$t('mandatoryField');
      }

      return !!this.errorMessage;
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;
}

.color-picker {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  .color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    flex-shrink: 0;
    border: 2px solid white;
    transition: 0.3s;

    .preview {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &.multicoloured {
        background: linear-gradient(
          180deg,
          #56cc6c 4.69%,
          #83cef8 19.27%,
          #991afc 33.85%,
          #ff72f9 50%,
          #ff3528 62.5%,
          #ff7121 81.77%,
          #ffc328 93.75%
        ) !important;
      }

      &.white {
        border: 1px solid $lightgrey-color;

        svg {
          color: $lightgrey-color;
        }
      }

      svg {
        color: $white-color;
        width: 8px;
        opacity: 0;
        transition: 0.3s;
      }
    }

    &.selected {
      border-color: $lightgrey-color;

      .preview {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
</style>
