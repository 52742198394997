import actions from './actions';
import getters from './getters';

const state = {
  modalType: null,
  modalData: null,
  modalResolve: () => {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
