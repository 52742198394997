<template>
  <div class="step">
    <div class="illustration">
      <img :src="Illustration" alt="collections" />
    </div>
    <h1 class="title-1">{{ $t('onboarding.step4Title') }}</h1>
    <p>
      Imaginez une garde robe cohérente en classant vos projets par collections.
      <br />À vous les total looks assortis !
    </p>
  </div>
</template>

<script>
import Illustration from '@/assets/illustration/collection.jpg';

export default {
  name: 'Step4',
  data() {
    return {
      Illustration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
