<template>
  <div class="stars" ref="stars">
    <Star
      v-for="star in 5"
      :key="star"
      class="star"
      :data-id="star"
      @click="$emit('update:modelValue', star)"
      :class="{ selected: star <= modelValue }"
    />
  </div>
</template>

<script>
import { hapticsImpact } from '@/utils/native';
import Star from '@/assets/svg/etoile.svg';

export default {
  name: 'Stars',
  components: {
    Star,
  },
  props: {
    modelValue: {
      type: Number,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      scoring: false,
      touchStartPositon: 0,
      starsRightPosition: 0,
    };
  },
  mounted() {
    this.starsRightPosition = this.$refs.stars.getBoundingClientRect().right;
    document.addEventListener('touchstart', this.touchStart);
    document.addEventListener('touchmove', this.touchmove);
    document.addEventListener('touchend', this.touchend);
  },
  methods: {
    touchStart(e) {
      this.scoring = document.querySelector('.stars').contains(e.target);
      this.touchStartPositon = e.touches[0].clientX;
    },
    async touchmove(e) {
      if (this.scoring) {
        const currentPosition = e.touches[0].clientX;
        const score = Math.floor(currentPosition / (this.starsRightPosition / 5));
        if (score !== this.modelValue) {
          await hapticsImpact('MEDIUM');
          this.$emit('update:modelValue', score);
        }
      }
    },
    touchend() {
      this.scoring = false;
    },
  },
  unmounted() {
    document.removeEventListener('touchstart', this.touchStart);
    document.removeEventListener('touchmove', this.touchmove);
    document.removeEventListener('touchend', this.touchend);
  },
};
</script>

<style scoped lang="scss">
.stars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;

  .star {
    color: $lightgrey-color;
    width: 48px;
    transition: 0.3s;

    &.selected {
      color: $yellow-color;
    }
  }

  &.done {
    .star {
      color: $white-color;

      &.selected {
        color: $pink-color;
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .stars {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
