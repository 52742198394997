<template>
  <div class="step">
    <div class="illustration">
      <img :src="Illustration" alt="patterns" />
    </div>
    <h1 class="title-1">{{ $t('onboarding.step2Title') }}</h1>
    <p>
      Ajoutez vos patrons et retrouvez-les à n’importe quel moment sur l’application. <br />Utile
      quand on veut craquer en mercerie !
    </p>
  </div>
</template>

<script>
import Illustration from '@/assets/illustration/patterns.jpg';

export default {
  name: 'Step2',
  data() {
    return {
      Illustration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
