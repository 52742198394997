import { createStore } from 'vuex';
import auth from '@/store/auth';
import patterns from '@/store/patterns';
import fabrics from '@/store/fabrics';
import projects from '@/store/projects';
import modals from '@/store/modals';
import toasts from '@/store/toasts';
import wools from '@/store/wools';
import knittingPatterns from '@/store/knittingPatterns';
import knittingProjects from '@/store/knittingProjects';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    patterns,
    fabrics,
    projects,
    modals,
    toasts,
    wools,
    knittingPatterns,
    knittingProjects,
  },
});
