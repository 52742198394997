<template>
  <div class="input-container" :class="{ error: errorMessage, required: required, loading }">
    <label class="label">{{ label }}</label>
    <div class="picture-input" :class="{ filled: pictureUrl }">
      <div class="delete" v-if="pictureUrl" @click="deleteImage"><Trash /></div>
      <FileButton
        class="picture-buttons"
        @setPicture="setPicture"
        @setLoader="() => (loading = true)"
        :buttonStyle="true"
        :name="name"
        v-else-if="!pictureUrl"
      />
      <Loader class="loader" v-if="loading" />
      <img
        ref="img"
        :src="pictureUrl"
        alt="picture"
        v-if="pictureUrl"
        accept="image/png, image/jpeg"
      />
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { FastAverageColor } from 'fast-average-color';
import { RGBToHSL, getPrimaryColor } from '@/utils/colorPalette';
import { getPlatform } from '@/utils/native';

import Trash from '@/assets/svg/poubelle.svg';
import Loader from '@/assets/svg/loader.svg';
import FileButton from '@/components/FileButton.vue';

export default {
  name: 'PictureInput',
  components: {
    Trash,
    FileButton,
    Loader,
  },
  props: {
    modelValue: {
      type: File,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    url: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    colorDetection: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'updateColor', 'deletePicture'],
  data() {
    return {
      pictureUrl: '',
      fac: null,
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    isAndroid() {
      return getPlatform() === 'android';
    },
  },
  watch: {
    url() {
      this.pictureUrl = this.url;
    },
  },
  mounted() {
    this.fac = new FastAverageColor();
    if (this.url) {
      this.pictureUrl = this.url;
    }
  },
  methods: {
    async setPicture({ pictureFile, pictureUrl, pictureAndroidUrl }) {
      this.pictureUrl = pictureUrl || pictureAndroidUrl;

      this.$emit('update:modelValue', pictureFile);
      if (this.name === 'sizeGuide') {
        this.$emit('deletePicture', false);
      }
      this.errorMessage = '';
      if (this.colorDetection) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.getMainColor();
          });
        }, 1000);
      } else {
        this.loading = false;
      }
    },
    async getMainColor() {
      const color = await this.fac.getColorAsync(this.$refs.img, {
        algorithm: 'dominant',
        mode: 'precision',
      });

      const hslColor = RGBToHSL(color.value[0], color.value[1], color.value[2]);
      const primaryColor = getPrimaryColor(hslColor);

      this.$emit('updateColor', [primaryColor]);
      this.loading = false;
    },
    deleteImage() {
      this.pictureUrl = '';
      this.$emit('update:modelValue', undefined);
      if (this.name === 'sizeGuide') {
        this.$emit('deletePicture', true);
      }
    },
    check() {
      if (this.required && !this.pictureUrl && !this.url) {
        this.errorMessage = this.$t('mandatoryField');
      }

      return !!this.errorMessage;
    },
  },
  unmounted() {
    this.fac.destroy();
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;

  &.error {
    .picture-input {
      border-color: $red-color;
      background: mix($red-color, $white-color, 10%);
    }
  }

  &.loading {
    :deep(.picture-buttons) {
      .picture-button {
        opacity: 0;
      }
    }
  }
}

.picture-input {
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
  border: 2px dashed var(--primary-color);

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    appearance: none;
    opacity: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .delete {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: $white-color;
    color: $red-color;
    z-index: 2;

    svg {
      width: 14px;
    }
  }

  :deep(.picture-buttons) {
    .picture-button {
      width: 240px !important;
      z-index: 2;
    }
  }

  &.filled {
    border: none;
  }

  .loader {
    position: absolute;
    color: var(--primary-color);
    animation: loader-rotation 1s linear infinite;
    width: 24px;
  }
}
</style>
