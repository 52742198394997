export default {
  patterns: ({ patterns }) => Object.values(patterns),
  filteredData: ({ filters, patterns }) => {
    const allPatterns = Object.values(patterns);

    const filteredData = allPatterns
      .filter(
        (pattern) =>
          pattern.name.toUpperCase().includes(filters.search.toUpperCase()) ||
          pattern.brand.toUpperCase().includes(filters.search.toUpperCase())
      )
      .filter(
        (pattern) => !filters.scoreFilters.length || filters.scoreFilters.includes(pattern.score)
      )
      .filter(
        (pattern) =>
          !filters.clothTypesFilters.length ||
          filters.clothTypesFilters.find((type) => pattern.clothTypes.includes(type))
      )
      .filter(
        (pattern) =>
          !filters.targets.length || filters.targets.find((type) => pattern.targets.includes(type))
      )
      .filter((pattern) => !filters.wishlist || (filters.wishlist && pattern.wishlist));

    return filteredData;
  },
  filters: ({ filters }) => filters,
  defaultFilters: ({ defaultFilters }) => defaultFilters,
  endFetching: ({ endFetching }) => endFetching,
  resizingPicture: ({ resizingPicture }) => resizingPicture,
  resizingPictureError: ({ resizingPictureError }) => resizingPictureError,
};
