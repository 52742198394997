<template>
  <div class="form">
    <Input
      v-model="wool.name"
      :placeholder="$t('fabrics.fabricName')"
      :label="$t('fabrics.fabricName')"
      :required="true"
      ref="name"
    />
    <Input
      v-model="wool.reference"
      :placeholder="$t('fabrics.reference')"
      :label="$t('fabrics.reference')"
    />
    <Input v-model="wool.brand" :placeholder="$t('fabrics.brand')" :label="$t('fabrics.brand')" />
    <SingleCheckbox :label="$t('fabrics.wishlistWool')" v-model="wool.wishlist" />
    <PictureInput
      v-model="woolFile"
      name="visual"
      :url="wool.pictureUrl"
      :colorDetection="true"
      :label="$t('fabrics.woolPicture')"
      @updateColor="updateColor"
      :required="true"
      ref="picture"
    />
    <ColorPicker
      v-model="wool.color"
      :label="$t('fabrics.woolColor')"
      :required="true"
      ref="color"
    />
    <Input
      v-model="wool.material"
      :placeholder="$t('fabrics.material')"
      :label="$t('fabrics.material')"
      :required="true"
      ref="material"
    />
    <Input
      v-model="wool.number"
      :label="$t('fabrics.woolNumber')"
      ref="number"
      type="number"
      :min="0"
      placeholder="0"
    />
    <Input
      v-model="wool.yarnLength"
      :label="$t('fabrics.yarnLength')"
      type="number"
      placeholder="0"
      :min="0"
    />
    <Input
      v-model="wool.yarnWeight"
      :label="$t('fabrics.yarnWeight')"
      type="number"
      placeholder="0"
      :min="0"
    />
    <Radios v-model="wool.type" :options="woolTypes" :label="$t('fabrics.woolType')" />
    <Input
      v-model="wool.recommandedNeedles"
      :label="$t('fabrics.recommandedNeedles')"
      placeholder="4"
    />
    <Textarea
      v-model="wool.sample"
      :label="$t('fabrics.sample')"
      :placeholder="$t('fabrics.sample')"
    />
    <Textarea
      v-model="wool.comment"
      :label="$t('fabrics.comment')"
      :placeholder="$t('fabrics.comment')"
    />
    <p class="mandatory-fields">{{ $t('mandatoryFields') }}</p>
  </div>
</template>

<script>
import { materials, woolTypes } from '@/interfaces/enums';

import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import Radios from '@/components/inputs/Radios.vue';
import PictureInput from '@/components/inputs/PictureInput.vue';
import ColorPicker from '@/components/inputs/ColorPicker.vue';
import SingleCheckbox from '@/components/inputs/SingleCheckbox.vue';

export default {
  name: 'WoolForm',
  components: {
    Input,
    Textarea,
    PictureInput,
    ColorPicker,
    SingleCheckbox,
    Radios,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['formValidity', 'update:modelValue'],
  data() {
    return {
      woolFile: null,
      error: false,
      materials,
      woolTypes,
    };
  },
  computed: {
    isFormValid() {
      return !!(
        this.wool &&
        this.wool.name &&
        this.wool.color &&
        (this.woolFile || this.wool.pictureUrl) &&
        this.wool.material
      );
    },
    wool: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
  },
  watch: {
    isFormValid(value) {
      this.$emit('formValidity', value);
    },
  },
  created() {
    this.$emit('formValidity', this.isFormValid);
  },
  methods: {
    updateColor(color) {
      this.wool.color = color;
    },
    checkForm() {
      const requiredFields = ['name', 'picture', 'color', 'material'];

      let error = false;
      requiredFields.forEach((field) => {
        error = this.$refs[field].check() || error;
      });

      return error;
    },
  },
};
</script>

<style scoped lang="scss">
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  p {
    font-size: 14px;
  }
}
</style>
