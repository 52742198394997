<template>
  <div class="input-container">
    <label class="label" v-if="label && items.length">{{ label }}</label>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in items"
        :key="item.name"
        :class="{ checked: item.checked, sewing: sewistaMode }"
      >
        <div class="line">
          <div class="checkbox" @click="checkItem(index)" v-if="checkbox"></div>
          <input
            type="text"
            v-model.lazy="item.name"
            :placeholder="placeholder"
            @focus="addItem(index)"
            @blur="$emit('update:modelValue', items)"
            :disabled="disabled"
            v-if="!disabled"
          />
          <p v-else class="name">{{ item.name }}</p>
          <Cross
            class="delete"
            :class="{ visible: item.name }"
            @click="deleteItem(item)"
            v-if="!checkbox && !disabled"
          />
        </div>
        <div class="number-input">
          <div>
            <p v-if="sewistaMode" class="input-label">{{ $t('patterns.length') }}:</p>
            <input
              type="number"
              v-model="item.length"
              placeholder="0"
              pattern="\d*"
              :disabled="disabled"
              @input="checkNumber(index)"
            />
            <p class="metric">{{ metric }}</p>
          </div>
          <div v-if="sewistaMode && ((disabled && item.width) || !disabled)">
            <p class="input-label">{{ $t('patterns.width') }}:</p>
            <input
              type="number"
              v-model="item.width"
              placeholder="0"
              pattern="\d*"
              :disabled="disabled"
              @input="checkNumber(index)"
            />
            <p class="metric">{{ metric }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'ListInput',
  components: {
    Cross,
  },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
    checkbox: {
      type: Boolean,
    },
    metric: {
      type: String,
      default: 'cm',
    },
    sewistaMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    items() {
      return this.disabled ? this.modelValue.filter((item) => item.name) : this.modelValue;
    },
  },
  created() {
    if (
      !this.disabled &&
      (!this.items.length || (this.items.length && this.items[this.items.length - 1].name))
    ) {
      this.addItem(this.modelValue.length - 1);
    }
  },
  methods: {
    checkNumber(index) {
      if (this.items[index].length < 0) {
        this.items[index].length = 0;
      }
      this.$emit('update:modelValue', this.items);
    },
    addItem(index) {
      if (index === this.items.length - 1) {
        this.items.push({ name: '', length: null, id: new Date().getTime() });
      }
    },
    deleteItem(item) {
      this.$emit(
        'update:modelValue',
        this.items.filter((i) => i.id !== item.id)
      );
    },
    checkItem(index) {
      this.items[index].checked = !this.items[index].checked;
      this.$emit('update:modelValue', this.items);
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;

  .line {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.sewing {
    position: relative;
    flex-wrap: wrap;

    input[type='text'] {
      padding-right: 24px;
    }

    .name,
    .number-input {
      width: 100%;
    }

    .number-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      div {
        width: 100%;
        margin: 0;
      }
    }

    .delete {
      position: absolute;
      right: 0;
      top: 3px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 8px;
    border: 2px solid var(--primary-color);

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--primary-color);
      opacity: 0;
      transition: 0.3s;
    }
  }

  input {
    appearance: none;
    border: none;
    background: none;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: $font-semibold;
    font-family: $text-font;
    width: 100%;

    &:disabled {
      opacity: 1;
    }
  }

  .name {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: $font-semibold;
    font-family: $text-font;
    width: 100%;
  }

  .number-input {
    display: block;
    font-weight: $font-bold;

    input,
    input.placeholder {
      min-width: 40px;
      color: $text-color;
      text-align: right;
      font-weight: $font-bold;
      max-width: 50px;

      &:disabled {
        color: $text-color;
        min-width: 40px;
      }
      &::placeholder {
        font-weight: 400;
      }
    }

    div {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .input-label {
      font-size: 14px;
      color: $grey-color;
      font-weight: 400;
      line-height: 20px;
      height: 20px;
    }
  }

  .delete {
    opacity: 0;
    width: 16px;
    margin-left: auto;
    transform: rotate(45deg);
    flex-shrink: 0;
    transition: 0.3s;
    margin-left: 12px;

    &.visible {
      opacity: 1;
    }
  }

  &.checked {
    .checkbox {
      &::after {
        opacity: 1;
      }
    }
  }

  &.placeholder {
    input {
      color: mix($text-color, $white-color, 30%);
    }

    .checkbox {
      border-color: mix($text-color, $white-color, 30%);
    }
  }

  &:focus,
  &.placeholder:focus-within {
    input {
      color: var(--primary-color);
    }
  }
}
</style>
