<template>
  <div class="step">
    <div class="illustration">
      <img :src="Illustration" alt="fabrics" />
    </div>
    <h1 class="title-1">{{ $t('onboarding.step1Title') }}</h1>
    <p>
      Ajoutez vos tissus et laines préférés pour mieux gérer votre stock.
      <br />Prenez une photo, renseignez quelques infos et le tour est joué !
    </p>
  </div>
</template>

<script>
import Illustration from '@/assets/illustration/textile.jpg';

export default {
  name: 'Step1',
  data() {
    return {
      Illustration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
