import actions from './actions';
import getters from './getters';
import { cloneDeep } from 'lodash';

const state = {
  patterns: [],
  endFetching: false,
  defaultFilters: {
    search: '',
    scoreFilters: [],
    clothTypesFilters: [],
    targets: [],
    wishlist: false,
  },
  filters: {},
  resizingPicture: false,
  resizingPictureError: false,
};

state.filters = cloneDeep(state.defaultFilters);

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
