<template>
  <div class="view">
    <div class="content">
      <h1 class="title-1">Design System</h1>
      <h2 class="title-1">Buttons</h2>
      <button class="primary-button fullwidth">primary button fullwidth</button>
      <button class="primary-button">primary button</button>
      <button class="secondary-button">secondary button</button>
      <h2 class="title-1">Inputs</h2>
      <PictureInput
        v-model="file"
        name="visual"
        label="visuel"
        :colorDetection="true"
        @updateColor="(value) => (color = value)"
      />
      <ColorPicker v-model="color" />

      <Input
        v-model="name"
        :placeholder="$t('projects.projectName')"
        :label="$t('projects.projectName')"
      />
      <Select
        :label="$t('fabrics.material')"
        :options="materials"
        v-model="material"
        :placeholder="$t('fabrics.materialPlaceholder')"
      />
      <Checkboxes v-model="types" :options="clothTypes" :label="$t('patterns.patternTypes')" />
      <Radios v-model="clothType" :options="clothTypes" :label="$t('projects.patternType')" />
      <ButtonSelect v-model="statut" :options="projectStatus" label="status" />
      <ButtonSelect
        v-model="colorFilters"
        :options="colorPalette.filter((option) => option.color)"
        label="couleur multiple"
        :colorPicker="true"
        :multiple="true"
      />
      <ButtonSelect
        v-model="score"
        :options="[1, 2, 3, 4, 5]"
        label="score multiple"
        :scoring="true"
        :multiple="true"
      />
      <Textarea
        v-model="comment"
        :label="$t('projects.comment')"
        :placeholder="$t('projects.comment')"
      />
      <CheckListInput v-model="list" label="liste de course" />
      <SwitchInput v-model="knitting" :labels="[$t('sewing'), $t('knitting')]" />
      <h2 class="title-1">Titre 1</h2>
      <h2 class="title-2">Titre 2</h2>
      <p>text de paragraphe</p>
      <a href="" class="link-font">lien</a>
    </div>
  </div>
</template>

<script>
import { materials, clothTypes, projectStatus } from '@/interfaces/enums';
import { colorPalette } from '@/utils/colorPalette';

import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import Radios from '@/components/inputs/Radios.vue';
import PictureInput from '@/components/inputs/PictureInput.vue';
import Select from '@/components/inputs/Select.vue';
import Checkboxes from '@/components/inputs/Checkboxes.vue';
import ColorPicker from '@/components/inputs/ColorPicker.vue';
import ButtonSelect from '@/components/inputs/ButtonSelect.vue';
import CheckListInput from '@/components/inputs/CheckListInput.vue';
import SwitchInput from '@/components/inputs/SwitchInput.vue';

export default {
  name: 'DesignSystem',
  components: {
    Input,
    Textarea,
    Radios,
    PictureInput,
    Select,
    Checkboxes,
    ColorPicker,
    ButtonSelect,
    CheckListInput,
    SwitchInput,
  },
  data() {
    return {
      file: null,
      name: '',
      material: 0,
      clothType: 0,
      statut: 0,
      comment: '',
      types: [],
      list: [],
      color: '',
      knitting: false,
      colorFilters: [],
      score: [],
      materials,
      clothTypes,
      projectStatus,
      colorPalette,
    };
  },
};
</script>

<style scoped lang="scss">
button,
p {
  margin-bottom: 16px;
}
</style>
