<template>
  <div class="toast" :class="toast.type">
    <p class="message">{{ toast.content }}</p>
    <div class="icon" v-if="toast.type === 'success'">
      <Check />
    </div>
    <div class="icon" v-else>
      <Cross />
    </div>
    <div class="progress" :style="{ animationDuration: `${toast.duration / 1000}s` }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cross from '@/assets/svg/cross.svg';
import Check from '@/assets/svg/check.svg';

export default {
  name: 'toast',
  components: {
    Cross,
    Check,
  },
  computed: {
    ...mapGetters({
      toast: 'toasts/toast',
    }),
  },
};
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  left: 15px;
  right: 15px;
  top: calc(24px + env(safe-area-inset-top));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1010;

  .message {
    font-weight: $font-bold;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $text-color;
  }

  .progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    animation: progress 3s linear;
  }

  &.success {
    background: $success-color;

    .message {
      color: $text-color;
    }

    .icon {
      color: $text-color;
      border-color: $text-color;

      svg {
        width: 8px;
      }
    }

    .progress {
      background: #49a673;
    }
  }

  &.error {
    background: $red-color;

    .message {
      color: $white-color;
    }

    .icon {
      color: $white-color;
      border-color: $white-color;
      rotate: 45deg;

      svg {
        width: 12px;
      }
    }

    .progress {
      background: #a92219;
    }
  }

  @keyframes progress {
    0% {
      right: 0;
    }

    100% {
      right: 100%;
    }
  }
}
</style>
