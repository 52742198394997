<template>
  <div class="form">
    <Input
      v-model="pattern.name"
      :placeholder="$t('patterns.patternName')"
      :label="$t('patterns.patternName')"
      :required="true"
      ref="name"
    />
    <Input
      v-model="pattern.brand"
      :placeholder="$t('patterns.brand')"
      :label="$t('patterns.brand')"
      :required="true"
      ref="brand"
    />
    <SingleCheckbox :label="$t('patterns.wishlist')" v-model="pattern.wishlist" />

    <PictureInput
      v-model="patternFile"
      name="visual"
      :url="pattern.pictureUrl"
      :label="$t('patterns.picture')"
      :required="true"
      ref="picture"
    />
    <Checkboxes
      v-model="pattern.clothTypes"
      :options="clothTypes"
      :label="$t('patterns.patternTypes')"
      :required="true"
      ref="clothType"
    />
    <Checkboxes
      v-model="pattern.targets"
      :options="targets"
      :label="$t('patterns.patternFor')"
      :required="true"
      ref="target"
    />
    <ListInput
      v-model="pattern.recommandedFabrics"
      :label="$tc('patterns.recommandedFabricsLength', 2)"
      :placeholder="$t('patterns.footageName')"
      :sewistaMode="true"
    />
    <Input
      v-model="pattern.recommandedFabric"
      :placeholder="$t('patterns.recommandedFabric')"
      :label="$t('patterns.recommandedFabric')"
    />
    <PictureInput
      v-model="sizeGuideFile"
      name="sizeGuide"
      :url="pattern.sizesGuidePictureUrl"
      :label="$t('patterns.sizeGuide')"
      @deletePicture="deletePicture"
    />
    <Input
      v-model="pattern.recommandedSize"
      :placeholder="$t('patterns.recommanderSize')"
      :label="$t('patterns.recommanderSize')"
    />
    <div class="line">
      <p>
        {{ $t('patterns.seamAllowanceIncludes') }}
      </p>

      <SwitchInput v-model="pattern.seamAllowanceIncludes" :labels="[$t('no'), $t('yes')]" />
    </div>
    <CheckListInput v-model="pattern.sewingSupply" :label="$t('projects.sewingSupply')" />
    <Textarea
      v-model="pattern.comment"
      :label="$t('patterns.comment')"
      :placeholder="$t('patterns.comment')"
    />
    <Radios
      v-model="pattern.patternType"
      :options="patternTypes"
      :label="$t('patterns.format')"
      :required="true"
      ref="format"
    />
    <div class="line" v-if="pattern.patternType === 2">
      <p>
        {{ $t('patterns.alreadyPrinted') }}
      </p>
      <SwitchInput
        v-model="pattern.printed"
        v-if="pattern.patternType === 2"
        :labels="[$t('no'), $t('yes')]"
      />
    </div>
    <Input
      v-model="pattern.storageLink"
      :placeholder="$t('patterns.storageLink')"
      :label="$t('patterns.storageLink')"
      v-if="pattern.patternType === 2 || pattern.patternType === 5"
    />
    <Input
      v-model="pattern.formatInfo"
      :placeholder="$t('patterns.formatPlaceholder')"
      :label="$t('patterns.formatInfo')"
    />
    <p class="mandatory-fields">{{ $t('mandatoryFields') }}</p>
  </div>
</template>

<script>
import { clothTypes, targets, patternTypes } from '@/interfaces/enums.json';

import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import Checkboxes from '@/components/inputs/Checkboxes.vue';
import Radios from '@/components/inputs/Radios.vue';
import SwitchInput from '@/components/inputs/SwitchInput.vue';
import PictureInput from '@/components/inputs/PictureInput.vue';
import ListInput from '@/components/inputs/ListInput.vue';
import CheckListInput from '@/components/inputs/CheckListInput.vue';
import SingleCheckbox from '@/components/inputs/SingleCheckbox.vue';

export default {
  name: 'PatternForm',
  components: {
    Input,
    Checkboxes,
    Textarea,
    Radios,
    SwitchInput,
    PictureInput,
    ListInput,
    CheckListInput,
    SingleCheckbox,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['formValidity', 'update:modelValue'],
  data() {
    return {
      patternFile: null,
      sizeGuideFile: null,
      sizeGuideFileDeleted: false,
      clothTypes,
      targets,
      patternTypes,
    };
  },
  computed: {
    isFormValid() {
      return !!(
        this.pattern &&
        this.pattern.name &&
        this.pattern.brand &&
        (this.patternFile || this.pattern.pictureUrl) &&
        this.pattern.patternType &&
        this.pattern.clothTypes.length &&
        this.pattern.targets.length
      );
    },
    pattern: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
  },
  watch: {
    isFormValid(value) {
      this.$emit('formValidity', value);
    },
  },
  created() {
    this.$emit('formValidity', this.isFormValid);
  },
  methods: {
    checkForm() {
      const requiredFields = ['name', 'brand', 'picture', 'clothType', 'target'];

      let error = false;
      requiredFields.forEach((field) => {
        error = this.$refs[field].check() || error;
      });

      return error;
    },
    deletePicture(value) {
      this.sizeGuideFileDeleted = value;
    },
  },
};
</script>

<style scoped lang="scss">
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  p {
    font-size: 14px;
  }
}
</style>
