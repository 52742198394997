<template>
  <div class="input-container" :class="{ selected: modelValue, disabled }">
    <div class="labels">
      <p class="label" :class="{ visible: !modelValue }">{{ labels[0] }}</p>
      <p class="label" :class="{ visible: modelValue }">{{ labels[1] }}</p>
    </div>
    <div
      class="switch-input"
      @click="
        () => {
          $emit('update:modelValue', !modelValue);
          setHapstic();
        }
      "
    >
      <div class="cursor"></div>
    </div>
  </div>
</template>

<script>
import { hapticsImpact } from '@/utils/native';

export default {
  name: 'SwitchInput',
  props: {
    modelValue: {
      type: Boolean,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    async setHapstic() {
      await hapticsImpact('MEDIUM');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: max-content;

  &.disabled {
    pointer-events: none;

    .switch-input {
      display: none;
    }
  }

  .labels {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;

    .label {
      font-size: 14px;
      font-weight: $font-bold;
      color: var(--primary-color);
      opacity: 0;

      &.visible {
        opacity: 1;
      }

      &:first-child {
        margin-bottom: -7px;
      }

      &:last-child {
        margin-top: -7px;
      }
    }
  }

  &.knitting-switch {
    flex-direction: row-reverse;

    .labels {
      .label {
        color: $green-color;
      }
    }

    .switch-input {
      background: $green-color;
    }

    &.selected {
      .labels {
        .label {
          color: $pink-color;
        }
      }

      .switch-input {
        background: $pink-color;
      }
    }
  }

  &.selected {
    .switch-input {
      background: var(--primary-color);

      .cursor {
        left: calc(100% - 30px);
        right: 2px;
        transition: background 0.2s, left 0.2s ease-in 0.05s, right 0.2s ease-out;
      }
    }
  }
}

.switch-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 60px;
  height: 34px;
  border-radius: 16px;
  background: $lightgrey-color;
  border: 1px solid $white-color;
  transition: 0.5s;

  .cursor {
    position: absolute;
    left: 2px;
    right: calc(100% - 30px);
    height: 28px;
    border-radius: 28px;
    background: $white-color;
    transition: background 0.2s, left 0.2s ease-out, right 0.2s ease-in 0.05s;
  }
}
</style>
