<template>
  <div class="modal-background small" @click.self="$emit('close')">
    <div class="modal">
      <h1 class="title-1">{{ $t('auth.forgetPassword') }}</h1>
      <p class="description">{{ $t('auth.forgetPasswordDescription') }}</p>
      <Input
        class="form-input"
        v-model="email"
        :placeholder="$t('auth.email')"
        :label="$t('auth.email')"
        type="email"
      />
      <div class="buttons-container">
        <button class="secondary-button" @click="$emit('close')">
          {{ $t('cancel') }}
        </button>
        <button class="primary-button" @click="forgetPassword" :disabled="!email">
          {{ $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input.vue';

export default {
  name: 'ForgetPasswordModal',
  components: {
    Input,
  },
  emits: ['close'],
  data() {
    return {
      email: '',
    };
  },
  mounted() {
    document.documentElement.classList.add('no-scroll');
  },
  methods: {
    async forgetPassword() {
      if (!this.email) return;
      try {
        await this.$store.dispatch('auth/forgetPassword', {
          email: this.email,
        });
        this.$store.dispatch('toasts/setToast', {
          type: 'success',
          content: this.$t('toasts.resetPasswordEmailSuccess', {
            email: this.email,
          }),
        });
        this.$emit('close');
      } catch (e) {
        this.$store.dispatch('toasts/setToast', {
          type: 'error',
          content: this.$t('toasts.resetPasswordEmailError'),
        });
      }
    },
  },
  unmounted() {
    document.documentElement.classList.remove('no-scroll');
  },
};
</script>

<style scoped lang="scss"></style>
