export default {
  async setToast({ state }, { type, content, duration = 3000 }) {
    state.toast = {
      type: type,
      content: content,
      duration: duration,
    };

    setTimeout(() => {
      state.toast = null;
    }, duration);
  },
};
