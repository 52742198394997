<template>
  <div class="input-container">
    <label class="label">{{ label }}</label>
    <textarea class="input" v-model="value" :placeholder="placeholder" :disabled="disabled" />
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      errorMessage: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;

  .input {
    @include hiddeScrollbar;
    height: 152px;
    padding: 16px;
    overflow: auto;
  }

  textarea {
    resize: none;
  }
}
</style>
