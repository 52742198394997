export const getters = {
  user: ({ user }) => user,
  loggedIn: ({ loggedIn }) => loggedIn,
  sewistaMode: ({ sewistaMode }) => sewistaMode,
  offline: ({ offline }) => offline,
  resizingPicture: ({ resizingPicture }) => resizingPicture,
  resizingPictureError: ({ resizingPictureError }) => resizingPictureError,
  maintenanceActive: ({ maintenance }) => (maintenance === null ? null : maintenance?.active),
  maintenanceMessage: ({ maintenance }) => (maintenance === null ? null : maintenance?.message),
  maintenanceDate: ({ maintenance }) => (maintenance === null ? null : maintenance?.date.seconds),
  scrollPosition: ({ scrollPosition }) => scrollPosition,
};
