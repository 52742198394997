<template>
  <div class="picture-container" :class="{ selected }">
    <img
      :src="pictureUrl"
      :alt="item.name"
      :onError="getPictureUrl"
      loading="lazy"
      :class="{ error }"
      v-if="item"
    />
    <Cross v-else />
  </div>
</template>

<script>
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'SelectImage',
  components: {
    Cross,
  },
  props: {
    item: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    storeName: {
      type: String,
    },
  },
  data() {
    return {
      pictureUrl: null,
      error: false,
    };
  },
  created() {
    if (this.item) {
      this.pictureUrl = this.item.pictureUrl;
    }
  },
  methods: {
    async getPictureUrl() {
      this.error = true;
      this.pictureUrl = await this.$store.dispatch(`${this.storeName}/getPicture`, {
        item: this.item,
        pictureName: 'visual',
        url: this.pictureUrl,
      });
      this.error = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.picture-container {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background: $lightgrey-color;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
    &.error {
      opacity: 0;
    }
  }

  svg {
    width: 20px;
  }

  &.selected {
    position: absolute;
    top: 6px;
    left: 16px;
  }
}
</style>
