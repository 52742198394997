<template>
  <router-link
    :to="{ name: routeName, params: { id: cardContent.id } }"
    class="card fade"
    :class="{ big, small }"
    :data-id="cardContent.id"
  >
    <ScoreTag :score="cardContent.score" v-if="cardContent.score" />

    <img
      :src="pictureUrl"
      :alt="cardContent.name"
      :onError="getPictureUrl"
      loading="lazy"
      :onLoad="() => (loaded = true)"
      :class="{ loaded }"
    />
    <div
      class="tag"
      v-if="status"
      :style="{ background: status.backgroundColor, color: status.color }"
    >
      {{ $t(status.translation) }}
    </div>
    <div class="tag wishlist" v-if="cardContent.wishlist">
      {{ $t('wishlist') }}
    </div>
    <p class="name">{{ cardContent.name }}</p>
  </router-link>
</template>

<script>
import { projectStatus } from '@/interfaces/enums.json';
import ScoreTag from '@/components/ScoreTag.vue';

export default {
  name: 'Card',
  components: {
    ScoreTag,
  },
  props: {
    cardContent: {
      type: Object,
    },
    big: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
    },
    storeName: {
      type: String,
    },
  },
  data() {
    return {
      pictureUrl: null,
      loaded: false,
    };
  },
  computed: {
    status() {
      return this.cardContent.status
        ? projectStatus.find((status) => status.id === this.cardContent.status)
        : null;
    },
  },
  async created() {
    this.pictureUrl = this.cardContent.pictureUrl;
  },
  methods: {
    async getPictureUrl() {
      this.pictureUrl = await this.$store.dispatch(`${this.storeName}/getPicture`, {
        item: this.cardContent,
        pictureName: 'visual',
        url: this.cardContent.pictureUrl,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  width: 100%;
  height: 240px;
  border-radius: 16px;
  background: var(--primary-light-color);
  overflow: hidden;
  grid-row-end: span 25;
  outline: none;
  user-select: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;

    &.loaded {
      opacity: 1;
    }
  }

  &.small {
    height: 220px;
    grid-row-end: span 23;
  }

  &.big {
    height: 260px;
    grid-row-end: span 27;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 104px;
    z-index: 0;
    background: linear-gradient(180deg, rgba($lightgreen-color, 0) 0%, #1d2b2b 100%);
  }

  .tag {
    z-index: 1;
    width: fit-content;
    height: 26px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: $yellow-color;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: $font-semibold;
    color: $white-color;

    &.wishlist {
      background: $yellow-color;
      color: $text-color;
    }
  }

  .score-tag {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
  }

  .name {
    z-index: 1;
    color: $white-color;
    font-weight: $font-bold;
  }
}
</style>
