import { woolNumbers } from '@/interfaces/enums';

export default {
  wools: ({ wools }) => Object.values(wools),
  filteredData: ({ filters, wools }) => {
    const allWools = Object.values(wools);

    const filteredData = allWools
      .filter(
        (wool) =>
          wool.name.toUpperCase().includes(filters.search.toUpperCase()) ||
          wool.brand.toUpperCase().includes(filters.search.toUpperCase()) ||
          wool.material.toUpperCase().includes(filters.search.toUpperCase())
      )
      .filter(
        (wool) =>
          !filters.colorFilters.length ||
          filters.colorFilters.find((color) => wool.color.includes(color))
      )
      .filter((wool) => {
        const sizes = woolNumbers.filter((size) => filters.woolNumbersFilters.includes(size.id));

        return (
          !filters.woolNumbersFilters.length ||
          sizes.find((s) => !s.min && wool.number <= s.max) ||
          sizes.find((s) => wool.number >= s.min && wool.number <= s.max) ||
          sizes.find((s) => wool.number >= s.min && !s.max)
        );
      })
      .filter((wool) => !filters.scoreFilters.length || filters.scoreFilters.includes(wool.score))
      .filter((wool) => !filters.woolTypes.length || filters.woolTypes.includes(wool.type))
      .filter((wool) => !filters.wishlist || (filters.wishlist && wool.wishlist));

    return filteredData;
  },
  filters: ({ filters }) => filters,
  defaultFilters: ({ defaultFilters }) => defaultFilters,
  endFetching: ({ endFetching }) => endFetching,
  resizingPicture: ({ resizingPicture }) => resizingPicture,
  resizingPictureError: ({ resizingPictureError }) => resizingPictureError,
};
