import actions from './actions';
import getters from './getters';
import { cloneDeep } from 'lodash';

const state = {
  projects: [],
  endFetching: false,
  collectionEndFetching: false,
  defaultFilters: {
    search: '',
    scoreFilters: [],
    clothTypesFilters: [],
    collections: [],
    statusFilters: [],
  },
  filters: {},
  collections: [],
  resizingPicture: false,
  resizingPictureError: false,
};

state.filters = cloneDeep(state.defaultFilters);

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
