export default {
  projects: ({ projects }) => Object.values(projects),
  filteredData: ({ filters, projects }) => {
    const allProjects = Object.values(projects);

    const filteredData = allProjects
      .filter((project) => project.name.toUpperCase().includes(filters.search.toUpperCase()))
      .filter(
        (project) => !filters.scoreFilters.length || filters.scoreFilters.includes(project.score)
      )
      .filter(
        (project) =>
          !filters.clothTypesFilters.length || filters.clothTypesFilters.includes(project.clothType)
      )
      .filter(
        (project) => !filters.statusFilters.length || filters.statusFilters.includes(project.status)
      )
      .filter(
        (project) =>
          !filters.collections.length || filters.collections.includes(project.collectionId)
      );

    return filteredData;
  },
  filters: ({ filters }) => filters,
  defaultFilters: ({ defaultFilters }) => defaultFilters,
  endFetching: ({ endFetching }) => endFetching,
  collectionEndFetching: ({ collectionEndFetching }) => collectionEndFetching,
  collections: ({ collections }) => Object.values(collections),
  resizingPicture: ({ resizingPicture }) => resizingPicture,
  resizingPictureError: ({ resizingPictureError }) => resizingPictureError,
};
