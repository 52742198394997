import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  getAuth,
  GoogleAuthProvider,
  deleteUser,
  signInWithCredential,
  updateProfile,
  updatePassword,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider,
} from 'firebase/auth/cordova';
import { getDoc, setDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage, analytics } from '../../utils/firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject, updateMetadata } from 'firebase/storage';
import { setUserProperties, logEvent } from 'firebase/analytics';

import { setStorage, removeStorage, getStorage } from '@/utils/native';

const auth = getAuth();

let resizingPictureAttempt = 0;

export const actions = {
  async initUser({ dispatch, state }) {
    const currentUser = JSON.parse(await getStorage('margueriteUser'));
    if (state.offline) {
      state.user = currentUser;
      state.loggedIn = true;
    } else {
      await dispatch('getUser', currentUser);
    }
  },

  async getPicture({ dispatch, state }) {
    const pictureRef = ref(storage, `users/${state.user.uid}/user-picture_200x200.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      dispatch('updateUserPicture', { photoURL: downloadURL });
      return downloadURL;
    } catch (e) {
      return '';
    }
  },

  async registerPassword({ dispatch }, { email, password }) {
    await createUserWithEmailAndPassword(auth, email, password);
    await dispatch('createUser', { user: auth.currentUser });
    await dispatch('getUser');
  },

  async loginPassword({ dispatch }, { email, password }) {
    await signInWithEmailAndPassword(auth, email, password);
    await dispatch('getUser');

    logEvent(analytics, 'login', {
      login_type: 'password',
    });
  },

  async loginGoogle({ dispatch }) {
    const result = await FirebaseAuthentication.signInWithGoogle();
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    await signInWithCredential(auth, credential);
    await dispatch('getUser');
    logEvent(analytics, 'login', {
      login_type: 'google',
    });
  },

  async loginApple({ dispatch }) {
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    try {
      await signInWithCredential(auth, credential);
      await dispatch('getUser');
      logEvent(analytics, 'login', {
        login_type: 'apple',
      });
    } catch (e) {
      console.log(e.code);
    }
  },

  async logout({ state }) {
    await FirebaseAuthentication.signOut();
    await signOut(auth);
    state.user = null;
    state.loggedIn = false;
    removeStorage('margueriteUser');
    state.sewistaMode = true;
  },

  async reconnectUser({ dispatch, state }, { password, google, apple }) {
    if (google) {
      await dispatch('loginGoogle');
    } else if (apple) {
      await dispatch('loginApple');
    } else {
      await dispatch('loginPassword', { email: state.user.email, password });
    }
  },

  async deleteAccount({ dispatch, state }) {
    dispatch('deleteUserPicture', {
      userPictureUrl: auth.currentUser.photoURL,
    });
    logEvent(analytics, 'user_delete', {
      profil_type: state.user.isSewista ? 'sewing' : 'knitting',
    });
    deleteDoc(doc(db, 'users', auth.currentUser.uid));
    deleteUser(auth.currentUser);
    state.user = null;
    state.loggedIn = false;
    removeStorage('margueriteUser');
  },

  async updateDisplayName({ state }, { displayName }) {
    await updateProfile(auth.currentUser, {
      displayName,
    });
    state.user = {
      ...auth.currentUser,
      isSewista: state.user.isSewista,
      measurements: state.user.measurements,
    };
    setStorage('margueriteUser', JSON.stringify(auth.currentUser));
    logEvent(analytics, 'user_update_pseudo', {
      profil_type: state.user.isSewista ? 'sewing' : 'knitting',
    });
  },
  async updateUserPicture({ state }, { photoURL }) {
    await updateProfile(auth.currentUser, {
      photoURL,
    });
    state.user = {
      ...auth.currentUser,
      isSewista: state.user.isSewista,
      measurements: state.user.measurements,
    };
    setStorage('margueriteUser', JSON.stringify(auth.currentUser));
    if (!photoURL.includes('_200x200')) {
      logEvent(analytics, 'user_update_picture', {
        profil_type: state.user.isSewista ? 'sewing' : 'knitting',
      });
    }
  },
  async updatePassword(_, { newPassword }) {
    await updatePassword(auth.currentUser, newPassword);
  },
  async createUser(_, { user }) {
    await setDoc(doc(db, 'users', user.uid), {
      id: user.uid,
      isSewista: true,
      measurements: null,
      createdAt: new Date().getTime(),
    });
    setUserProperties(analytics, { profil_type: 'sewing' });
    logEvent(analytics, 'sign_up');
  },
  async getUser({ state, dispatch }, currentUser) {
    const currentUserInfo = currentUser || auth.currentUser;
    const snapshot = await getDoc(doc(db, 'users', currentUserInfo.uid));
    if (snapshot.exists()) {
      const dbUser = snapshot.data();
      const detailedUser = {
        ...currentUserInfo,
        isSewista: dbUser.isSewista,
        measurements: dbUser.measurements || null,
      };

      setStorage('margueriteUser', JSON.stringify(detailedUser));
      state.user = detailedUser;
      state.loggedIn = true;
    } else {
      dispatch('createUser', { user: currentUserInfo });
      await dispatch('getUser');
    }
    dispatch('getAppConfig');
  },
  editUserProfile({ state }, { isSewista }) {
    updateDoc(doc(db, 'users', auth.currentUser.uid), {
      isSewista,
      measurements: state.user.measurements,
    });
    state.user.isSewista = isSewista;
    state.sewistaMode = isSewista;
    setUserProperties(analytics, { profil_type: isSewista ? 'sewing' : 'knitting' });
  },
  editUserMeasurements({ state }, { measurements }) {
    updateDoc(doc(db, 'users', auth.currentUser.uid), {
      isSewista: state.user.isSewista,
      measurements,
    });
    state.user.measurements = measurements;
    logEvent(analytics, 'user_update_measures', {
      profil_type: state.user.isSewista ? 'sewing' : 'knitting',
    });
  },
  async forgetPassword(_, { email }) {
    await FirebaseAuthentication.sendPasswordResetEmail({
      email,
    });
  },
  updateAppProfileMode({ state }, { isSewista }) {
    state.sewistaMode = isSewista;
  },
  async getResizePicture({ dispatch, state }) {
    const userRef = ref(storage, `users/${auth.currentUser.uid}/user-picture_200x200.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(userRef);
      updateMetadata(userRef, newMetadata);
      updateDoc(doc(db, 'users', auth.currentUser.uid), { pictureUrl: downloadURL });
      state.resizingPicture = false;
      await dispatch('updateUserPicture', { photoURL: downloadURL });

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {}, 1000);

      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizePicture');
        }
      }, 1000);
    }
  },
  async storeUserPicture({ dispatch, state }, { file }) {
    const userRef = ref(storage, `users/${auth.currentUser.uid}/${file.name}.jpeg`);
    await uploadBytes(userRef, file);
    resizingPictureAttempt = 0;
    state.resizingPictureError = false;
    await dispatch('getResizePicture');
  },
  async deleteUserPicture(_, { userPictureUrl }) {
    if (!userPictureUrl) return;
    try {
      const usertRef = ref(storage, userPictureUrl);
      await deleteObject(usertRef);
    } catch (e) {
      return '';
    }
  },
  async getAppConfig({ state }) {
    const snapshot = await getDoc(doc(db, 'application', 'maintenance'));
    if (snapshot.exists()) {
      state.maintenance = snapshot.data();
    }
  },
  async closeMaintenanceBanner({ state }) {
    state.maintenance.active = false;
  },
  setOfflineMode({ state }, { isOffline }) {
    state.offline = isOffline;
  },
  setScrollPosition({ state }, { scrollPosition }) {
    state.scrollPosition = scrollPosition;
  },
};
