<template>
  <div class="content">
    <h1 class="title-1">{{ $t('projects.newProject') }}</h1>
    <ProjectForm v-model="project" ref="form" @formValidity="checkFormValidity" />
    <LoaderButton
      :disabled="!isFormValid"
      :text="$t('projects.create')"
      :loading="submited"
      @action="saveProject"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import ProjectInterface from '@/interfaces/project';
import gsap from 'gsap';

import ProjectForm from '@/components/forms/ProjectForm.vue';

import LoaderButton from '@/components/LoaderButton.vue';

export default {
  name: 'ProjectCreation',
  components: {
    ProjectForm,
    LoaderButton,
  },
  data() {
    return {
      project: cloneDeep({ ...ProjectInterface }),
      submited: false,
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters({
      modalResolve: 'modals/modalResolve',
      fabrics: 'fabrics/fabrics',
      projects: 'projects/projects',
      resizingPicture: 'projects/resizingPicture',
      resizingPictureError: 'projects/resizingPictureError',
    }),
  },
  methods: {
    checkFormValidity(value) {
      this.isFormValid = value;
    },
    async saveProject() {
      if (this.submited) return;
      this.submited = true;

      const error = this.$refs.form.checkForm();
      if (error) {
        this.submited = false;
        gsap.to('.modal .content', {
          scrollTo: { y: '.error', offsetY: 42 },
          duration: 0.3,
          ease: 'power1.inOut',
        });
        return;
      }

      const projectFile = this.$refs.form.projectFile;
      const projectId = await this.$store.dispatch('projects/getNewProjectId');

      this.project.recommandedFabrics = this.$refs.form.recommandedFabrics.filter(
        (fabric) => fabric.checked
      );

      try {
        await this.$store.dispatch('projects/createProject', {
          projectId,
          project: this.project,
        });

        await this.$store.dispatch('projects/storeProjectPicture', {
          file: projectFile,
          projectId,
        });

        const imageInterval = setInterval(() => {
          if (!this.resizingPicture) {
            clearInterval(imageInterval);

            if (this.resizingPictureError) {
              this.$store.dispatch('toasts/setToast', {
                type: 'error',
                content: this.$t('toasts.pictureError'),
              });
            } else {
              this.$store.dispatch('toasts/setToast', {
                type: 'success',
                content: this.$t('toasts.createProjectSuccess'),
              });
            }

            this.modalResolve(true);
          }
        }, 1000);
      } catch (e) {
        this.$store.dispatch('toasts/setToast', {
          type: 'error',
          content: this.$t('toasts.createProjectError'),
        });
        this.submited = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
