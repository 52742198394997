<template>
  <div class="modal collection" ref="scroller">
    <div class="step view no-switch" ref="collectionName">
      <div class="content">
        <button @click="$emit('close')" class="go-back"><BackArrow />{{ $t('back') }}</button>

        <div class="picture" @click="goToPictureSelection">
          <img
            ref="img"
            :src="selectedIllustration || pictureUrl"
            alt="picture"
            v-if="selectedIllustration || pictureUrl"
            accept="image/png, image/jpeg"
          />
          <Edit class="icon" />
        </div>

        <input
          class="collection-name"
          type="text"
          v-model="collectionName"
          :placeholder="$t('projects.collectionName')"
        />
        <LoaderButton
          :disabled="(!pictureUrl && !selectedIllustration) || !collectionName"
          :text="$t('projects.editCollection')"
          :loading="submited"
          @action="savePicture"
          v-if="edition"
        />
        <LoaderButton
          :disabled="(!pictureUrl && !selectedIllustration) || !collectionName"
          :text="$t('projects.createCollection')"
          :loading="submited"
          @action="createCollection"
          v-else
        />
      </div>
    </div>
    <div class="step view no-switch" ref="collectionPicture">
      <div class="content column">
        <button @click="goBackCollectionName" class="go-back"><BackArrow />{{ $t('back') }}</button>
        <div
          class="illustration"
          :class="{ selected: selectedIllustration === illustration.url }"
          v-for="illustration in collectionIllustrations"
          :key="illustration.name"
          @click="selectedIllustration = illustration.url"
        >
          <img :src="illustration.url" :alt="illustration.name" />
        </div>
        <FileButton @setPicture="setPicture" />
        <button
          class="primary-button fullwidth"
          :disabled="!selectedIllustration"
          @click="selectIllustration"
        >
          {{ $t('projects.choseIllustration') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { collectionIllustrations } from '@/interfaces/enums.json';
import gsap from 'gsap';
import { getPlatform } from '@/utils/native';

import BackArrow from '@/assets/svg/back-arrow.svg';
import Edit from '@/assets/svg/edit.svg';

import LoaderButton from '@/components/LoaderButton.vue';
import FileButton from '@/components/FileButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CollectionModal',
  components: {
    BackArrow,
    Edit,
    LoaderButton,
    FileButton,
  },
  props: {
    name: {
      type: String,
    },
    collection: {
      type: Object,
    },
    edition: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      pictureUrl: null,
      submited: false,
      collectionIllustrations,
      selectedIllustration: null,
      collectionName: '',
    };
  },
  computed: {
    ...mapGetters({
      resizingPicture: 'projects/resizingPicture',
      resizingPictureError: 'projects/resizingPictureError',
    }),
    isAndroid() {
      return getPlatform() === 'android';
    },
  },
  mounted() {
    this.collectionName = this.collection ? this.collection.name : this.name || '';
    this.pictureUrl = this.collection ? this.collection.pictureUrl : '';
  },
  methods: {
    goToPictureSelection() {
      gsap.to(this.$refs.scroller, {
        scrollTo: { x: this.$refs.collectionPicture },
        duration: 0.628,
      });
    },
    goBackCollectionName() {
      gsap.to(this.$refs.scroller, {
        scrollTo: { x: this.$refs.collectionName },
        duration: 0.628,
      });
    },
    async setPicture({ pictureFile, pictureUrl, pictureAndroidUrl }) {
      this.pictureUrl = pictureUrl || pictureAndroidUrl;
      this.pictureFile = pictureFile;

      this.goBackCollectionName();
    },
    async createCollection() {
      if (
        (!this.pictureFile && !this.selectedIllustration) ||
        !this.collectionName ||
        this.submited
      )
        return;

      this.submited = true;

      const collectionId = await this.$store.dispatch('projects/getNewCollectionId');

      try {
        const newCollection = {
          name: this.collectionName,
        };

        if (this.selectedIllustration) {
          newCollection.pictureUrl = this.selectedIllustration;
        }

        await this.$store.dispatch('projects/createCollection', {
          collection: newCollection,
          collectionId,
        });

        if (this.pictureFile) {
          await this.$store.dispatch('projects/storeCollectionPicture', {
            file: this.pictureFile,
            collectionId: collectionId,
          });
        }

        const imageInterval = setInterval(() => {
          if (!this.resizingPicture) {
            clearInterval(imageInterval);

            if (this.resizingPictureError) {
              this.$store.dispatch('toasts/setToast', {
                type: 'error',
                content: this.$t('toasts.pictureError'),
              });
            } else {
              this.$store.dispatch('toasts/setToast', {
                type: 'success',
                content: this.$t('toasts.createCollectionSuccess'),
              });
            }

            this.$emit('close', {
              id: collectionId,
              pictureUrl: this.selectedIllustration,
              name: this.collectionName,
            });
          }
        }, 1000);
      } catch (e) {
        this.$store.dispatch('toasts/setToast', {
          type: 'error',
          content: this.$t('toasts.createCollectionError'),
        });
        this.submited = false;
      }
    },
    async savePicture() {
      if (this.submited) return;
      this.submited = true;

      if (
        (this.selectedIllustration || this.pictureFile) &&
        this.collection &&
        this.collection.pictureUrl &&
        !collectionIllustrations.find(
          (illustration) => illustration.url === this.collection.pictureUrl
        )
      ) {
        await this.$store.dispatch('projects/deleteCollectionPicture', {
          collectionPictureUrl: this.collection.pictureUrl,
        });
      }

      const udpatedCollection = {
        ...this.collection,
        name: this.collectionName,
      };

      if (this.selectedIllustration) {
        udpatedCollection.pictureUrl = this.selectedIllustration;
      }

      await this.$store.dispatch('projects/editCollection', {
        collection: udpatedCollection,
      });

      if (this.pictureFile) {
        await this.$store.dispatch('projects/storeCollectionPicture', {
          file: this.pictureFile,
          collectionId: this.collection.id,
        });
      }

      const imageInterval = setInterval(() => {
        if (!this.resizingPicture) {
          clearInterval(imageInterval);

          if (this.resizingPictureError) {
            this.$store.dispatch('toasts/setToast', {
              type: 'error',
              content: this.$t('toasts.pictureError'),
            });
          }

          this.$emit('close');
        }
      }, 1000);
    },
    selectIllustration() {
      this.goBackCollectionName();
    },
  },
};
</script>

<style scoped lang="scss">
.modal.collection {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: $white-color;
  z-index: 1000;
  overflow: hidden;

  .view {
    flex-shrink: 0;
    padding-bottom: var(--view-padding);
  }

  .go-back {
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-weight: $font-semibold;
    background: none;
    color: $text-color;
    margin-bottom: 42px;
    width: 100%;

    svg {
      width: 22px;
      margin-right: 8px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &.column {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .go-back {
        margin: 0;
      }
    }
  }
}

.picture {
  position: relative;
  height: 168px;
  width: 168px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(29, 43, 43, 0) 0%, rgba(29, 43, 43, 0.48) 100%);
    z-index: 1;
  }

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    appearance: none;
    opacity: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    bottom: 16px;
    width: 24px;
    color: $white-color;
    pointer-events: none;
    z-index: 2;
  }
}

.collection-name {
  margin-top: 40px;
  width: fit-content;
  border: none;
  font-family: $text-font;
  padding: 0;
  border-bottom: 1px solid $lightgrey-color;
  font-size: 24px;
  font-weight: $font-bold;
  text-align: center;
  color: $text-color;

  &::placeholder {
    color: $lightgrey-color;
    font-weight: $font-regular;
  }
}

.illustration {
  $size: calc((100vw - (var(--view-padding) * 2) - 16px) / 3);
  position: relative;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  padding: 8px;
  overflow: hidden;
  border: 1px solid $white-color;
  transition: 0.3s;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.selected {
    border-color: var(--secondary-color);
  }
}

.primary-button {
  margin-top: auto;
}
</style>
