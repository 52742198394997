<template>
  <div class="banner">
    <p>{{ message }}</p>
    <Cross @click="closeBanner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setStorage } from '@/utils/native';

import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'MaintenanceBanner',
  components: {
    Cross,
  },
  computed: {
    ...mapGetters({
      message: 'auth/maintenanceMessage',
      date: 'auth/maintenanceDate',
    }),
  },
  methods: {
    async closeBanner() {
      await setStorage('lastMaintenanceBanner', String(this.date));
      this.$store.dispatch('auth/closeMaintenanceBanner');
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: calc(var(--safe-area-top) + 14px) var(--view-padding) 14px;
  background-color: var(--primary-light-color);
  z-index: 1000;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
    flex: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    flex-shrink: 0;
  }
}
</style>
