<template>
  <div
    class="input-container"
    :class="{ error: errorMessage, required: required, 'no-label': !label }"
  >
    <label class="label" v-if="label">{{ label }}</label>
    <div
      class="input"
      :class="{
        disabled,
        open: isOpen,
        picture: selectedOption && selectedOption.pictureUrl,
      }"
      v-clickout="() => (isOpen = false)"
    >
      <SelectImage
        :item="selectedOption"
        :selected="true"
        :storeName="storeName"
        v-if="selectedOption && selectedOption.pictureUrl"
      />
      <input
        type="text"
        :placeholder="placeholder"
        v-model="search"
        @focus="isOpen = true"
        ref="input"
      />
      <div class="arrow" @click="isOpen = !isOpen"><Arrow /></div>
      <div class="options">
        <div
          class="option add-collection"
          v-if="collectionInput && search && search !== selectedOptionName"
          @click="creatingCollection = true"
        >
          <SelectImage />
          {{ search }}
        </div>
        <div
          class="option"
          v-for="option in filteredOptions"
          :key="option.id"
          :value="option.id"
          :selected="modelValue === option.id"
          @click="selectOption(option)"
        >
          <SelectImage :item="option" v-if="option.pictureUrl" :storeName="storeName" />

          {{ option[optionName] || $t(option.translation) }}
        </div>
      </div>
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
    <Teleport to="#app">
      <transition name="translate-modal">
        <CollectionModal v-if="creatingCollection" @close="createCollection" :name="search" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import Arrow from '@/assets/svg/arrow.svg';
import CollectionModal from '@/components/modals/CollectionModal.vue';
import SelectImage from '@/components/inputs/SelectImage.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Select',
  components: {
    Arrow,
    CollectionModal,
    SelectImage,
  },
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [Number, String],
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    optionName: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    collectionInput: {
      type: Boolean,
    },
    defaultOptionId: {
      type: [String, Number],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      search: '',
      isOpen: false,
      errorMessage: '',
      creatingCollection: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
    }),
    selectedOption() {
      return this.modelValue ? this.options.find((option) => option.id === this.modelValue) : null;
    },
    selectedOptionName() {
      return this.selectedOption
        ? this.selectedOption[this.optionName] || this.$t(this.selectedOption.translation)
        : null;
    },
    filteredOptions() {
      let selection = [...this.options].filter((option) => {
        const name = option[this.optionName] || this.$t(option.translation);
        const brand = option.brand;
        return (
          (option.id !== this.defaultOptionId && !this.search) ||
          (this.modelValue && this.search === this.selectedOptionName) ||
          (this.search && name.toUpperCase().includes(this.search.toUpperCase())) ||
          (this.search && brand && brand.toUpperCase().includes(this.search.toUpperCase()))
        );
      });

      if (!selection.length && this.defaultOptionId) {
        selection = this.options.filter((option) => option.id === this.defaultOptionId);
      }

      return selection.sort((option1, option2) => {
        const name1 = option1[this.optionName] || this.$t(option1.translation);
        const name2 = option2[this.optionName] || this.$t(option2.translation);

        return name1.toUpperCase().localeCompare(name2.toUpperCase());
      });
    },
    storeName() {
      if (this.collectionInput) {
        return 'projects';
      } else {
        if (this.sewistaMode) {
          if (this.options.find((option) => option.patternType)) {
            return 'patterns';
          } else {
            return 'fabrics';
          }
        } else {
          if (this.options.find((option) => option.patternType)) {
            return 'knittingPatterns';
          } else {
            return 'wools';
          }
        }
      }
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue && !this.search) {
        this.search = this.selectedOptionName;
      }
    },
    search(search) {
      if (search && search.length && search !== this.selectedOptionName) {
        this.isOpen = true;
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.search = this.selectedOptionName;
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('update:modelValue', option.id);
      this.search = option[this.optionName] || this.$t(option.translation);
      this.errorMessage = '';
      this.$refs.input.blur();
      this.$nextTick(() => {
        this.isOpen = false;
      });
    },
    check() {
      if (this.required && !this.modelValue) {
        this.errorMessage = this.$t('mandatoryField');
      }

      return !!this.errorMessage;
    },
    createCollection(collection) {
      this.creatingCollection = false;
      if (collection) {
        this.selectOption(collection);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  position: relative;
  @include input;
  height: 112px;

  &.no-label {
    height: 56px;
    margin: 0;

    .input {
      position: absolute;
      top: 0;
    }
  }

  .input {
    position: absolute;
    top: 32px;
    flex-direction: column;
    padding: 0;
    height: unset;
    max-height: 56px;
    overflow: hidden;
    transition: 0.3s;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      top: 56px;
      left: 16px;
      right: 16px;
      background: $lightgrey-color;
      opacity: 0;
      transition: 0.3s;
    }

    input {
      height: 56px;
      flex-shrink: 0;
      padding-right: 44px;
    }

    .arrow {
      position: absolute;
      padding: 6px 22px 6px 6px;
      right: 0;
      top: 12px;

      svg {
        color: var(--primary-color);
        width: 14px;
        rotate: 180deg;

        transition: 0.3s;
      }
    }

    &.open {
      max-height: 296px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
      z-index: 10;

      &::after {
        opacity: 1;
      }

      .arrow svg {
        rotate: 0deg;
      }
    }

    &.picture {
      input {
        padding-left: 72px;
      }
    }
  }

  .options {
    overflow: auto;
    width: 100%;
    padding-top: 8px;

    .option {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 8px 16px;

      .picture-container {
        margin-right: 16px;
      }
    }
  }
}

.picture-container {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background: $lightgrey-color;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 20px;
  }

  &.selected {
    position: absolute;
    top: 6px;
    left: 16px;
  }
}
</style>
