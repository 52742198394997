<template>
  <transition name="toast">
    <MaintenanceBanner v-if="maintenance && displayMaintenanceBanner && sewistaSwitch" />
  </transition>
  <transition name="toast">
    <Toast v-if="toast" />
  </transition>
  <transition name="modal">
    <ModalTemplate v-if="modalType" />
  </transition>
  <transition name="fade" :class="transitionType">
    <SwitchInput
      v-model="isSewista"
      class="knitting-switch"
      :labels="[$t('knitting'), $t('sewing')]"
      v-if="sewistaSwitch"
    />
  </transition>
  <div class="offline-mode" v-if="sewistaSwitch && offline">
    {{ $t('toasts.offlineMode') }}
  </div>
  <router-view v-slot="{ Component }">
    <transition name="router" :class="transitionType">
      <component
        :is="Component"
        :class="{
          'no-navbar': !navbar,
          'no-switch': !sewistaSwitch,
          'no-statusbar': !statusBar,
        }"
        :key="routerKey"
      />
    </transition>
  </router-view>

  <transition name="fade" :class="transitionType">
    <Navbar v-if="navbar" />
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  isNative,
  setStatusBarStyle,
  checkNetworkStatus,
  removeNetworkListeners,
  getStorage,
} from '@/utils/native';

import Navbar from '@/components/Navbar.vue';
import SwitchInput from '@/components/inputs/SwitchInput.vue';
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import Toast from '@/components/Toast.vue';
import MaintenanceBanner from '@/components/MaintenanceBanner.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    SwitchInput,
    ModalTemplate,
    Toast,
    MaintenanceBanner,
  },
  data() {
    return {
      isSewista: true,
      previousRoute: null,
      transitionType: '',
      bannerHeight: 0,
      displayMaintenanceBanner: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
      modalType: 'modals/modalType',
      user: 'auth/user',
      toast: 'toasts/toast',
      offline: 'auth/offline',
      maintenance: 'auth/maintenanceActive',
      maintenanceDate: 'auth/maintenanceDate',
      scrollPosition: 'auth/scrollPosition',
    }),
    sewistaSwitch() {
      return this.$route.meta.sewistaSwitch;
    },
    navbar() {
      return this.$route.meta.navbar;
    },
    routerKey() {
      return this.$route.params.id ? this.$route.params.id : this.$route.name;
    },
    statusBar() {
      return this.$route.meta.statusbar;
    },
  },
  watch: {
    async isSewista(isSewista) {
      await this.$store.dispatch('auth/updateAppProfileMode', {
        isSewista: this.isSewista,
      });
      if (isSewista || isSewista === null) {
        document.documentElement.style.setProperty('--primary-color', '#e0867d');
        document.documentElement.style.setProperty('--secondary-color', '#28655a');
        document.documentElement.style.setProperty('--primary-light-color', '#f2c6c3');
        document.documentElement.style.setProperty('--secondary-light-color', '#a9c1bd');
        document.documentElement.style.setProperty('--primary-dark-color', '#B96E67');
        document.documentElement.style.setProperty('--secondary-dark-color', '#1d2b2b');
      } else {
        document.documentElement.style.setProperty('--primary-color', '#28655a');
        document.documentElement.style.setProperty('--secondary-color', '#e0867d');
        document.documentElement.style.setProperty('--primary-light-color', '#a9c1bd');
        document.documentElement.style.setProperty('--secondary-light-color', '#f2c6c3');
        document.documentElement.style.setProperty('--primary-dark-color', '#1d2b2b');
        document.documentElement.style.setProperty('--secondary-dark-color', '#B96E67');
      }
    },
    modalType() {
      document.documentElement.classList.toggle('no-scroll');
    },
    'user.isSewista'(value) {
      this.isSewista = value;
    },
    async maintenance() {
      const lastMaintenanceBanner = await getStorage('lastMaintenanceBanner');
      if (!lastMaintenanceBanner || lastMaintenanceBanner !== String(this.maintenanceDate)) {
        this.displayMaintenanceBanner = true;
      } else {
        this.displayMaintenanceBanner = false;
        this.$store.dispatch('auth/closeMaintenanceBanner');
      }
    },
  },
  mounted() {
    checkNetworkStatus(this.$store);
    if (!isNative()) {
      const meta = document.querySelector("meta[name='viewport']");
      meta.content = 'width=device-width,initial-scale=1.0';
    }

    this.$router.beforeEach((to, from) => {
      const scrollPosition = { y: window.scrollY, x: 0 };
      if (from.meta.transitionType === 'grid' && to.meta.transitionType !== 'grid') {
        this.$store.dispatch('auth/setScrollPosition', { scrollPosition });
      }

      document.getElementById('app').classList.add('transition');

      if (to.meta.routeOrder > from.meta.routeOrder) {
        this.transitionType = 'translate-enter';
      } else if (to.meta.routeOrder < from.meta.routeOrder) {
        this.transitionType = 'translate-leave';
      } else {
        this.transitionType = '';
      }

      if (from.name === 'account') {
        document.getElementById('app').classList.add('no-statusbar');
      }

      setTimeout(() => {
        document.getElementById('app').classList.remove('transition');
        if (this.transitionType === 'translate-enter') {
          window.scrollTo({ top: 0 });
        }
      }, 601);

      setTimeout(() => {
        document.getElementById('app').classList.remove('no-statusbar');

        if (!this.transitionType) {
          window.scrollTo({ top: 0 });
          this.$store.dispatch('auth/setScrollPosition', { scrollPosition: null });
        }

        if (
          to.meta.transitionType === 'grid' &&
          this.scrollPosition &&
          from.meta.transitionType !== 'grid' &&
          this.transitionType
        ) {
          window.scrollTo({ top: this.scrollPosition.y });
        }
      }, 100);
    });

    setStatusBarStyle();
  },
  beforeUnmount() {
    removeNetworkListeners();
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>

<style scoped lang="scss">
.knitting-switch {
  position: absolute;
  top: calc(var(--safe-area-top) + 24px);
  left: var(--view-padding);
}

.offline-mode {
  position: absolute;
  top: calc(var(--safe-area-top) + 34px);
  right: var(--view-padding);
  color: $red-color;
  font-weight: $font-semibold;
  font-size: 14px;
}
</style>
