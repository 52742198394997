<template>
  <div class="view grid-view">
    <div class="content">
      <div class="title fade">
        <h1 class="title-1">
          {{ sewistaMode ? $t('patterns.sewingPatterns') : $t('patterns.knittingPatterns') }}
        </h1>
        <button
          class="filters"
          @click="openFilters"
          v-if="currentList.length || filteredResult"
          :class="{ active: filteredResult }"
        >
          <Filter />
        </button>
      </div>
      <div class="patterns list-grid" v-if="currentList.length">
        <Card
          v-for="(pattern, index) in currentList"
          :key="pattern"
          :big="index % 5 === 0 || !index"
          :small="index % 3 === 0"
          :cardContent="pattern"
          :routeName="sewistaMode ? 'pattern' : 'knittingPattern'"
          :storeName="currentStore"
        />
      </div>
      <div class="projects list-grid empty" v-else>
        <img :src="PatternIllustration" alt="pattern" class="illustration" />
        <Loader v-if="fetching && !filteredResult" class="loader" />

        <p class="description" v-else>
          {{ filteredResult ? $t('patterns.noFilteredResult') : $t('patterns.noPatterns') }}
        </p>
        <button
          class="primary-button"
          @click="createPattern"
          v-if="!filteredResult && !fetching"
          :class="{ disabled: offline }"
        >
          <Cross />{{ $t('patterns.newPattern') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { analytics } from '@/utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';

import Card from '@/components/Card.vue';
import Loader from '@/assets/svg/loader.svg';

import Filter from '@/assets/svg/filter.svg';
import PatternIllustration from '@/assets/illustration/pattern.png';
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'Patterns',
  components: {
    Card,
    Filter,
    Cross,
    Loader,
  },
  data() {
    return {
      PatternIllustration,
      pageSize: 8,
      pageNumber: 0,
      fetching: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
      filteredPatterns: 'patterns/filteredData',
      filteredKnittingPatterns: 'knittingPatterns/filteredData',
      patternFilters: 'patterns/filters',
      knittingPatternFilters: 'knittingPatterns/filters',
      patternsDefaultFilters: 'patterns/defaultFilters',
      knittingPatternsDefaultFilters: 'knittingPatterns/defaultFilters',
      patternsEndFetching: 'patterns/endFetching',
      knittingPatternsEndFetching: 'knittingPatterns/endFetching',
      offline: 'auth/offline',
    }),
    currentList() {
      return this.sewistaMode ? this.filteredPatterns : this.filteredKnittingPatterns;
    },
    currentFilters() {
      return this.sewistaMode ? this.patternFilters : this.knittingPatternFilters;
    },
    filteredResult() {
      return !!Object.values(this.currentFilters).filter(
        (filter) => (filter && filter.length) || filter === true
      ).length;
    },
    currentStore() {
      return this.sewistaMode ? 'patterns' : 'knittingPatterns';
    },
    endFetching() {
      return this.sewistaMode ? this.patternsEndFetching : this.knittingPatternsEndFetching;
    },
  },
  watch: {
    async sewistaMode() {
      if (
        !this.currentList.length ||
        (this.currentList.length < this.pageSize && !this.endFetching)
      ) {
        this.fetching = !this.endFetching;
        await this.$store.dispatch(`${this.currentStore}/getPaginatedPatterns`, { pageNumber: 0 });
        this.pageNumber = 0;
        this.fetching = false;
      }

      //GA
      logEvent(analytics, 'screen_view', {
        firebase_screen: this.sewistaMode ? 'Patterns' : 'KnittingPatterns',
        firebase_screen_class: this.sewistaMode ? 'Patterns' : 'KnittingPatterns',
      });
    },
  },
  async created() {
    if (
      !this.currentList.length ||
      (this.currentList.length < this.pageSize && !this.endFetching)
    ) {
      this.fetching = !this.endFetching;

      await this.$store.dispatch(`${this.currentStore}/getPaginatedPatterns`, { pageNumber: 0 });
      this.fetching = false;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);

    //GA
    logEvent(analytics, 'screen_view', {
      firebase_screen: this.sewistaMode ? 'Patterns' : 'KnittingPatterns',
      firebase_screen_class: this.sewistaMode ? 'Patterns' : 'KnittingPatterns',
    });
  },
  methods: {
    onScroll() {
      this.updateTitle();
      this.pagination();
    },
    updateTitle() {
      const statusbarHeight = Number(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--safe-area-top')
          .replace('px', '')
      );
      const titlePosition = document.querySelector('.title').getBoundingClientRect().top;

      if (
        titlePosition <= statusbarHeight &&
        !document.querySelector('.title').classList.contains('small')
      ) {
        document.querySelector('.title').classList.add('small');
      } else if (document.documentElement.scrollTop === 0) {
        document.querySelector('.title').classList.remove('small');
      }
    },
    async pagination() {
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = documentHeight - window.innerHeight - window.scrollY;

      if (scrollPosition < 600 && !this.fetching && !this.endFetching) {
        this.fetching = true;
        this.pageNumber += 1;
        await this.$store.dispatch(`${this.currentStore}/getPaginatedPatterns`, {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.fetching = false;
      }
    },
    async openFilters() {
      await this.$store.dispatch('modals/setModal', {
        modalType: 'FiltersModal',
        modalData: {
          filters: this.currentFilters,
          storeModule: this.sewistaMode ? 'patterns' : 'knittingPatterns',
          defaultFilters: this.sewistaMode
            ? this.patternsDefaultFilters
            : this.knittingPatternsDefaultFilters,
        },
      });
    },
    async createPattern() {
      if (this.offline) return;
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'PatternCreation' : 'KnittingPatternCreation',
        modalData: {},
      });
    },
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.patterns {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc((100% - 10px) / 2));
  grid-auto-rows: 10px;
  justify-content: space-between;
  margin-top: 32px;
}
</style>
