<template>
  <div class="view edition">
    <div class="content" :class="{ translate: editingColleciton || addingProject }">
      <div class="line">
        <GoBackButton />
        <button
          class="link-font"
          @click="addingProject = true"
          v-if="collectionProjects.length && !offline"
        >
          {{ $t('projects.addProjects') }}
        </button>
      </div>
      <div
        class="collection-detail"
        @click="editingColleciton = true"
        :class="{ disabled: offline }"
      >
        <div class="picture">
          <img
            ref="img"
            :src="collection.pictureUrl"
            alt="picture"
            accept="image/png, image/jpeg"
          />
          <Edit class="icon" v-if="!offline" />
        </div>
        <p class="name">{{ collection.name }}</p>
      </div>
      <div class="projects" v-if="collectionProjects.length">
        <Card
          v-for="(project, index) in collectionProjects"
          :key="project"
          :big="index % 5 === 0 || !index"
          :small="index % 3 === 0"
          :cardContent="project"
          :routeName="project.woolMetric ? 'knittingProject' : 'project'"
          :storeName="project.woolMetric ? 'knittingProjects' : 'projects'"
        />
      </div>
      <div class="empty" v-else>
        <p>{{ $t('projects.emptyCollection') }}</p>
        <button class="primary-button" @click="addingProject = true">
          <Cross />{{ $t('projects.addProjects') }}
        </button>
      </div>

      <button
        class="primary-button dangerous fullwidth"
        :class="{ offline: offline }"
        @click="deletingCollection = true"
      >
        {{ $t('projects.deleteCollection') }}
      </button>
    </div>
    <Teleport to="#app">
      <transition name="small-modal">
        <ConfirmationModal
          v-if="deletingCollection"
          :title="$t('projects.deleteCollectionModalTitle')"
          :description="$t('projects.deleteCollectionModalDescription')"
          :button="$t('delete')"
          :dangerous="true"
          @close="deletingCollection = false"
          @confirm="deleteCollection"
        />
      </transition>
      <transition name="translate-modal">
        <CollectionModal
          v-if="editingColleciton"
          :collection="collection"
          :edition="true"
          @close="editingColleciton = false"
        />
      </transition>
      <transition name="translate-modal">
        <ProjectsModal v-if="addingProject" @close="addProjects" :collectionId="collection.id" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { collectionIllustrations } from '@/interfaces/enums.json';
import { analytics } from '@/utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';

import Card from '@/components/Card.vue';
import GoBackButton from '@/components/GoBackButton.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import CollectionModal from '@/components/modals/CollectionModal.vue';
import ProjectsModal from '@/components/modals/ProjectsModal.vue';

import Edit from '@/assets/svg/edit.svg';
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'Collection',
  components: {
    Card,
    GoBackButton,
    Edit,
    ConfirmationModal,
    CollectionModal,
    Cross,
    ProjectsModal,
  },
  data() {
    return {
      pictureFile: null,
      deletingCollection: false,
      editingColleciton: false,
      addingProject: false,
      collectionProjects: [],
    };
  },
  computed: {
    ...mapGetters({
      projects: 'projects/projects',
      knittingProjects: 'knittingProjects/projects',
      collections: 'projects/collections',
      offline: 'auth/offline',
    }),
    allProjects() {
      return this.projects.concat(this.knittingProjects);
    },
    collection() {
      return this.collections.find((collection) => collection.id === this.$route.params.id) || {};
    },
  },
  async created() {
    const projects = await this.$store.dispatch('projects/getProjectByCollection', {
      collectionId: this.$route.params.id,
    });
    const knittingProjects = await this.$store.dispatch('knittingProjects/getProjectByCollection', {
      collectionId: this.$route.params.id,
    });

    this.collectionProjects = projects
      .concat(knittingProjects)
      .sort((p1, p2) => p2.createdAt - p1.createdAt);
  },
  mounted() {
    //GA
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Collection',
      firebase_screen_class: 'Collection',
    });
  },
  methods: {
    async deleteCollection() {
      const collectionPictureUrl = this.collection.pictureUrl;
      try {
        await this.$store.dispatch('projects/deleteCollection', {
          collection: this.collection,
        });

        if (
          !collectionIllustrations.find((illustration) => illustration.url === collectionPictureUrl)
        ) {
          await this.$store.dispatch('projects/deleteCollectionPicture', {
            collectionPictureUrl,
          });
        }

        this.$store.dispatch('toasts/setToast', {
          type: 'success',
          content: this.$t('toasts.deleteCollectionSuccess'),
        });
        this.$router.go(-1);
      } catch (e) {
        this.$store.dispatch('toasts/setToast', {
          type: 'error',
          content: this.$t('toasts.deleteCollectionError'),
        });
      }
    },
    addProjects(newProjects) {
      if (newProjects && newProjects.length) {
        this.collectionProjects = this.collectionProjects
          .concat(newProjects)
          .sort((p1, p2) => p2.createdAt - p1.createdAt);
      }

      this.addingProject = false;
    },
  },
};
</script>

<style scoped lang="scss">
.view {
  .content {
    transition: 0.6s;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - (var(--safe-area-top) + 32px) - var(--view-padding));

    .primary-button {
      margin-top: auto;
    }

    &.translate {
      transform: translateX(calc(-100% - var(--view-padding)));
    }

    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.collection-detail {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 32px 0;
  width: fit-content;
  padding-right: 24px;

  &.disabled {
    pointer-events: none;
  }

  .picture {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(29, 43, 43, 0) 0%, rgba(29, 43, 43, 0.48) 100%);
      z-index: 1;
    }

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      appearance: none;
      opacity: 0;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .icon {
      position: absolute;
      bottom: 8px;
      width: 24px;
      color: $white-color;
      pointer-events: none;
      z-index: 2;
    }
  }

  .name {
    font-weight: $font-bold;
  }
}

.projects {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc((100% - 10px) / 2));
  grid-auto-rows: 10px;
  justify-content: space-between;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
</style>
