<template>
  <div class="modal-background small" @click.self="$emit('close')">
    <div class="modal">
      <h1 class="title-1">{{ title }}</h1>
      <p class="description">{{ description }}</p>
      <Stars v-model="selectedStar" />
      <div class="buttons-container">
        <button class="secondary-button" @click="$emit('close')">
          {{ $t('cancel') }}
        </button>
        <button class="primary-button" @click="rate">
          {{ $t('validate') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Stars from '@/components/Stars.vue';

export default {
  name: 'Scoring',
  components: {
    Stars,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
  data() {
    return {
      selectedStar: 0,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
    }),
    title() {
      switch (this.$route.name) {
        case 'pattern':
        case 'knittingPattern':
          return this.$t('patterns.scorePattern');
        case 'fabric':
          return this.$t('fabrics.scoreFabric');
        case 'wool':
          return this.$t('fabrics.scoreWool');
        default:
          return this.$t('projects.scoreProject');
      }
    },
    description() {
      switch (this.$route.name) {
        case 'pattern':
        case 'knittingPattern':
          return this.$t('patterns.scorePatternDescription');
        case 'fabric':
          return this.$t('fabrics.scoreFabricDescription');
        case 'wool':
          return this.$t('fabrics.scoreWoolDescription');
        default:
          return this.$t('projects.scoreProjectDescription');
      }
    },
  },
  mounted() {
    this.selectedStar = this.score;
    document.documentElement.classList.add('no-scroll');
  },
  methods: {
    async rate() {
      switch (this.$route.name) {
        case 'pattern':
        case 'knittingPattern':
          await this.$store.dispatch(
            `${this.sewistaMode ? 'patterns' : 'knittingPatterns'}/scorePattern`,
            {
              patternId: this.$route.params.id,
              score: this.selectedStar,
            }
          );
          this.$emit('close');
          break;
        case 'fabric':
          await this.$store.dispatch('fabrics/scoreFabric', {
            fabricId: this.$route.params.id,
            score: this.selectedStar,
          });
          this.$emit('close');
          break;
        case 'wool':
          await this.$store.dispatch('wools/scoreWool', {
            woolId: this.$route.params.id,
            score: this.selectedStar,
          });
          this.$emit('close');
          break;
        default:
          await this.$store.dispatch(
            `${this.sewistaMode ? 'projects' : 'knittingProjects'}/scoreProject`,
            {
              projectId: this.$route.params.id,
              score: this.selectedStar,
            }
          );
          this.$emit('close');
          break;
      }
    },
  },
  unmounted() {
    document.documentElement.classList.remove('no-scroll');
  },
};
</script>

<style scoped lang="scss"></style>
