<template>
  <div class="view grid-view">
    <div class="content">
      <div class="title fade">
        <h1 class="title-1">
          {{ sewistaMode ? $t('fabrics.fabrics') : $t('fabrics.wools') }}
        </h1>
        <button
          class="filters"
          @click="openFilters"
          v-if="currentList.length || filteredResult"
          :class="{ active: filteredResult }"
        >
          <Filter />
        </button>
      </div>
      <div class="fabrics list-grid" v-if="currentList.length">
        <Card
          v-for="(fabric, index) in currentList"
          :key="fabric"
          :big="index % 5 === 0 || !index"
          :small="index % 3 === 0"
          :cardContent="fabric"
          :routeName="sewistaMode ? 'fabric' : 'wool'"
          :storeName="sewistaMode ? 'fabrics' : 'wools'"
        />
      </div>
      <div class="projects list-grid empty" v-else>
        <img
          :src="sewistaMode ? FabricIllustration : WoolIllustration"
          alt="fabrics"
          class="illustration"
        />
        <Loader v-if="fetching && !filteredResult" class="loader" />

        <p class="description" v-if="filteredResult && !fetching">
          {{ sewistaMode ? $t('fabrics.noFilteredResult') : $t('fabrics.noFilteredResultWool') }}
        </p>
        <p class="description" v-else-if="!filteredResult && !fetching">
          {{ sewistaMode ? $t('fabrics.noFabrics') : $t('fabrics.noWools') }}
        </p>
        <button
          class="primary-button"
          @click="createFabric"
          v-if="!filteredResult && !fetching"
          :class="{ disabled: offline }"
        >
          <Cross />{{ sewistaMode ? $t('fabrics.newFabric') : $t('fabrics.newWool') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { analytics } from '@/utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Loader from '@/assets/svg/loader.svg';

import Card from '@/components/Card.vue';

import Filter from '@/assets/svg/filter.svg';
import WoolIllustration from '@/assets/illustration/wool.png';
import FabricIllustration from '@/assets/illustration/fabric.png';
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'Fabrics',
  components: {
    Card,
    Filter,
    Cross,
    Loader,
  },
  data() {
    return {
      WoolIllustration,
      FabricIllustration,
      pageSize: 8,
      pageNumber: 0,
      fetching: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
      fabrics: 'fabrics/fabrics',
      filteredFabrics: 'fabrics/filteredData',
      filteredWools: 'wools/filteredData',
      fabricFilters: 'fabrics/filters',
      woolFilters: 'wools/filters',
      fabricsDefaultFilters: 'fabrics/defaultFilters',
      woolsDefaultFilters: 'wools/defaultFilters',
      fabricsEndFetching: 'fabrics/endFetching',
      woolsEndFetching: 'wools/endFetching',
      offline: 'auth/offline',
    }),
    currentList() {
      return this.sewistaMode ? this.filteredFabrics : this.filteredWools;
    },
    currentFilters() {
      return this.sewistaMode ? this.fabricFilters : this.woolFilters;
    },
    filteredResult() {
      return !!Object.values(this.currentFilters).filter(
        (filter) => (filter && filter.length) || filter === true
      ).length;
    },
    currentStoreActions() {
      return this.sewistaMode ? 'fabrics/getPaginatedFabrics' : 'wools/getPaginatedWools';
    },
    endFetching() {
      return this.sewistaMode ? this.fabricsEndFetching : this.woolsEndFetching;
    },
  },
  watch: {
    async sewistaMode() {
      if (
        !this.currentList.length ||
        (this.currentList.length < this.pageSize && !this.endFetching)
      ) {
        this.fetching = !this.endFetching;
        await this.$store.dispatch(`${this.currentStoreActions}`, { pageNumber: 0 });
        this.pageNumber = 0;
        this.fetching = false;
      }

      //GA
      logEvent(analytics, 'screen_view', {
        firebase_screen: this.sewistaMode ? 'Fabrics' : 'Wools',
        firebase_screen_class: this.sewistaMode ? 'Fabrics' : 'Wools',
      });
    },
  },
  async created() {
    if (
      !this.currentList.length ||
      (this.currentList.length < this.pageSize && !this.endFetching)
    ) {
      this.fetching = !this.endFetching;

      await this.$store.dispatch(`${this.currentStoreActions}`, { pageNumber: 0 });
      this.fetching = false;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);

    //GA
    logEvent(analytics, 'screen_view', {
      firebase_screen: this.sewistaMode ? 'Fabrics' : 'Wools',
      firebase_screen_class: this.sewistaMode ? 'Fabrics' : 'Wools',
    });
  },
  methods: {
    onScroll() {
      this.updateTitle();
      this.pagination();
    },
    updateTitle() {
      const statusbarHeight = Number(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--safe-area-top')
          .replace('px', '')
      );
      const titlePosition = document.querySelector('.title').getBoundingClientRect().top;

      if (
        titlePosition <= statusbarHeight &&
        !document.querySelector('.title').classList.contains('small')
      ) {
        document.querySelector('.title').classList.add('small');
      } else if (document.documentElement.scrollTop === 0) {
        document.querySelector('.title').classList.remove('small');
      }
    },
    async pagination() {
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = documentHeight - window.innerHeight - window.scrollY;

      if (scrollPosition < 600 && !this.fetching && !this.endFetching) {
        this.fetching = true;
        this.pageNumber += 1;
        await this.$store.dispatch(`${this.currentStoreActions}`, {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.fetching = false;
      }
    },
    async openFilters() {
      await this.$store.dispatch('modals/setModal', {
        modalType: 'FiltersModal',
        modalData: {
          filters: this.currentFilters,
          storeModule: this.sewistaMode ? 'fabrics' : 'wools',
          defaultFilters: this.sewistaMode ? this.fabricsDefaultFilters : this.woolsDefaultFilters,
        },
      });
    },
    async createFabric() {
      if (this.offline) return;
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'FabricCreation' : 'WoolCreation',
        modalData: {},
      });
    },
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.fabrics {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc((100% - 10px) / 2));
  grid-auto-rows: 10px;
  justify-content: space-between;
  margin-top: 32px;
}
</style>
