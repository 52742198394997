<template>
  <div class="form">
    <Input
      v-model="fabric.name"
      :placeholder="$t('fabrics.fabricName')"
      :label="$t('fabrics.fabricName')"
      :required="true"
      ref="name"
    />
    <Input v-model="fabric.brand" :placeholder="$t('fabrics.brand')" :label="$t('fabrics.brand')" />
    <SingleCheckbox :label="$t('fabrics.wishlist')" v-model="fabric.wishlist" />
    <PictureInput
      v-model="fabricFile"
      name="visual"
      :url="fabric.pictureUrl"
      :colorDetection="true"
      :label="$t('fabrics.picture')"
      @updateColor="updateColor"
      :required="true"
      ref="picture"
    />
    <ColorPicker v-model="fabric.color" :label="$t('fabrics.color')" :required="true" ref="color" />
    <Select
      :label="$t('fabrics.material')"
      :options="materials"
      v-model="fabric.material"
      :placeholder="$t('fabrics.materialPlaceholder')"
      :required="true"
      :defaultOptionId="39"
      ref="material"
    />
    <Input
      v-model="fabric.fabricLength"
      :label="$t('fabrics.fabricLength')"
      type="number"
      :min="0"
      placeholder="0"
      ref="fabricLength"
    />
    <Input
      v-model="fabric.fabricWidth"
      :label="$t('fabrics.fabricWidth')"
      type="number"
      :min="0"
      placeholder="0"
      :required="true"
      ref="fabricWidth"
    />
    <Input
      v-model="fabric.fabricWeight"
      :label="$t('fabrics.fabricWeight')"
      type="number"
      :min="0"
      placeholder="0"
    />
    <Textarea
      v-model="fabric.comment"
      :label="$t('fabrics.comment')"
      :placeholder="$t('fabrics.comment')"
    />
    <div class="line">
      <p>{{ $t('fabrics.alreadyWashed') }}</p>
      <SwitchInput v-model="fabric.washed" :labels="[$t('no'), $t('yes')]" />
    </div>
    <p class="mandatory-fields">{{ $t('mandatoryFields') }}</p>
  </div>
</template>

<script>
import { materials } from '@/interfaces/enums';

import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import SwitchInput from '@/components/inputs/SwitchInput.vue';
import PictureInput from '@/components/inputs/PictureInput.vue';
import ColorPicker from '@/components/inputs/ColorPicker.vue';
import Select from '@/components/inputs/Select.vue';
import SingleCheckbox from '@/components/inputs/SingleCheckbox.vue';

export default {
  name: 'FabricForm',
  components: {
    Input,
    Textarea,
    SwitchInput,
    PictureInput,
    ColorPicker,
    Select,
    SingleCheckbox,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['formValidity', 'update:modelValue'],
  data() {
    return {
      fabricFile: null,
      error: false,
      materials,
    };
  },
  computed: {
    isFormValid() {
      return !!(
        this.fabric &&
        this.fabric.name &&
        this.fabric.color &&
        (this.fabricFile || this.fabric.pictureUrl) &&
        this.fabric.material &&
        this.fabric.fabricWidth
      );
    },
    fabric: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
  },
  watch: {
    isFormValid(value) {
      this.$emit('formValidity', value);
    },
  },
  created() {
    this.$emit('formValidity', this.isFormValid);
  },
  methods: {
    updateColor(color) {
      this.fabric.color = color;
    },
    checkForm() {
      const requiredFields = ['name', 'picture', 'color', 'material', 'fabricWidth'];

      let error = false;
      requiredFields.forEach((field) => {
        error = this.$refs[field].check() || error;
      });

      return error;
    },
  },
};
</script>

<style scoped lang="scss">
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  p {
    font-size: 14px;
  }
}
</style>
