<template>
  <button @click="$router.go(-1)" class="go-back" :class="{ light }">
    <BackArrow />{{ $t('back') }}
  </button>
</template>

<script>
import BackArrow from '@/assets/svg/back-arrow.svg';

export default {
  name: 'GoBackButton',
  components: {
    BackArrow,
  },
  props: {
    light: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.go-back {
  display: flex;
  align-items: center;
  font-weight: $font-semibold;
  background: none;
  color: $text-color;
  padding: 10px 10px 10px 0;
  &.light {
    color: $white-color;
  }

  svg {
    width: 22px;
    margin-right: 8px;
  }
}
</style>
