export default {
  async setModal({ state }, { modalData, modalType }) {
    state.modalData = modalData;
    state.modalType = modalType;
    return new Promise((resolve) => {
      const onResolve = (modalReponse) => {
        state.modalData = null;
        state.modalType = null;
        return resolve(modalReponse);
      };
      state.modalResolve = onResolve;
    });
  },

  updateModalData({ state }, { key, data }) {
    state.modalData[key] = data;
  },
};
