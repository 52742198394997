import { actions } from './actions';
import { getters } from './getters';

export const state = {
  user: null,
  credential: null,
  loggedIn: false,
  sewistaMode: true,
  offline: false,
  maintenance: null,
  resizingPicture: false,
  resizingPictureError: false,
  scrollPosition: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
