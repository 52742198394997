import { isMobile, getStorage, isNative, removeStorage } from '@/utils/native';

function reset() {
  removeStorage('margueriteUser');
}

async function routingGuard(to, next, store) {
  const onboardingDone = await getStorage('onboardingDone');
  const user = JSON.parse(await getStorage('margueriteUser'));

  if (
    (to.meta && to.meta.middleware && to.meta.middleware.includes('auth')) ||
    to.name === 'home'
  ) {
    if (!store.state.auth.loggedIn) {
      if (user) {
        try {
          await store.dispatch('auth/initUser');
          if (to.name === 'home') {
            next({ name: 'projects' });
          } else {
            next();
          }
        } catch (e) {
          next({ name: 'login' });
          reset();
        }
      } else {
        next({ name: 'login' });
        reset();
      }
    } else {
      next();
    }
  } else {
    if (!onboardingDone && !user && to.name !== 'onboarding') {
      next({ name: 'onboarding' });
    } else {
      next();
    }
  }
}

export default function authGuard(Vue, { router, store }) {
  router.beforeEach(async (to, from, next) => {
    if (process.env.NODE_ENV === 'development') {
      if (isMobile()) {
        await routingGuard(to, next, store);
      } else if (!isMobile() && !to.meta?.webenable) {
        next({ name: 'home' });
      } else {
        next();
      }
    } else {
      if (isNative()) {
        await routingGuard(to, next, store);
      } else if (!isNative() && !to.meta?.webenable) {
        next({ name: 'home' });
      } else {
        next();
      }
    }
  });
}
