<template>
  <div class="content">
    <div class="title">
      <h2>{{ $t('filter') }}</h2>
      <button class="link-font" @click="reset">{{ $t('reset') }}</button>
    </div>
    <Input v-model="filters.search" :placeholder="searchLabel" :label="searchLabel" />
    <ButtonSelect
      v-model="filters.fabricLengthFilters"
      :options="fabricLengths"
      :label="$t('filters.fabricLength')"
      :multiple="true"
      v-if="filters.fabricLengthFilters"
    />
    <ButtonSelect
      v-model="filters.woolNumbersFilters"
      :options="woolNumbers"
      :label="$t('filters.woolNumber')"
      :multiple="true"
      v-if="filters.woolNumbersFilters"
    />
    <ButtonSelect
      v-model="filters.woolTypes"
      :options="woolTypes"
      :label="$t('filters.woolTypes')"
      :multiple="true"
      v-if="filters.woolTypes"
    />
    <ButtonSelect
      v-model="filters.collections"
      :options="collections"
      :label="$t('filters.collection')"
      :multiple="true"
      v-if="filters.collections && collections.length"
    />
    <ButtonSelect
      v-model="filters.statusFilters"
      :options="projectStatus"
      :label="$t('filters.status')"
      :multiple="true"
      v-if="filters.statusFilters"
    />
    <SingleCheckbox
      :label="$t('filters.wishlist')"
      v-model="filters.wishlist"
      v-if="filters.wishlist !== undefined"
    />
    <ButtonSelect
      v-model="filters.clothTypesFilters"
      :options="clothTypes"
      :label="$t('filters.clothTypes')"
      :multiple="true"
      v-if="filters.clothTypesFilters"
    />
    <ButtonSelect
      v-model="filters.targets"
      :options="targets"
      :label="$t('filters.patternsFor')"
      :multiple="true"
      v-if="filters.targets"
    />
    <ButtonSelect
      v-model="filters.colorFilters"
      :options="colorPalette.filter((option) => option.color)"
      :label="$t('filters.color')"
      :colorPicker="true"
      :multiple="true"
      v-if="filters.colorFilters"
    />
    <ButtonSelect
      v-model="filters.fabricTypeFilters"
      :options="fabricTypes"
      :label="$t('filters.fabricType')"
      :multiple="true"
      v-if="filters.fabricTypeFilters"
    />
    <ButtonSelect
      v-model="filters.scoreFilters"
      :options="[1, 2, 3, 4, 5]"
      :label="$t('filters.score')"
      :scoring="true"
      :multiple="true"
      v-if="filters.scoreFilters"
    />
    <button class="primary-button fullwidth" @click="filter">
      {{ $t('filter') }} ({{ filteredList }})
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  clothTypes,
  projectStatus,
  fabricTypes,
  materials,
  fabricLengths,
  woolNumbers,
  targets,
  woolTypes,
} from '@/interfaces/enums';
import { colorPalette } from '@/utils/colorPalette';
import { cloneDeep } from 'lodash';

import Input from '@/components/inputs/Input.vue';
import ButtonSelect from '@/components/inputs/ButtonSelect.vue';
import SingleCheckbox from '@/components/inputs/SingleCheckbox.vue';

export default {
  name: 'FiltersModal',
  components: {
    Input,
    ButtonSelect,
    SingleCheckbox,
  },
  data() {
    return {
      projectStatus,
      clothTypes,
      colorPalette,
      fabricTypes,
      materials,
      fabricLengths,
      woolNumbers,
      targets,
      woolTypes,
      filters: {},
    };
  },
  computed: {
    ...mapGetters({
      modalResolve: 'modals/modalResolve',
      modalData: 'modals/modalData',
      collections: 'projects/collections',
    }),
    filteredList() {
      return this.$store.getters[`${this.modalData.storeModule}/filteredData`].length;
    },
    searchLabel() {
      if (this.filters.colorFilters) {
        return this.$t('filters.searchByNameBrandAndMaterial');
      } else if (this.filters.targets) {
        return this.$t('filters.searchByNameAndBrand');
      } else {
        return this.$t('filters.searchByName');
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.$store.dispatch(`${this.modalData.storeModule}/setFilters`, this.filters);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  },
  created() {
    this.filters = this.modalData.filters;
  },
  methods: {
    filter() {
      this.modalResolve(this.filters);
    },
    reset() {
      this.filters = cloneDeep(this.modalData.defaultFilters);
      this.$store.dispatch(`${this.modalData.storeModule}/setFilters`, this.filters);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-size: 24px;
  }

  .link-font {
    color: $text-color;
  }
}
</style>
