export const colorPalette = [
  {
    name: 'Beige',
    radiusMin: 30,
    radiusMax: 55,
    saturationMin: 25,
    saturationMax: 50,
    lightMin: 70,
    lightMax: 97,
    color: '#EDE2BD',
    translation: 'colors.beige',
  },
  {
    name: 'Yellow',
    radiusMin: 40,
    radiusMax: 64,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#FFC328',
    translation: 'colors.yellow',
  },
  {
    name: 'Orange',
    radiusMin: 15,
    radiusMax: 39,
    saturationMin: 46,
    saturationMax: 100,
    lightMin: 40,
    lightMax: 97,
    color: '#FF7121',
    translation: 'colors.orange',
  },
  {
    name: 'Red',
    radiusMin: 0,
    radiusMax: 14,
    saturationMin: 41,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#FF3528',
    translation: 'colors.red',
  },
  {
    name: 'Red',
    radiusMin: 346,
    radiusMax: 360,
    saturationMin: 41,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 70,
  },
  {
    name: 'Pink',
    radiusMin: 295,
    radiusMax: 319,
    saturationMin: 50,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#FF72F9',
    translation: 'colors.pink',
  },
  {
    name: 'Pink',
    radiusMin: 320,
    radiusMax: 360,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 70,
    lightMax: 97,
  },
  {
    name: 'Pink',
    radiusMin: 320,
    radiusMax: 345,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
  },
  {
    name: 'Purple',
    radiusMin: 240,
    radiusMax: 320,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#991AFC',
    translation: 'colors.purple',
  },
  {
    name: 'Blue',
    radiusMin: 170,
    radiusMax: 239,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#83CEF8',
    translation: 'colors.blue',
  },
  {
    name: 'Blue',
    radiusMin: 240,
    radiusMax: 259,
    saturationMin: 40,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
  },
  {
    name: 'Green',
    radiusMin: 65,
    radiusMax: 169,
    saturationMin: 10,
    saturationMax: 100,
    lightMin: 11,
    lightMax: 97,
    color: '#56CC6C',
    translation: 'colors.green',
  },
  {
    name: 'Brown',
    radiusMin: 0,
    radiusMax: 39,
    saturationMin: 11,
    saturationMax: 45,
    lightMin: 11,
    lightMax: 97,
    color: '#613603',
    translation: 'colors.brown',
  },
  {
    name: 'Grey',
    radiusMin: 0,
    radiusMax: 360,
    saturationMin: 0,
    saturationMax: 10,
    lightMin: 11,
    lightMax: 97,
    color: '#DED9DD',
    translation: 'colors.grey',
  },
  {
    name: 'Black',
    radiusMin: 0,
    radiusMax: 360,
    saturationMin: 0,
    saturationMax: 100,
    lightMin: 0,
    lightMax: 10,
    color: '#000000',
    translation: 'colors.black',
  },
  {
    name: 'White',
    radiusMin: 0,
    radiusMax: 360,
    saturationMin: 0,
    saturationMax: 100,
    lightMin: 98,
    lightMax: 100,
    color: '#ffffff',
    translation: 'colors.white',
  },
  {
    name: 'Multicoloured',
    radiusMin: -1,
    radiusMax: -1,
    saturationMin: -1,
    saturationMax: -1,
    lightMin: -1,
    lightMax: -1,
    color: 'Multicoloured',
    translation: 'colors.multicoloured',
  },
];

export function RGBToHSL(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
  return [
    Math.round(60 * h < 0 ? 60 * h + 360 : 60 * h),
    Math.round(100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0)),
    Math.round((100 * (2 * l - s)) / 2),
  ];
}

export function getPrimaryColor(hslColor) {
  let primaryColor = colorPalette.find(
    (color) =>
      hslColor[0] >= color.radiusMin &&
      hslColor[0] <= color.radiusMax &&
      hslColor[1] >= color.saturationMin &&
      hslColor[1] <= color.saturationMax &&
      hslColor[2] >= color.lightMin &&
      hslColor[2] <= color.lightMax
  );

  return primaryColor.name;
}
