import { ref, uploadBytes, getDownloadURL, deleteObject, updateMetadata } from 'firebase/storage';
import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage, analytics } from '../../utils/firebaseConfig';
import { cloneDeep } from 'lodash';
import { logEvent } from 'firebase/analytics';

let resizingPictureAttempt = 0;

export default {
  async getResizePicture({ dispatch, state }, { name, fabricId }) {
    const fabricRef = ref(storage, `fabrics/${fabricId}/${name}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(fabricRef);
      updateMetadata(fabricRef, newMetadata);
      updateDoc(doc(db, 'fabrics', fabricId), { pictureUrl: downloadURL });
      dispatch('getOneFabric', { fabricId });
      state.resizingPicture = false;

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizePicture', { name, fabricId });
        }
      }, 1000);
    }
  },
  async storeFabricPicture({ dispatch, state }, { file, fabricId }) {
    const fabricRef = ref(storage, `fabrics/${fabricId}/${file.name}.jpeg`);
    await uploadBytes(fabricRef, file);
    resizingPictureAttempt = 0;
    state.resizingPictureError = false;
    await dispatch('getResizePicture', { name: file.name, fabricId });
  },
  async getPicture({ dispatch }, { item, pictureName }) {
    const pictureRef = ref(storage, `fabrics/${item.id}/${pictureName}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'fabrics', item.id), { ...item, pictureUrl: downloadURL });
      dispatch('getOneFabric', { patternId: item.id });
      return downloadURL;
    } catch (e) {
      return process.env.VUE_APP_DEFAULT_PROJECT_IMAGE;
    }
  },
  async deleteFabricPicture(_, { fabricPictureUrl }) {
    if (!fabricPictureUrl) return;
    try {
      const fabricRef = ref(storage, fabricPictureUrl);
      await deleteObject(fabricRef);
    } catch (e) {
      return '';
    }
  },
  async getNewFabricId() {
    const newDoc = await doc(collection(db, 'fabrics'));
    return newDoc.id;
  },
  async createFabric({ rootState, dispatch }, { fabric, fabricId }) {
    await setDoc(doc(db, 'fabrics', fabricId), {
      ...fabric,
      id: fabricId,
      createdAt: new Date().getTime(),
      userId: rootState.auth.user.uid,
    })
      .then(() => {
        dispatch('getOneFabric', { fabricId });
        logEvent(analytics, 'fabric_create');
      })
      .catch((error) => {
        dispatch('deleteFabricPicture', { fabricPictureUrl: fabric.pictureUrl });
        throw new Error(error);
      });
  },
  async deleteFabric({ dispatch, state }, { fabric }) {
    await deleteDoc(doc(db, 'fabrics', fabric.id))
      .then(() => {
        dispatch('deleteFabricPicture', { fabricPictureUrl: fabric.pictureUrl });

        const existingFabricIndex = state.fabrics.findIndex((f) => fabric.id === f.id);
        state.fabrics.splice(existingFabricIndex, 1);

        logEvent(analytics, 'fabric_delete');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getOneFabric({ state }, { fabricId }) {
    const snapshot = await getDoc(doc(db, 'fabrics', fabricId));

    if (snapshot.exists()) {
      let existingFabricIndex = state.fabrics.findIndex((fabric) => fabric.id === fabricId);

      if (existingFabricIndex >= 0) {
        state.fabrics[existingFabricIndex] = snapshot.data();
      } else {
        state.fabrics.unshift(snapshot.data());
      }
    }
  },
  async getPaginatedFabrics({ state, rootState }, { pageNumber, pageSize = 8 }) {
    if (!state.endFetching) {
      let request = null;

      if (pageNumber === 0) {
        state.fabrics = [];
        request = query(
          collection(db, 'fabrics'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          limit(pageSize)
        );
      } else {
        const lastVisible = state.fabrics[state.fabrics.length - 1];

        request = query(
          collection(db, 'fabrics'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          startAfter(lastVisible.createdAt, lastVisible.id),
          limit(pageSize)
        );
      }

      const snapshot = await getDocs(request);
      snapshot.forEach((doc) => {
        state.fabrics.push(doc.data());
      });

      state.endFetching = snapshot.size < pageSize;
    }
  },
  async editFabric({ dispatch }, { fabric }) {
    updateDoc(doc(db, 'fabrics', fabric.id), fabric);
    dispatch('getOneFabric', { fabricId: fabric.id });
  },
  async scoreFabric({ dispatch, getters }, { fabricId, score }) {
    const currentFabric = getters.fabrics.find((fabric) => fabric.id === fabricId);

    updateDoc(doc(db, 'fabrics', fabricId), { ...currentFabric, score });
    dispatch('getOneFabric', { fabricId });
  },
  async setFilters({ state, rootState, dispatch }, filters) {
    state.filters = filters;

    let request = query(
      collection(db, 'fabrics'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const fabricSnap = await getCountFromServer(request);
    const fabricCount = fabricSnap.data().count;

    if (state.fabrics.length !== fabricCount) {
      dispatch('getAllFabrics');
    }
  },
  async getAllFabrics({ state, rootState }) {
    const lastVisible = state.fabrics[state.fabrics.length - 1];

    let request = null;
    if (lastVisible) {
      request = query(
        collection(db, 'fabrics'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc'),
        startAfter(lastVisible.createdAt, lastVisible.id)
      );
    } else {
      request = query(
        collection(db, 'fabrics'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);
    snapshot.forEach((doc) => {
      state.fabrics.push(doc.data());
    });
    state.endFetching = true;
  },
  async getFabricsByIds({ rootState }, { fabricsId }) {
    const request = query(
      collection(db, 'fabrics'),
      where('userId', '==', rootState.auth.user.uid),
      where('id', 'in', fabricsId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });

    return results;
  },
  async deleteAll({ rootState, dispatch }) {
    const request = query(
      collection(db, 'fabrics'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);
    for (let i = 0; i < snapshot.docs.length; i++) {
      await dispatch('deleteFabric', { fabric: snapshot.docs[i].data() });
    }
  },
  reset({ state }) {
    state.fabrics = [];
    state.filters = cloneDeep(state.defaultFilters);
    state.endFetching = false;
  },
};
