<template>
  <div class="view">
    <div class="content">
      <div class="title-1 logo">marguerite</div>
      <div class="scroller" ref="scroller">
        <div
          class="illustration current"
          ref="currentIllustration"
          :class="{ 'center-right': currentIllustration === illustrations[1] }"
          :style="{ backgroundImage: `url(${currentIllustration})` }"
        ></div>
        <div
          class="illustration next"
          ref="nextIllustration"
          :class="{ 'center-right': nextIllustration === illustrations[1] }"
          :style="{ backgroundImage: `url(${nextIllustration})` }"
        ></div>
      </div>
      <div class="text">
        <h1 class="title-2">
          Découvrez la nouvelle application<br />
          de couture et de tricot !
        </h1>
        <p>
          Ajoutez vos tissus, vos patrons et créez de nouveaux <br />projets plus originaux les uns
          des autres.<br /><br />Téléchargez dès à présent l’application sur les stores <br />et
          profitez-en sans plus attendre !
        </p>
        <div class="buttons-container">
          <a
            href="https://apps.apple.com/fr/app/marguerite-couture-tricot/id6448568910"
            class="store-button"
            target="_blank"
          >
            <img :src="Apple" alt="apple store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.marguerite.app"
            class="store-button"
            target="_blank"
          >
            <img :src="Google" alt="google play store" />
          </a>
        </div>
      </div>
      <router-link class="privacy-link" :to="{ name: 'legal' }">
        {{ $t('auth.condfidentalityRules') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import Illustration1 from '@/assets/illustration/textile.jpg';
import Illustration2 from '@/assets/illustration/patterns.jpg';
import Illustration3 from '@/assets/illustration/projet.jpg';
import Illustration4 from '@/assets/illustration/collection.jpg';
import Google from '@/assets/google.png';
import Apple from '@/assets/apple.png';

export default {
  name: 'Landing',
  data() {
    return {
      illustrations: [Illustration1, Illustration2, Illustration3, Illustration4],
      timer: 0,
      currentIllustration: Illustration1,
      nextIllustration: Illustration2,
      Google,
      Apple,
    };
  },
  created() {
    this.timerInterval = setInterval(() => {
      if (this.timer > 0 && this.timer % 3 === 0) {
        this.timer = 0;
      } else {
        this.timer += 1;
      }
      gsap.to(this.$refs.scroller, {
        scrollTo: { x: this.$refs.nextIllustration },
        duration: 0.628,
        onComplete: () => {
          this.currentIllustration = this.nextIllustration;
          this.nextIllustration =
            this.timer === 3 ? this.illustrations[0] : this.illustrations[this.timer + 1];
          gsap.to(this.$refs.scroller, {
            scrollTo: { x: this.$refs.currentIllustration },
            duration: 0,
          });
        },
      });
    }, 3000);
  },
  unmounted() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style lang="scss" scoped>
.view {
  background: $darkgreen-color;
  height: 100vh;
  padding: 0;

  .logo {
    position: fixed;
    top: 40px;
    left: 56px;
    color: $lightpink-color;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 112px;
    height: 100%;
  }

  .scroller {
    display: flex;
    width: 40vw;
    height: 80vh;
    overflow: hidden;
    align-self: flex-end;
  }

  .illustration {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.center-right {
      background-position: right;
    }
  }

  .text {
    color: $white-color;
    width: 40vw;

    .title-2 {
      line-height: 36px;
    }

    p {
      line-height: 24px;
      margin-bottom: 40px;
    }

    .store-button {
      width: 180px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .privacy-link {
    color: $lightpink-color;
    position: absolute;
    bottom: 24px;
    right: 24px;
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover {
      color: $darkpink-color;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .view {
    height: unset;
    min-height: 100vh;

    .logo {
      position: relative;
      top: unset;
      left: unset;
      margin: 0;
    }
    .privacy-link {
      position: static;
      display: block;
      margin: 24px auto 0;
    }

    .content {
      flex-direction: column;
      align-items: flex-start;
      padding: var(--view-padding);
      gap: 24px;
    }

    .scroller {
      width: 100vw;
      height: 35vh;
      margin: 0 calc(var(--view-padding) * -1);
      flex-shrink: 0;
      align-self: unset;
    }

    .text {
      width: 100%;
    }

    .title-2 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      line-height: 20px;
    }

    .buttons-container {
      flex-direction: column;
      gap: 12px;
    }
  }
}
</style>
