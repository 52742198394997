<template>
  <div
    class="input-container"
    :class="{
      error: errorMessage,
      password: passwordRules,
      required: required,
    }"
  >
    <label class="label" v-if="label">{{ label }}</label>
    <div class="input" :class="{ disabled }">
      <input
        :type="inputType"
        v-model="value"
        :placeholder="placeholder"
        :disabled="disabled"
        pattern="\d*"
        @input="inputCheck"
        v-if="type === 'number'"
      />
      <input
        :id="label"
        :type="inputType"
        v-model="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="formatDefaultValue"
        @input="inputCheck"
        v-else
      />
      <div class="see-password" v-if="type === 'password'" @click="updatePasswordType">
        <EyeOpen ref="eye" class="eye" v-if="inputType === 'password'" />
        <EyeClose v-else />
      </div>
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import EyeOpen from '@/assets/svg/eye-open.svg';
import EyeClose from '@/assets/svg/eye-close.svg';

export default {
  name: 'Input',
  components: {
    EyeOpen,
    EyeClose,
  },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    passwordConfirmation: {
      type: String,
    },
    passwordRules: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      errorMessage: false,
      inputType: '',
    };
  },
  computed: {
    passwordRequirements() {
      const upperCaseRegex = new RegExp('[A-Z]+');
      const lowerCaseRegex = new RegExp('[a-z]+');
      const specialRegex = new RegExp('[^a-zA-Z0-9]+');
      const numberRegex = new RegExp('[0-9]+');
      return (
        this.value.length >= 10 &&
        upperCaseRegex.test(this.value) &&
        lowerCaseRegex.test(this.value) &&
        specialRegex.test(this.value) &&
        numberRegex.test(this.value)
      );
    },
    value: {
      get() {
        return this.modelValue ? this.modelValue : '';
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        if (this.required && newValue) {
          this.errorMessage = '';
        }
        return newValue;
      },
    },
  },
  created() {
    this.inputType = this.type;
  },
  methods: {
    updatePasswordType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    inputCheck() {
      this.errorMessage = '';

      if (this.type === 'number' && this.value) {
        if (this.value < this.min) {
          this.value = this.min;
        } else if (this.max && this.value > this.max) {
          this.value = this.max;
        }
      }

      if (this.required && !this.value) {
        this.errorMessage = this.$t('mandatoryField');
      }

      if (this.passwordConfirmation && this.passwordConfirmation !== this.modelValue) {
        this.errorMessage = this.$t('auth.passwordConfirmationError');
      }

      return !!this.errorMessage;
    },
    check() {
      this.errorMessage = '';

      if (this.required && !this.value) {
        this.errorMessage = this.$t('mandatoryField');
      }

      if (this.passwordRules && this.value && !this.passwordRequirements) {
        this.errorMessage = this.$t('auth.passwordError');
      }

      if (this.type === 'email' && this.value) {
        const regexEmail = new RegExp(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
        );
        if (!regexEmail.test(this.value)) {
          this.errorMessage = this.$t('auth.emailError');
        }
      }

      return !!this.errorMessage;
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;

  .input {
    padding: 0;
  }

  .see-password {
    width: 24px;
    flex-shrink: 0;
    margin-right: 20px;

    svg {
      width: 24px;
    }
  }
}
</style>
