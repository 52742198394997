import { ref, uploadBytes, getDownloadURL, deleteObject, updateMetadata } from 'firebase/storage';
import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage, analytics } from '../../utils/firebaseConfig';
import { cloneDeep } from 'lodash';
import { logEvent } from 'firebase/analytics';
import { collectionIllustrations } from '@/interfaces/enums.json';

let resizingPictureAttempt = 0;

export default {
  async getResizePicture({ dispatch, state }, { name, projectId }) {
    const pictureRef = ref(storage, `projects/${projectId}/${name}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'projects', projectId), { pictureUrl: downloadURL });
      dispatch('getOneProject', { projectId });
      state.resizingPicture = false;

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizePicture', { name, projectId });
        }
      }, 1000);
    }
  },
  async storeProjectPicture({ dispatch, state }, { file, projectId }) {
    if (file) {
      const projectRef = ref(storage, `projects/${projectId}/${file.name}.jpeg`);
      await uploadBytes(projectRef, file);
      resizingPictureAttempt = 0;
      state.resizingPictureError = false;

      await dispatch('getResizePicture', { name: file.name, projectId });
    } else {
      await updateDoc(doc(db, 'projects', projectId), {
        pictureUrl: process.env.VUE_APP_DEFAULT_PROJECT_IMAGE,
      });
      await dispatch('getOneProject', { projectId });
    }
  },
  async getPicture({ dispatch }, { item, pictureName, url }) {
    const pictureRef = ref(storage, `projects/${item.id}/${pictureName}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };

    try {
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'projects', item.id), { ...item, pictureUrl: downloadURL });
      dispatch('getOneProject', { projectId: item.id });
      return downloadURL;
    } catch (e) {
      //todo remove in future release
      if (
        e.code === 'storage/object-not-found' &&
        url === process.env.VUE_APP_DEFAULT_OLD_PROJECT_IMAGE
      ) {
        updateDoc(doc(db, 'projects', item.id), {
          ...item,
          pictureUrl: process.env.VUE_APP_DEFAULT_PROJECT_IMAGE,
        });
        dispatch('getOneProject', { projectId: item.id });
      }

      return process.env.VUE_APP_DEFAULT_PROJECT_IMAGE;
    }
  },
  async getCollectionPicture({ dispatch }, { item, url }) {
    const pictureRef = ref(storage, `collections/${item.id}/visual_200x200.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'collections', item.id), { ...item, pictureUrl: downloadURL });
      dispatch('getOneCollection', { collectionId: item.id });
      return downloadURL;
    } catch (e) {
      const defaultImage = collectionIllustrations.find((col) => col.oldUrl === url);
      if (e.code === 'storage/object-not-found' && defaultImage) {
        updateDoc(doc(db, 'collections', item.id), {
          ...item,
          pictureUrl: defaultImage.url,
        });
        dispatch('getOneCollection', { collectionId: item.id });
        return defaultImage.url;
      }
      return '';
    }
  },
  async getResizeCollectionPicture({ dispatch, state }, { collectionId }) {
    const collectionRef = ref(storage, `collections/${collectionId}/visual_200x200.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(collectionRef);
      updateMetadata(collectionRef, newMetadata);
      updateDoc(doc(db, 'collections', collectionId), { pictureUrl: downloadURL });
      dispatch('getOneCollection', { collectionId });
      state.resizingPicture = false;

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizeCollectionPicture', { collectionId });
        }
      }, 1000);
    }
  },
  async storeCollectionPicture({ dispatch, state }, { file, collectionId }) {
    const collectionRef = ref(storage, `collections/${collectionId}/${file.name}.jpeg`);
    await uploadBytes(collectionRef, file);
    resizingPictureAttempt = 0;
    state.resizingPictureError = false;
    await dispatch('getResizeCollectionPicture', { collectionId });
  },
  async deleteProjectPicture(_, { projectPictureUrl }) {
    if (!projectPictureUrl || projectPictureUrl === process.env.VUE_APP_DEFAULT_PROJECT_IMAGE)
      return;
    try {
      const projectRef = ref(storage, projectPictureUrl);
      await deleteObject(projectRef);
    } catch (e) {
      return '';
    }
  },
  async deleteCollectionPicture(_, { collectionPictureUrl }) {
    if (!collectionPictureUrl) return;
    try {
      const collectionRef = ref(storage, collectionPictureUrl);
      await deleteObject(collectionRef);
    } catch (e) {
      return '';
    }
  },
  async getNewProjectId() {
    const newDoc = await doc(collection(db, 'projects'));
    return newDoc.id;
  },
  async getNewCollectionId() {
    const newDoc = await doc(collection(db, 'collections'));
    return newDoc.id;
  },
  async createProject({ rootState, dispatch }, { project, projectId }) {
    if (project.sewingSupply) {
      project.sewingSupply = [...project.sewingSupply].filter((value) => value.content);
    } else {
      project.sewingSupply = [];
    }
    await setDoc(doc(db, 'projects', projectId), {
      ...project,
      id: projectId,
      createdAt: new Date().getTime(),
      userId: rootState.auth.user.uid,
    })
      .then(() => {
        dispatch('getOneProject', { projectId });
        logEvent(analytics, 'project_create');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async createCollection({ rootState, dispatch }, { collection, collectionId }) {
    await setDoc(doc(db, 'collections', collectionId), {
      ...collection,
      id: collectionId,
      userId: rootState.auth.user.uid,
      createdAt: new Date().getTime(),
    })
      .then(() => {
        dispatch('getOneCollection', { collectionId });
        logEvent(analytics, 'collection_create');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getOneProject({ state }, { projectId }) {
    const snapshot = await getDoc(doc(db, 'projects', projectId));
    if (snapshot.exists()) {
      let existingProjectIndex = state.projects.findIndex((project) => project.id === projectId);
      if (existingProjectIndex >= 0) {
        state.projects[existingProjectIndex] = snapshot.data();
      } else {
        state.projects.unshift(snapshot.data());
      }
    }
  },
  async getOneCollection({ state }, { collectionId }) {
    const snapshot = await getDoc(doc(db, 'collections', collectionId));
    if (snapshot.exists()) {
      let existingCollectionIndex = state.collections.findIndex(
        (collection) => collection.id === collectionId
      );

      if (existingCollectionIndex >= 0) {
        state.collections[existingCollectionIndex] = snapshot.data();
      } else {
        state.collections.unshift(snapshot.data());
      }
    }
  },
  async getPaginatedProjects({ state, rootState }, { pageNumber, pageSize = 8 }) {
    //update cache on pro
    // const pref = ref(storage, `project-placeholder.jpg`);
    // updateMetadata(pref, {
    //   cacheControl: 'public,max-age=604800',
    //   contentType: 'image/jpeg',
    // });
    if (!state.endFetching) {
      let request = null;

      if (pageNumber === 0) {
        state.projects = [];
        request = query(
          collection(db, 'projects'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          limit(pageSize)
        );
      } else {
        const lastVisible = state.projects[state.projects.length - 1];

        request = query(
          collection(db, 'projects'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          startAfter(lastVisible.createdAt, lastVisible.id),
          limit(pageSize)
        );
      }
      const snapshot = await getDocs(request);
      snapshot.forEach((doc) => {
        state.projects.push(doc.data());
      });

      state.endFetching = snapshot.size < pageSize;
    }
  },
  async getAllCollections({ state, rootState }) {
    if (!state.collections.length) {
      const request = query(
        collection(db, 'collections'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc')
      );

      const snapshot = await getDocs(request);
      snapshot.forEach((doc) => {
        state.collections.push(doc.data());
      });
      state.collectionEndFetching = true;
    }
  },
  editProject({ dispatch }, { project }) {
    if (project.sewingSupply) {
      project.sewingSupply = [...project.sewingSupply].filter((value) => value.content);
    } else {
      project.sewingSupply = [];
    }
    project.recommandedFabrics = project.recommandedFabrics || [];

    updateDoc(doc(db, 'projects', project.id), project);
    dispatch('getOneProject', { projectId: project.id });
  },
  editCollection({ dispatch }, { collection }) {
    updateDoc(doc(db, 'collections', collection.id), collection);
    dispatch('getOneCollection', { collectionId: collection.id });
  },
  async deleteProject({ dispatch, state }, { project }) {
    await deleteDoc(doc(db, 'projects', project.id))
      .then(() => {
        dispatch('deleteProjectPicture', { projectPictureUrl: project.pictureUrl });

        const existingProjectIndex = state.projects.findIndex((p) => project.id === p.id);
        state.projects.splice(existingProjectIndex, 1);

        logEvent(analytics, 'project_delete');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteCollection({ state }, { collection }) {
    await deleteDoc(doc(db, 'collections', collection.id))
      .then(() => {
        const existingCollectionIndex = state.collections.findIndex((c) => collection.id === c.id);
        state.collections.splice(existingCollectionIndex, 1);

        logEvent(analytics, 'collection_delete');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  scoreProject({ dispatch, getters }, { projectId, score }) {
    const currentProject = getters.projects.find((project) => project.id === projectId);

    updateDoc(doc(db, 'projects', projectId), { ...currentProject, score });
    dispatch('getOneProject', { projectId });
  },
  async setFilters({ state, rootState, dispatch }, filters) {
    state.filters = filters;

    let request = query(
      collection(db, 'projects'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const projectsSnap = await getCountFromServer(request);
    const projectsCount = projectsSnap.data().count;

    if (state.projects.length !== projectsCount) {
      dispatch('getAllProjects');
    }
  },
  async getAllProjects({ state, rootState }) {
    const lastVisible = state.projects[state.projects.length - 1];
    let request = null;

    if (lastVisible) {
      request = query(
        collection(db, 'projects'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc'),
        startAfter(lastVisible.createdAt, lastVisible.id)
      );
    } else {
      request = query(
        collection(db, 'projects'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);

    snapshot.forEach((doc) => {
      state.projects.push(doc.data());
    });
    state.endFetching = true;
  },
  async getRelatedProjects({ rootState }, { fabricId, patternId }) {
    if (!fabricId && !patternId) return [];

    let request = null;

    if (fabricId) {
      request = query(
        collection(db, 'projects'),
        where('userId', '==', rootState.auth.user.uid),
        where('fabricsId', 'array-contains', fabricId),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    } else {
      request = query(
        collection(db, 'projects'),
        where('userId', '==', rootState.auth.user.uid),
        where('patternsId', 'array-contains', patternId),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  },
  async getProjectByCollection({ rootState }, { collectionId }) {
    const request = query(
      collection(db, 'projects'),
      where('userId', '==', rootState.auth.user.uid),
      where('collectionId', '==', collectionId),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  },
  setCollectionFilter({ state }, { collections }) {
    state.filters.collections = collections;
  },
  async deleteAll({ rootState, dispatch }) {
    const request = query(
      collection(db, 'projects'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);
    for (let i = 0; i < snapshot.docs.length; i++) {
      await dispatch('deleteProject', { project: snapshot.docs[i].data() });
    }
  },
  async deleteAllCollections({ rootState, dispatch }) {
    const request = query(
      collection(db, 'collections'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc')
    );

    const snapshot = await getDocs(request);
    for (let i = 0; i < snapshot.docs.length; i++) {
      await dispatch('deleteCollection', { collection: snapshot.docs[i].data() });
    }
  },
  reset({ state }) {
    state.projects = [];
    state.filters = cloneDeep(state.defaultFilters);
    state.collections = [];
    state.endFetching = false;
    state.collectionEndFetching = false;
  },
  markAsDone({ dispatch }, { project }) {
    updateDoc(doc(db, 'projects', project.id), project);
    dispatch('getOneProject', { projectId: project.id });
    logEvent(analytics, 'project_done');
  },
};
