<template>
  <div class="modal-background small" @click.self="$emit('close')">
    <div class="modal">
      <h1 class="title-1">{{ title }}</h1>
      <p class="description">{{ description }}</p>
      <div class="buttons-container">
        <button class="secondary-button" @click="$emit('close')">
          {{ $t('cancel') }}
        </button>
        <button class="primary-button" :class="{ dangerous }" @click="$emit('confirm')">
          {{ button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    button: {
      type: String,
    },
    dangerous: {
      type: Boolean,
    },
  },
  emits: ['close', 'confirm'],
  mounted() {
    document.documentElement.classList.add('no-scroll');
  },
  unmounted() {
    document.documentElement.classList.remove('no-scroll');
  },
};
</script>

<style scoped lang="scss"></style>
