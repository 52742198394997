<template>
  <div
    class="score-tag"
    :class="{ empty: score == null, fade: score == null }"
    @click="scoreModal = true"
  >
    {{ score == null ? $t('score') : score }}<Star />
    <Teleport to="#app">
      <transition name="small-modal">
        <ScoringModal v-if="scoreModal" @close="closeModal" :score="score" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import Star from '@/assets/svg/etoile.svg';
import ScoringModal from '@/components/modals/ScoringModal.vue';

export default {
  name: 'ScoreTag',
  components: {
    Star,
    ScoringModal,
  },
  props: {
    score: {
      type: Number,
    },
  },
  data() {
    return {
      scoreModal: false,
    };
  },
  methods: {
    closeModal() {
      this.scoreModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.score-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 42px;
  padding: 0 5px;
  background: $white-color;
  border: 1px solid $white-color;
  color: $yellow-color;
  border-radius: 100px;
  font-size: 14px;
  font-weight: $font-regular;
  transition: 0.3s;

  &.empty {
    background: rgba($white-color, 0);
    color: $white-color;
  }

  svg {
    width: 12px;
    margin-left: 2px;
    color: $yellow-color;
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .score-tag {
    height: 42px;
    padding: 0 12px;
    font-size: 16px;

    svg {
      width: 20px;
      margin-left: 6px;
    }
  }
}
</style>
