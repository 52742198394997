import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';
import gsap from 'gsap';
import i18n from '@/i18n';

export let keyboardOpen = false;

export async function hapticsImpact(stength) {
  if (isNative()) {
    await Haptics.impact({ style: ImpactStyle[stength] });
  }
}

export async function setStorage(key, value) {
  if (isNative()) {
    await Preferences.set({
      key,
      value,
    });
  } else {
    localStorage.setItem(key, value);
  }
}

export async function getStorage(key) {
  if (isNative()) {
    const response = await Preferences.get({
      key,
    });
    return response.value;
  } else {
    return localStorage.getItem(key);
  }
}

export async function removeStorage(key) {
  if (isNative()) {
    await Preferences.remove({
      key,
    });
  } else {
    localStorage.removeItem(key);
  }
}

export function isNative() {
  return Capacitor.isNativePlatform();
}

export function getPlatform() {
  return Capacitor.getPlatform();
}

export async function getOperatingSystem() {
  const info = await Device.getInfo();
  return info.operatingSystem;
}

export function isMobile() {
  return window.matchMedia('(max-width: 991px)').matches;
}

function openKeyboard(info, className, animation) {
  keyboardOpen = true;
  document.querySelector(className).style.paddingBottom = `${info.keyboardHeight + 32}px`;

  if (animation) {
    const focusElement = document.querySelector('*:focus');

    gsap.to(className, {
      scrollTo: {
        y: focusElement,
        offsetY: 64,
      },
      duration: 0.3,
    });
  }
}

function closeKeyboard(className) {
  keyboardOpen = false;
  document.querySelector(className).style.paddingBottom = '32px';
}

export async function setKeyboard(className, animation) {
  if (Capacitor.isNativePlatform()) {
    await Keyboard.setAccessoryBarVisible({ isVisible: true });

    Keyboard.addListener('keyboardWillShow', (info) => openKeyboard(info, className, animation));

    Keyboard.addListener('keyboardWillHide', () => closeKeyboard(className));
  }
}

export async function killKeyboard() {
  if (Capacitor.isNativePlatform()) {
    await Keyboard.removeAllListeners();
  }
}

export async function setStatusBarStyle() {
  if (Capacitor.isNativePlatform()) {
    await StatusBar.setStyle({ style: Style.Light });
  }
}

export async function checkNetworkStatus(store) {
  const status = await Network.getStatus();
  store.dispatch('auth/setOfflineMode', {
    isOffline: !status.connected,
  });

  if (!status.connected) {
    store.dispatch('toasts/setToast', {
      type: 'error',
      content: i18n.global.t('toasts.offlineMode'),
      duration: 5000,
    });
  }

  Network.addListener('networkStatusChange', (status) => {
    if (!status.connected) {
      store.dispatch('toasts/setToast', {
        type: 'error',
        content: i18n.global.t('toasts.offlineMode'),
        duration: 5000,
      });
      store.dispatch('auth/setOfflineMode', {
        isOffline: true,
      });
    } else {
      store.dispatch('auth/setOfflineMode', {
        isOffline: false,
      });
    }
  });
}

export function removeNetworkListeners() {
  Network.removeAllListeners();
}

export async function getVersion() {
  if (!isNative()) return 'v. 1.3';
  const info = await App.getInfo();
  return `v. ${info.version}`;
}
