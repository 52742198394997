<template>
  <div class="illustration" :class="{ create: !collection }">
    <img
      :src="pictureUrl"
      :alt="collection.name"
      :onError="getPictureUrl"
      loading="lazy"
      :onLoad="() => (loaded = true)"
      :class="{ loaded }"
      v-if="collection"
    />
    <Cross v-else />
  </div>
</template>

<script>
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'CollectionsImage',
  components: {
    Cross,
  },
  props: {
    collection: {
      type: Object,
    },
  },
  data() {
    return {
      pictureUrl: null,
      loaded: false,
    };
  },
  watch: {
    'collection.pictureUrl'(value) {
      if (value !== this.pictureUrl) {
        this.pictureUrl = value;
      }
    },
  },
  created() {
    if (this.collection) {
      this.pictureUrl = this.collection.pictureUrl;
    }
  },
  methods: {
    async getPictureUrl() {
      this.error = true;
      this.pictureUrl = await this.$store.dispatch('projects/getCollectionPicture', {
        item: this.collection,
        url: this.collection.pictureUrl,
      });
      this.error = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.illustration {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  background: var(--primary-light-color);
  border: 1px solid $white-color;

  &.create {
    background: var(--primary-light-color);
    padding: 0;
    width: 72px;
    height: 72px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;

    &.loaded {
      opacity: 1;
    }
  }

  svg {
    color: $white-color;
    width: 24px;
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .illustration {
    width: 84px;
    height: 84px;

    &.create {
      width: 84px;
      height: 84px;
    }
  }
}
</style>
