<template>
  <div class="modal view no-switch no-navbar">
    <button @click="$emit('close')" class="go-back"><BackArrow />{{ $t('back') }}</button>
    <div class="projects list-grid">
      <div
        v-for="project in allProjects"
        :key="project"
        @click="selectProject(project)"
        class="element"
        :class="{
          selected: selection.find((selection) => selection.id === project.id),
        }"
      >
        <Card
          :cardContent="project"
          :storeName="project.woolMetric ? 'knittingProjects' : 'projects'"
        />
      </div>
    </div>
    <button class="primary-button" @click="addProjects">
      {{ $t('add') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Card from '@/components/Card.vue';

import BackArrow from '@/assets/svg/back-arrow.svg';

export default {
  name: 'ProjectsModal',
  props: {
    collectionId: {
      type: String,
    },
  },
  components: {
    Card,
    BackArrow,
  },
  emits: ['close'],
  data() {
    return {
      selection: [],
    };
  },
  computed: {
    ...mapGetters({
      projects: 'projects/projects',
      knittingProjects: 'knittingProjects/projects',
      collections: 'projects/collections',
    }),
    allProjects() {
      return this.projects
        .concat(this.knittingProjects)
        .filter(
          (project) =>
            !project.collectionId ||
            !this.collections.find((collection) => collection.id === project.collectionId)
        )
        .sort((p1, p2) => {
          return p2.createdAt - p1.createdAt;
        });
    },
  },
  async created() {
    await this.$store.dispatch('projects/getAllProjects');
    await this.$store.dispatch('knittingProjects/getAllProjects');
  },
  methods: {
    selectProject(project) {
      if (this.selection.find((p) => p.id === project.id)) {
        this.selection = this.selection.filter((s) => s.id !== project.id);
      } else {
        this.selection.push(project);
      }
    },
    addProjects() {
      this.selection.forEach((project) => {
        if (project.woolMetric) {
          this.$store.dispatch('knittingProjects/editProject', {
            project: {
              ...project,
              collectionId: this.collectionId,
            },
          });
        } else {
          this.$store.dispatch('projects/editProject', {
            project: {
              ...project,
              collectionId: this.collectionId,
            },
          });
        }
      });
      this.$emit('close', this.selection);
    },
  },
};
</script>

<style scoped lang="scss">
.modal.view {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white-color;
  z-index: 1000;
  overflow: auto;

  .go-back {
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-weight: $font-semibold;
    background: none;
    color: $text-color;
    width: 50%;

    svg {
      width: 22px;
      margin-right: 8px;
    }
  }

  .projects {
    padding-bottom: 56px;
  }

  .element {
    position: relative;
    width: 100%;
    height: 240px;
    grid-row-end: span 25;
    opacity: 0.5;
    transition: 0.3s;

    .card {
      pointer-events: none;
    }

    &.selected {
      opacity: 1;
    }
  }

  .primary-button {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + var(--view-padding));
    left: var(--view-padding);
    right: var(--view-padding);
    padding: 24px;
  }
}
</style>
