<template>
  <div class="modal-background" @click.self="modalResolve(false)">
    <div class="modal" ref="modal">
      <component :is="modalType" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setKeyboard, killKeyboard } from '@/utils/native';

import ProjectCreation from '@/components/modals/ProjectCreation.vue';
import FabricCreation from '@/components/modals/FabricCreation.vue';
import PatternCreation from '@/components/modals/PatternCreation.vue';
import FiltersModal from '@/components/modals/FiltersModal.vue';
import WoolCreation from '@/components/modals/WoolCreation.vue';
import KnittingPatternCreation from '@/components/modals/KnittingPatternCreation.vue';
import KnittingProjectCreation from '@/components/modals/KnittingProjectCreation.vue';

export default {
  name: 'ModalTemplate',
  components: {
    ProjectCreation,
    FabricCreation,
    PatternCreation,
    FiltersModal,
    WoolCreation,
    KnittingPatternCreation,
    KnittingProjectCreation,
  },
  data() {
    return {
      touching: 0,
      closingModal: false,
      delta: 0,
      keyboardOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      modalResolve: 'modals/modalResolve',
      modalType: 'modals/modalType',
    }),
  },
  async mounted() {
    this.$refs.modal.addEventListener('touchstart', this.onTouchStart);
    this.$refs.modal.addEventListener('touchmove', this.onTouchMove);
    this.$refs.modal.addEventListener('touchend', this.onTouchEnd);

    await setKeyboard('.modal .content', true);
  },
  methods: {
    onTouchStart(e) {
      if (this.keyboardOpen) return;
      this.touching = e.touches[0].clientY;
    },
    onTouchMove(e) {
      if (this.keyboardOpen) return;
      this.closingModal = true;
      this.delta = e.touches[0].clientY - this.touching;
      const progress = this.delta / this.$refs.modal.clientHeight;
      const scroller = document.querySelector('.modal .content');
      if (this.delta > 0 && scroller.scrollTop <= 0) {
        this.$refs.modal.style.bottom = `-${Math.round(progress * 100)}%`;
      }
    },
    onTouchEnd() {
      const scroller = document.querySelector('.modal .content');

      if (this.closingModal && scroller.scrollTop <= 0 && this.delta > 100 && !this.keyboardOpen) {
        this.modalResolve(false);
        this.touching = 0;
        this.closingModal = false;
      } else {
        this.$refs.modal.style.bottom = 0;
        this.delta = 0;
        this.touching = 0;
      }
    },
  },
  beforeUnmount() {
    this.$refs.modal.removeEventListener('touchstart', this.onTouchStart);
    this.$refs.modal.removeEventListener('touchmove', this.onTouchMove);
    this.$refs.modal.removeEventListener('touchend', this.onTouchEnd);
    killKeyboard();
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  bottom: 0;

  .content {
    @include hiddeScrollbar;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px 18px;
    background: $white-color;
    border-radius: 24px 24px 0 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: -14px;
    height: 5px;
    width: 48px;
    border-radius: 5px;
    background: $white-color;
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .modal {
    .content {
      padding: 42px 52px;
    }
  }
}
</style>
