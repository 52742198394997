<template>
  <div class="view">
    <div class="content">
      <div class="header">
        <div class="picture">
          <input
            type="file"
            @input="setPicture"
            name="user picture"
            accept="capture=camera,image/*"
          />
          <img ref="img" :src="user.photoURL" alt="picture" loading="lazy" v-if="user.photoURL" />
          <Edit class="icon" v-if="!offline" />
        </div>
        <h1
          class="title-1"
          :class="{ disabled: offline }"
          v-if="user.displayName"
          @click="updatingDisplayName = true"
        >
          {{ user.displayName }}
        </h1>
        <button class="link-font pseudo" v-else @click="updatingDisplayName = true">
          {{ $t('auth.addDisplayName') }}
        </button>
      </div>
      <router-link class="section" :to="{ name: 'measurements' }" :class="{ disabled: offline }">
        {{ $t('measurements.title') }}<Arrow />
      </router-link>
      <router-link
        class="section"
        :class="{ disabled: offline }"
        :to="{ name: 'accountInformations' }"
      >
        {{ $t('auth.myInformations') }}<Arrow />
      </router-link>
      <router-link class="section" :to="{ name: 'legal' }">
        {{ $t('auth.condfidentalityRules') }}<Arrow />
      </router-link>
      <button @click="logout" class="section" :class="{ disabled: offline }">
        {{ $t('auth.logout') }}<Arrow />
      </button>

      <p class="signature">
        {{ $t('madeWithLove') }} <br />
        {{ appVersion }}
      </p>
      <a class="instagram" href="https://www.instagram.com/marguerite_moods/?hl=fr" target="_blank"
        >@marguerite_moods</a
      >
    </div>
    <Teleport to="#app">
      <transition name="small-modal">
        <DisplayNameModal v-if="updatingDisplayName" @close="updatingDisplayName = false" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getVersion } from '@/utils/native';
import { analytics } from '@/utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';

import DisplayNameModal from '@/components/modals/DisplayNameModal.vue';

import Arrow from '@/assets/svg/arrow.svg';
import Edit from '@/assets/svg/edit.svg';

export default {
  name: 'Account',
  components: {
    Arrow,
    DisplayNameModal,
    Edit,
  },
  data() {
    return {
      updatingDisplayName: false,
      appVersion: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      offline: 'auth/offline',
      resizingPicture: 'auth/resizingPicture',
      resizingPictureError: 'auth/resizingPictureError',
    }),
  },
  async mounted() {
    this.$store.dispatch('auth/getAppConfig');
    this.appVersion = await getVersion();

    //GA
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Account',
      firebase_screen_class: 'Account',
    });
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('projects/reset');
      this.$store.dispatch('fabrics/reset');
      this.$store.dispatch('patterns/reset');
      this.$store.dispatch('knittingProjects/reset');
      this.$store.dispatch('knittingPatterns/reset');
      this.$store.dispatch('wools/reset');
      this.$router.push({ name: 'login' });
    },
    async setPicture(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const pictureFile = new File([file], 'user-picture', {
        type: file.type,
      });

      if (this.user.photoURL) {
        await this.$store.dispatch('auth/deleteUserPicture', {
          userPictureUrl: this.user.photoURL,
        });
      }
      await this.$store.dispatch('auth/storeUserPicture', {
        file: pictureFile,
      });

      const imageInterval = setInterval(() => {
        if (!this.resizingPicture) {
          clearInterval(imageInterval);
          if (this.resizingPictureError) {
            this.$store.dispatch('toasts/setToast', {
              type: 'error',
              content: this.$t('toasts.pictureError'),
            });
          }
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  display: flex;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: calc(var(--view-padding) * -1);
    right: calc(var(--view-padding) * -1);
    top: calc((var(--safe-area-top) + 72px) * -1);
    height: calc(128px + var(--safe-area-top));
    background: var(--primary-light-color);
    z-index: 0;
  }

  .picture {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    border-radius: 100%;
    background: var(--primary-color);
    margin-bottom: 24px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(29, 43, 43, 0) 0%, rgba(29, 43, 43, 0.3) 100%);
      z-index: 0;
      transition: 0.628s;
      pointer-events: none;
    }

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      appearance: none;
      opacity: 0;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .icon {
      position: absolute;
      bottom: 16px;
      width: 24px;
      color: $white-color;
      pointer-events: none;
      z-index: 2;
    }
  }
}

.title-1,
.pseudo {
  margin-bottom: 32px;

  &.disabled {
    pointer-events: none;
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid $lightgrey-color;
  background: none;
  font-size: 14px;
  font-weight: $font-semibold;
  text-align: left;
  color: $text-color;

  &:last-child {
    border-bottom: 1px solid $lightgrey-color;
  }

  svg {
    width: 14px;
    rotate: 90deg;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.signature {
  display: flex;
  flex: 1;
  align-items: flex-end;
  color: $text-color;
}

.instagram {
  color: $pink-color;
}

.instagram,
.signature {
  font-size: 11px;
  line-height: 18px;
  font-weight: $font-semibold;
  text-align: center;
}

@media (min-width: $layout-breakpoint-medium) {
  .signature {
    bottom: calc(var(--navbar-height) + 82px);
    font-size: 14px;
  }
}
</style>
