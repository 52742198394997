import actions from './actions';
import getters from './getters';

const state = {
  toast: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
