<template>
  <nav class="navbar" :class="{ extend }">
    <MenuShape class="shape" />
    <router-link class="nav-link" :to="{ name: 'projects' }">
      <Project v-if="sewistaMode" />
      <Needles v-else />
      <span>{{ $t('projectsNav') }}</span>
    </router-link>
    <router-link class="nav-link" :to="{ name: 'patterns' }">
      <Pattern />
      <span>{{ $t('patternsNav') }}</span>
    </router-link>
    <button
      class="main-button"
      :class="{ disabled: offline }"
      @click="add"
      v-clickout="() => (extend = false)"
    >
      <Cross />
    </button>
    <button class="add-button project" @click="createProject">
      <Project v-if="sewistaMode" />
      <Needles v-else />
    </button>
    <button class="add-button pattern" @click="createPattern">
      <Pattern />
    </button>
    <button class="add-button fabric" @click="createFabric">
      <Fabric v-if="sewistaMode" />
      <Knitting v-else />
    </button>

    <router-link class="nav-link" :to="{ name: 'fabrics' }">
      <Fabric v-if="sewistaMode" />
      <Knitting v-else />
      <span>{{ sewistaMode ? $t('fabricsNav') : $t('woolsNav') }}</span>
    </router-link>
    <router-link class="nav-link" :to="{ name: 'account' }">
      <div class="profile">
        <img
          :src="pictureUrl"
          :alt="user.uid"
          :onError="getPictureUrl"
          loading="lazy"
          :onLoad="() => (loaded = true)"
          :class="{ loaded }"
          v-if="user && user?.photoURL"
        />
      </div>
      <span>{{ $t('profielNav') }}</span>
    </router-link>
    <!-- <div class="active-state" :class="$route.name"></div> -->
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { hapticsImpact } from '@/utils/native';

import MenuShape from '@/assets/svg/menu.svg';
import Cross from '@/assets/svg/cross.svg';
import Pattern from '@/assets/svg/patrons.svg';
import Project from '@/assets/svg/projets.svg';
import Needles from '@/assets/svg/aiguille.svg';
import Fabric from '@/assets/svg/tissus.svg';
import Knitting from '@/assets/svg/tricots.svg';

export default {
  name: 'Navbar',
  components: {
    MenuShape,
    Cross,
    Pattern,
    Project,
    Fabric,
    Knitting,
    Needles,
  },
  data() {
    return {
      extend: false,
      pictureUrl: null,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      sewistaMode: 'auth/sewistaMode',
      user: 'auth/user',
      offline: 'auth/offline',
    }),
  },
  watch: {
    user() {
      if (this.pictureUrl !== this.user?.photoURL) {
        this.pictureUrl = this.user?.photoURL;
      }
    },
  },
  created() {
    this.pictureUrl = this.user?.photoURL;
  },
  methods: {
    async getPictureUrl() {
      this.pictureUrl = await this.$store.dispatch('auth/getPicture');
    },
    async add() {
      if (this.offline) return;
      await hapticsImpact('MEDIUM');
      this.extend = !this.extend;
    },
    async createProject() {
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'ProjectCreation' : 'KnittingProjectCreation',
        modalData: {},
      });
    },
    async createFabric() {
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'FabricCreation' : 'WoolCreation',
        modalData: {},
      });
    },
    async createPattern() {
      await this.$store.dispatch('modals/setModal', {
        modalType: this.sewistaMode ? 'PatternCreation' : 'KnittingPatternCreation',
        modalData: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  --main-btn-width: 64px;
  position: fixed;
  bottom: -2px;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: calc(84px + env(safe-area-inset-bottom));
  padding-bottom: 0;

  * {
    user-select: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: env(safe-area-inset-bottom);
    background: $white-color;
    z-index: 0;
  }

  &.extend {
    .add-button {
      &.project {
        opacity: 1;
        transform: translate(-68px, min(calc((env(safe-area-inset-bottom) + 25px) * -1), -50px))
          scale(1);
      }

      &.pattern {
        opacity: 1;
        transform: translate(0, min(calc((env(safe-area-inset-bottom) + 60px) * -1), -84px))
          scale(1);
      }

      &.fabric {
        opacity: 1;
        transform: translate(68px, min(calc((env(safe-area-inset-bottom) + 25px) * -1), -50px))
          scale(1);
      }
    }

    .main-button {
      background: var(--secondary-color);
      box-shadow: 0px 0px 8px 0px var(--secondary-color);
      transform: rotate(45deg);
    }
  }

  .shape {
    position: absolute;
    top: -12px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .nav-link {
    width: calc((100% - var(--main-btn-width)) / 4);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: $lightgrey-color;
    padding: 24px 10px env(safe-area-inset-bottom);
    transition: 0.4s;
    user-select: none;

    .profile {
      border: 2px solid $white-color;
    }

    &.router-link-active {
      color: var(--primary-color);

      .profile {
        border: 2px solid var(--primary-color);
      }
    }

    svg {
      width: 24px;
    }

    span {
      font-size: 12px;
      text-align: center;
      font-weight: $font-semibold;
    }

    .profile {
      position: relative;
      overflow: hidden;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--primary-color);
      transition: 0.4s;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.6s ease-in-out;

        &.loaded {
          opacity: 1;
        }
      }
    }
  }

  .main-button {
    position: relative;
    bottom: max(calc(24px + env(safe-area-inset-bottom)), 42px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: var(--main-btn-width);
    height: var(--main-btn-width);
    border-radius: 50%;
    background: var(--primary-color);
    box-shadow: 0px 0px 8px 0px var(--primary-color);
    transition: 0.4s;

    svg {
      width: 24px;
      color: $white-color;
    }

    &.disabled {
      opacity: 0.3;
    }
  }

  .add-button {
    position: absolute;
    bottom: max(calc(var(--safe-area-bottom) + 32px), 42px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: var(--primary-color);
    z-index: 0;
    transform: scale(0.3);
    opacity: 0;
    transition: 0.4s ease-in-out;

    svg {
      width: 24px;
      color: $white-color;
    }
  }

  .active-state {
    position: absolute;
    bottom: calc(12px + env(safe-area-inset-bottom));
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--primary-color);
    $link-width: calc((100% - var(--main-btn-width)) / 4);
    transition: 0.4s;

    &.projects {
      left: calc($link-width / 2 - 3px);
    }

    &.patterns {
      left: calc(#{$link-width} * 1.5 - 3px);
    }

    &.fabrics {
      left: calc(#{$link-width} * 2.5 + var(--main-btn-width) - 3px);
    }

    &.account {
      left: calc(#{$link-width} * 3.5 + var(--main-btn-width) - 3px);
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .navbar {
    --main-btn-width: 84px;
    min-height: calc(140px + env(safe-area-inset-bottom));
    padding-top: 32px;

    .shape {
      top: 0;
    }

    &.extend {
      .add-button {
        &.project {
          transform: translate(-68px, min(calc((env(safe-area-inset-bottom) + 25px) * -1), -70px))
            scale(1);
        }

        &.pattern {
          transform: translate(0, min(calc((env(safe-area-inset-bottom) + 60px) * -1), -104px))
            scale(1);
        }

        &.fabric {
          transform: translate(68px, min(calc((env(safe-area-inset-bottom) + 25px) * -1), -70px))
            scale(1);
        }
      }
    }

    .nav-link {
      svg {
        width: 34px;
      }

      span {
        font-size: 14px;
      }

      .profile {
        width: 34px;
        height: 34px;
      }
    }

    .main-button {
      svg {
        width: 34px;
      }
    }
  }
}
</style>
