<template>
  <div class="input-container" :class="{ error: errorMessage, required: required }">
    <label class="label">{{ label }}</label>
    <div class="options">
      <div
        class="option"
        v-for="option in options"
        :key="option.id"
        :class="{ checked: modelValue === option.id, disabled }"
        @click="
          $emit('update:modelValue', option.id);
          errorMessage = '';
        "
      >
        <div class="checkbox"></div>
        {{ $t(option.translation) }}
      </div>
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Radios',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      errorMessage: '',
    };
  },
  watch: {
    modelValue(value) {
      if (value) {
        this.errorMessage = '';
      }
    },
  },
  methods: {
    check() {
      if (this.required && !this.modelValue) {
        this.errorMessage = this.$t('mandatoryField');
      }

      return !!this.errorMessage;
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;

  &.error {
    .option {
      color: $red-color;

      .checkbox {
        border-color: $red-color;
      }
    }
  }
}

.options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.option {
  display: flex;
  align-items: center;
  color: var(--primary-color);

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid var(--primary-color);

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--primary-color);
      opacity: 0;
      transition: 0.3s;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.checked {
    font-weight: $font-bold;

    .checkbox {
      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
