<template>
  <div class="view">
    <div class="content">
      <h1 class="title-1">{{ $t('auth.hello') }}</h1>
      <p class="welcome-paragraph">{{ $t('auth.welcome') }}</p>
      <div class="form">
        <Input
          class="form-input"
          v-model="email"
          :placeholder="$t('auth.email')"
          :label="$t('auth.email')"
          type="email"
          ref="email"
        />
        <Input
          class="form-input"
          v-model="password"
          :placeholder="$t('auth.password')"
          :label="$t('auth.password')"
          :passwordRules="true"
          type="password"
          ref="password"
        />
        <router-link class="privacy-link" :to="{ name: 'legal' }">
          {{ $t('auth.legalAgreement') }}
        </router-link>
        <button @click="register" class="primary-button fullwidth">
          {{ $t('auth.signUp') }}
        </button>
      </div>
      <ProviderAuth />

      <p class="login">
        {{ $t('auth.haveAccount') }}
        <router-link :to="{ name: 'login' }" class="link-font">
          {{ $t('auth.iSignin') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { setKeyboard, killKeyboard } from '@/utils/native';

import Input from '@/components/inputs/Input.vue';
import ProviderAuth from '@/components/ProviderAuth.vue';

export default {
  name: 'Register',
  components: {
    Input,
    ProviderAuth,
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    };
  },
  async mounted() {
    await setKeyboard('.view .content');
  },
  methods: {
    async register(e) {
      e.preventDefault();
      const emailError = !this.email || this.$refs.email.check();
      const passwordError = !this.password || this.$refs.password.check();

      if (emailError || passwordError) {
        return;
      }

      try {
        await this.$store.dispatch('auth/registerPassword', {
          email: this.email,
          password: this.password,
        });
        this.$router.push({ name: 'projects' });
      } catch (e) {
        if (e.message.includes('email-already-in-use')) {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: this.$t('toasts.emailAlreadyUse'),
          });
          this.errorMessage = this.$t('toasts.emailAlreadyUse');
        } else if (e.message.includes('invalid-email')) {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: this.$t('toasts.invalidEmail'),
          });
          this.errorMessage = this.$t('toasts.invalidEmail');
        } else {
          this.$store.dispatch('toasts/setToast', {
            type: 'error',
            content: e.message,
          });
          this.errorMessage = e.message;
        }
      }
    },
  },
  unmounted() {
    killKeyboard();
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  align-items: center;
  flex-direction: column;

  .link-font {
    align-self: flex-end;
    margin: -12px 0 24px;
  }

  .form-input {
    width: 100%;
  }

  .primary-button {
    margin-top: 29px;
  }
}

.title-1 {
  text-align: center;
}

.welcome-paragraph {
  text-align: center;
  margin-bottom: 48px;
  height: 32px;
}

.privacy-link {
  font-size: 12px;
  line-height: 18px;
}

.login {
  text-align: center;

  .link-font {
    font-size: 16px;
    display: inline;
  }
}
</style>
