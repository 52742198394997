import { materials, fabricLengths } from '@/interfaces/enums';
import i18n from '@/locales/fr.json';

export default {
  fabrics: ({ fabrics }) => Object.values(fabrics),
  filteredData: ({ filters, fabrics }) => {
    const allFabrics = Object.values(fabrics);

    const filteredData = allFabrics
      .filter((fabric) => {
        const material = materials.find((material) => material.id === fabric.material)?.name;

        return (
          fabric.name.toUpperCase().includes(filters.search.toUpperCase()) ||
          fabric.brand.toUpperCase().includes(filters.search.toUpperCase()) ||
          i18n.materials[material]?.toUpperCase().includes(filters.search.toUpperCase())
        );
      })
      .filter(
        (fabric) =>
          !filters.colorFilters.length ||
          filters.colorFilters.find((color) => fabric.color.includes(color))
      )
      .filter(
        (fabric) => !filters.scoreFilters.length || filters.scoreFilters.includes(fabric.score)
      )
      .filter((fabric) => {
        const sizes = fabricLengths.filter((size) => filters.fabricLengthFilters.includes(size.id));

        return (
          !filters.fabricLengthFilters.length ||
          sizes.find((s) => !s.min && fabric.fabricLength <= s.max) ||
          sizes.find((s) => fabric.fabricLength >= s.min && fabric.fabricLength <= s.max) ||
          sizes.find((s) => fabric.fabricLength >= s.min && !s.max)
        );
      })
      .filter((fabric) => {
        const material = materials.find((material) => material.id === fabric.material).type;

        return (
          !filters.fabricTypeFilters.length ||
          material.find((m) => filters.fabricTypeFilters.includes(m))
        );
      })
      .filter((fabric) => !filters.wishlist || (filters.wishlist && fabric.wishlist));

    return filteredData;
  },
  filters: ({ filters }) => filters,
  defaultFilters: ({ defaultFilters }) => defaultFilters,
  endFetching: ({ endFetching }) => endFetching,
  resizingPicture: ({ resizingPicture }) => resizingPicture,
  resizingPictureError: ({ resizingPictureError }) => resizingPictureError,
};
