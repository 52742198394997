import { ref, uploadBytes, getDownloadURL, deleteObject, updateMetadata } from 'firebase/storage';
import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage, analytics } from '../../utils/firebaseConfig';
import { cloneDeep } from 'lodash';
import { logEvent } from 'firebase/analytics';

let resizingPictureAttempt = 0;

export default {
  async getResizePicture({ dispatch, state }, { name, projectId }) {
    const pictureRef = ref(storage, `knittingProjects/${projectId}/${name}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'knittingProjects', projectId), { pictureUrl: downloadURL });
      dispatch('getOneProject', { projectId });
      state.resizingPicture = false;

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizePicture', { name, projectId });
        }
      }, 1000);
    }
  },
  async storeProjectPicture({ dispatch, state }, { file, projectId }) {
    if (file) {
      const projectRef = ref(storage, `knittingProjects/${projectId}/${file.name}.jpeg`);
      await uploadBytes(projectRef, file);
      resizingPictureAttempt = 0;
      state.resizingPictureError = false;
      await dispatch('getResizePicture', { name: file.name, projectId });
    } else {
      await updateDoc(doc(db, 'knittingProjects', projectId), {
        pictureUrl: process.env.VUE_APP_DEFAULT_PROJECT_IMAGE,
      });
      await dispatch('getOneProject', { projectId });
    }
  },
  async getPicture({ dispatch }, { item, pictureName, url }) {
    const pictureRef = ref(storage, `knittingProjects/${item.id}/${pictureName}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      const downloadURL = await getDownloadURL(pictureRef);
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'knittingProjects', item.id), { ...item, pictureUrl: downloadURL });
      dispatch('getOneProject', { projectId: item.id });
      return downloadURL;
    } catch (e) {
      if (
        e.code === 'storage/object-not-found' &&
        url === process.env.VUE_APP_DEFAULT_OLD_PROJECT_IMAGE
      ) {
        updateDoc(doc(db, 'knittingProjects', item.id), {
          ...item,
          pictureUrl: process.env.VUE_APP_DEFAULT_PROJECT_IMAGE,
        });
        dispatch('getOneProject', { projectId: item.id });
      }
      return process.env.VUE_APP_DEFAULT_PROJECT_IMAGE;
    }
  },
  async deleteProjectPicture(_, { projectPictureUrl }) {
    if (!projectPictureUrl || projectPictureUrl === process.env.VUE_APP_DEFAULT_PROJECT_IMAGE)
      return;
    try {
      const projectRef = ref(storage, projectPictureUrl);
      await deleteObject(projectRef);
    } catch (e) {
      return '';
    }
  },
  async getNewProjectId() {
    const newDoc = await doc(collection(db, 'knittingProjects'));
    return newDoc.id;
  },
  async createProject({ rootState, dispatch }, { project, projectId }) {
    if (project.sewingSupply) {
      project.sewingSupply = [...project.sewingSupply].filter((value) => value.content);
    } else {
      project.sewingSupply = [];
    }

    await setDoc(doc(db, 'knittingProjects', projectId), {
      ...project,
      id: projectId,
      createdAt: new Date().getTime(),
      userId: rootState.auth.user.uid,
    })
      .then(() => {
        dispatch('getOneProject', { projectId });
        logEvent(analytics, 'knitting_project_create');
      })
      .catch((error) => {
        dispatch('deleteProjectPicture', { projectPictureUrl: project.pictureUrl });
        throw new Error(error);
      });
  },
  async getOneProject({ state }, { projectId }) {
    const snapshot = await getDoc(doc(db, 'knittingProjects', projectId));
    if (snapshot.exists()) {
      let existingProjectIndex = state.projects.findIndex((project) => project.id === projectId);
      if (existingProjectIndex >= 0) {
        state.projects[existingProjectIndex] = snapshot.data();
      } else {
        state.projects.unshift(snapshot.data());
      }
    }
  },
  async getPaginatedProjects({ state, rootState }, { pageNumber, pageSize = 8 }) {
    if (!state.endFetching) {
      let request = null;

      if (pageNumber === 0) {
        state.projects = [];
        request = query(
          collection(db, 'knittingProjects'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          limit(pageSize)
        );
      } else {
        const lastVisible = state.projects[state.projects.length - 1];
        request = query(
          collection(db, 'knittingProjects'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          startAfter(lastVisible.createdAt, lastVisible.id),
          limit(pageSize)
        );
      }
      const snapshot = await getDocs(request);
      snapshot.forEach((doc) => {
        state.projects.push(doc.data());
      });

      state.endFetching = snapshot.size < pageSize;
    }
  },
  editProject({ dispatch }, { project }) {
    if (project.sewingSupply) {
      project.sewingSupply = [...project.sewingSupply].filter((value) => value.content);
    } else {
      project.sewingSupply = [];
    }

    project.recommandedWools = project.recommandedWools || [];

    updateDoc(doc(db, 'knittingProjects', project.id), project);
    dispatch('getOneProject', { projectId: project.id });
  },
  async deleteProject({ dispatch, state }, { project }) {
    await deleteDoc(doc(db, 'knittingProjects', project.id))
      .then(() => {
        dispatch('deleteProjectPicture', { projectPictureUrl: project.pictureUrl });

        const existingProjectIndex = state.projects.findIndex((p) => project.id === p.id);
        state.projects.splice(existingProjectIndex, 1);

        logEvent(analytics, 'knitting_project_delete');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  scoreProject({ dispatch, getters }, { projectId, score }) {
    const currentProject = getters.projects.find((project) => project.id === projectId);

    updateDoc(doc(db, 'knittingProjects', projectId), { ...currentProject, score });
    dispatch('getOneProject', { projectId });
  },
  async setFilters({ state, rootState, dispatch }, filters) {
    state.filters = filters;

    let request = query(
      collection(db, 'knittingProjects'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const projectsSnap = await getCountFromServer(request);
    const projectsCount = projectsSnap.data().count;

    if (state.projects.length !== projectsCount) {
      dispatch('getAllProjects');
    }
  },
  async getAllProjects({ state, rootState }) {
    const lastVisible = state.projects[state.projects.length - 1];
    let request = null;

    if (lastVisible) {
      request = query(
        collection(db, 'knittingProjects'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc'),
        startAfter(lastVisible.createdAt, lastVisible.id)
      );
    } else {
      request = query(
        collection(db, 'knittingProjects'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);

    snapshot.forEach((doc) => {
      state.projects.push(doc.data());
    });
    state.endFetching = true;
  },
  async getRelatedProjects({ rootState }, { woolId, patternId }) {
    if (!woolId && !patternId) return [];

    let request = null;

    if (woolId) {
      request = query(
        collection(db, 'knittingProjects'),
        where('userId', '==', rootState.auth.user.uid),
        where('woolIds', 'array-contains', woolId),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    } else {
      request = query(
        collection(db, 'knittingProjects'),
        where('userId', '==', rootState.auth.user.uid),
        where('patternsId', 'array-contains', patternId),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  },
  async getProjectByCollection({ rootState }, { collectionId }) {
    const request = query(
      collection(db, 'knittingProjects'),
      where('userId', '==', rootState.auth.user.uid),
      where('collectionId', '==', collectionId),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  },
  async deleteAll({ rootState, dispatch }) {
    const request = query(
      collection(db, 'knittingProjects'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);
    for (let i = 0; i < snapshot.docs.length; i++) {
      await dispatch('deleteProject', { project: snapshot.docs[i].data() });
    }
  },
  reset({ state }) {
    state.projects = [];
    state.filters = cloneDeep(state.defaultFilters);
    state.endFetching = false;
  },
  markAsDone({ dispatch }, { project }) {
    updateDoc(doc(db, 'knittingProjects', project.id), project);
    dispatch('getOneProject', { projectId: project.id });
    logEvent(analytics, 'knitting_project_done');
  },
};
