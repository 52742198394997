<template>
  <div class="collections fade">
    <div class="collection" @click="creatingCollection = true" :class="{ disabled: offline }">
      <CollectionImage />
      <p>{{ $t('projects.collection') }}</p>
    </div>
    <router-link
      class="collection"
      v-for="collection in filteredCollection"
      :key="collection.id"
      :to="{ name: 'collection', params: { id: collection.id } }"
    >
      <CollectionImage :collection="collection" />
      <p>{{ collection.name }}</p>
    </router-link>
    <Teleport to="#app">
      <transition name="translate-modal">
        <CollectionModal v-if="creatingCollection" @close="creatingCollection = false" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import CollectionModal from '@/components/modals/CollectionModal.vue';
import CollectionImage from '@/components/CollectionImage.vue';

import { mapGetters } from 'vuex';
export default {
  name: 'CollectionsList',
  components: {
    CollectionModal,
    CollectionImage,
  },
  data() {
    return {
      creatingCollection: false,
    };
  },
  computed: {
    ...mapGetters({
      collections: 'projects/collections',
      sewistaMode: 'auth/sewistaMode',
      projectsFilters: 'projects/filters',
      knittingProjectsfilters: 'knittingProjects/filters',
      offline: 'auth/offline',
    }),
    currentFilters() {
      return this.sewistaMode ? this.projectsFilters : this.knittingProjectsfilters;
    },
    filteredCollection() {
      return this.currentFilters.collections.length
        ? this.collections.filter((collection) =>
            this.currentFilters.collections.includes(collection.id)
          )
        : this.collections;
    },
  },
};
</script>

<style scoped lang="scss">
.collections {
  @include hiddeScrollbar;

  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  overflow: auto;
  margin: 0 calc(var(--view-padding) * -1);
  padding-left: var(--view-padding);

  &::after {
    content: '';
    position: relative;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
  }

  .collection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 72px;
    flex-shrink: 0;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    p {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .collections {
    gap: 24px;
    .collection {
      width: 84px;
    }
  }
}
</style>
