<template>
  <div class="form">
    <Input
      v-model="project.name"
      :placeholder="$t('projects.projectName')"
      :label="$t('projects.projectName')"
      :required="true"
      ref="name"
    />
    <ButtonSelect
      v-model="project.status"
      :options="statusOptions"
      :label="$t('projects.status')"
      :required="true"
      ref="status"
      v-if="!editionMode || (editionMode && project.status !== 3)"
    />
    <PictureInput
      v-model="projectFile"
      name="visual"
      :url="project.pictureUrl"
      :label="$t('projects.inspirations')"
      ref="picture"
    />
    <MultipleSelectInput
      :label="$t('projects.woolType')"
      :options="wools"
      :placeholder="$t('projects.choseWool')"
      v-model="woolsId"
    />
    <Select
      :label="$t('projects.sewingPattern')"
      :options="patterns"
      v-model="patternId"
      :placeholder="$t('projects.chosePattern')"
      optionName="name"
    />
    <ListInput
      v-model="recommandedWools"
      :metric="woolMetric"
      :checkbox="true"
      :disabled="true"
      v-if="patternId"
    />
    <Input
      v-model="project.size"
      :placeholder="$t('projects.knittingPatternSize')"
      :label="$t('projects.knittingPatternSize')"
    />
    <Radios
      v-model="project.clothType"
      :options="clothTypes"
      :label="$t('projects.patternType')"
      :required="true"
      ref="clothType"
    />
    <Input
      v-model="project.recommandedNeedles"
      :label="$t('projects.recommandedNeedles')"
      ref="recommandedNeedles"
    />
    <CheckListInput v-model="project.sewingSupply" :label="$t('projects.sewingSupply')" />
    <Select
      :label="$t('projects.addToCollection')"
      :options="collections"
      v-model="project.collectionId"
      :placeholder="$t('projects.choseCollection')"
      :collectionInput="true"
      optionName="name"
    />
    <Textarea
      v-model="project.comment"
      :label="$t('projects.comment')"
      :placeholder="$t('projects.comment')"
    />
    <p class="mandatory-fields">{{ $t('mandatoryFields') }}</p>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import Radios from '@/components/inputs/Radios.vue';
import PictureInput from '@/components/inputs/PictureInput.vue';
import Select from '@/components/inputs/Select.vue';
import CheckListInput from '@/components/inputs/CheckListInput.vue';
import ButtonSelect from '@/components/inputs/ButtonSelect.vue';
import MultipleSelectInput from '@/components/inputs/MultipleSelectInput.vue';
import ListInput from '@/components/inputs/ListInput.vue';

import { clothTypes, projectTypes, projectStatus, woolMetrics } from '@/interfaces/enums.json';
import { mapGetters } from 'vuex';

export default {
  name: 'KnittingProjectForm',
  components: {
    Input,
    Radios,
    Textarea,
    PictureInput,
    Select,
    CheckListInput,
    ButtonSelect,
    MultipleSelectInput,
    ListInput,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['formValidity', 'update:modelValue'],
  data() {
    return {
      projectFile: null,
      recommandedWools: [],
      clothTypes,
      projectTypes,
      projectStatus,
    };
  },
  computed: {
    ...mapGetters({
      patterns: 'knittingPatterns/patterns',
      wools: 'wools/wools',
      collections: 'projects/collections',
    }),
    isFormValid() {
      return !!(this.project && this.project.name && this.project.clothType && this.project.status);
    },
    project: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
    patternId: {
      get() {
        return this.project.patternsId ? this.project.patternsId[0] : '';
      },
      set(newValue) {
        this.project.patternsId = [newValue];
        if (newValue) {
          const pattern = this.patterns.find((pattern) => pattern.id === newValue);
          this.project.clothType = pattern.clothTypes[0];
          if (pattern && pattern.recommandedWools) {
            this.recommandedWools = pattern.recommandedWools
              .filter((wool) => wool.name)
              .map((wool) => ({ ...wool, checked: false }));
          }

          this.project.size = pattern.recommandedSize;
          this.project.woolMetric = pattern.woolMetric;

          if (pattern.sewingSupply) {
            this.project.sewingSupply = [
              ...pattern.sewingSupply.filter((supply) => supply.content),
              { content: '', checked: false },
            ];
          }
          this.project.recommandedNeedles = pattern.recommandedNeedles;
        }
        return newValue;
      },
    },
    woolsId: {
      get() {
        return this.project.woolIds;
      },
      set(newValue) {
        this.project.woolIds = newValue;
        if (newValue.length) {
          const fabrics = this.wools.filter((wool) => newValue.includes(wool.id));
          this.project.materials = fabrics.map((wool) => wool.material);
        }
        return newValue;
      },
    },
    woolMetric() {
      return this.$t(
        woolMetrics.find((metric) => metric.id === this.project.woolMetric).translation
      );
    },
    editionMode() {
      return this.$route.name === 'knittingProjectEdition';
    },
    statusOptions() {
      return this.editionMode ? this.projectStatus.slice(0, 2) : this.projectStatus;
    },
  },
  watch: {
    isFormValid(value) {
      this.$emit('formValidity', value);
    },
  },
  async created() {
    await this.$store.dispatch('knittingPatterns/getAllPatterns');
    await this.$store.dispatch('wools/getAllWools');

    if (this.project?.recommandedWools?.length) {
      const pattern = this.patterns.find((pattern) => pattern.id === this.patternId);
      this.recommandedWools = pattern?.recommandedWools
        .filter((wool) => wool.name)
        .map((wool) => ({
          ...wool,
          checked: !!this.project.recommandedWools.find((wo) => wool.name === wo.name),
        }));
    }
  },
  mounted() {
    this.$emit('formValidity', this.isFormValid);
  },
  methods: {
    checkForm() {
      const requiredFields = ['name', 'clothType', 'status'];

      let error = false;
      requiredFields.forEach((field) => {
        error = this.$refs[field]?.check() || error;
      });

      return error;
    },
  },
};
</script>

<style scoped lang="scss"></style>
