<template>
  <div class="buttons-container" :class="{ column: buttonStyle }">
    <div class="picture-button">
      <div v-if="pictureUrl && buttonStyle" class="image-container">
        <img :src="pictureUrl" alt="image" />
      </div>
      <button :class="{ 'primary-button': buttonStyle, 'link-font': !buttonStyle }">
        <input
          type="file"
          @input="takePicture"
          name="project-picture"
          accept="capture=camera,image/*"
        />
        <Cross v-if="isAndroid" />
        <Photo v-else />
        {{ $t('addPicture') }}
      </button>
    </div>
    <div class="picture-button android" v-if="isAndroid">
      <div v-if="pictureAndroidUrl && buttonStyle" class="image-container">
        <img :src="pictureAndroidUrl" alt="image" />
      </div>
      <button :class="{ 'primary-button': buttonStyle, 'link-font': !buttonStyle }">
        <input
          type="file"
          @input="takePicture"
          name="project-picture-android"
          accept="image/*"
          capture
        />
        <Photo />{{ $t('takePicture') }}
      </button>
    </div>
  </div>
</template>

<script>
import { getPlatform } from '@/utils/native';
import Photo from '@/assets/svg/photo.svg';
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'FileButton',
  components: {
    Photo,
    Cross,
  },
  props: {
    buttonStyle: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'visual',
    },
  },
  emits: ['setPicture', 'setLoader'],
  data() {
    return {
      pictureUrl: null,
      pictureFile: null,
      pictureAndroidUrl: null,
    };
  },
  computed: {
    isAndroid() {
      return getPlatform() === 'android';
    },
  },
  methods: {
    takePicture(e) {
      this.$emit('setLoader');
      const input = e.target;
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (input.name.includes('android')) {
          this.pictureAndroidUrl = e.target.result;
          this.pictureUrl = null;
        } else {
          this.pictureAndroidUrl = null;
          this.pictureUrl = e.target.result;
        }
        this.$emit('setPicture', {
          pictureFile: this.pictureFile,
          pictureUrl: this.pictureUrl,
          pictureAndroidUrl: this.pictureAndroidUrl,
        });
      };
      reader.readAsDataURL(file);
      this.pictureFile = new File([file], this.name, {
        type: file.type,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.buttons-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &.column {
    flex-direction: column;

    .picture-button {
      gap: 16px;
      width: 100%;

      &.android {
        margin-top: -16px;
      }
    }
  }
}

.picture-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .primary-button {
    position: relative;
    flex-grow: 1;
  }

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    appearance: none;
    opacity: 0;
  }

  .link-font {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    text-align: right;
    color: $pink-color;

    svg {
      display: none;
    }
  }

  .image-container {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 16px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
