<template>
  <div class="input-container" :class="{ error: errorMessage, required: required }">
    <label class="label">{{ label }}</label>
    <div class="options">
      <div
        class="option"
        v-for="(option, index) in options"
        :key="index"
        :class="{ selected: isSelected(option) }"
        @click="selectOption(option)"
      >
        <div
          class="color"
          v-if="colorPicker"
          :style="{ background: option.color }"
          :class="{
            multicoloured: option.name === 'Multicoloured',
            white: option.name === 'White',
          }"
        ></div>
        {{ optionValue(option) }}
        <Star v-if="scoring" class="star" />
      </div>
    </div>
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import Star from '@/assets/svg/etoile.svg';
export default {
  name: 'ButtonSelect',
  components: {
    Star,
  },
  props: {
    modelValue: {
      type: [Number, String, Array],
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    colorPicker: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    scoring: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    selectOption(option) {
      const value = option.id || option.name || option;
      if (this.multiple) {
        let values = [...this.modelValue];
        if (values.find((v) => v === value)) {
          values = values.filter((v) => v !== value);
        } else {
          values.push(value);
        }
        this.$emit('update:modelValue', values);

        if (values.length) {
          this.errorMessage = '';
        }
      } else {
        this.$emit('update:modelValue', value === this.modelValue ? 0 : value);
        if (value !== this.modelValue) {
          this.errorMessage = '';
        }
      }
    },
    isSelected(option) {
      const value = option.id || option.name || option;

      if (this.multiple) {
        return this.modelValue.find((v) => v === value);
      } else {
        return value === this.modelValue;
      }
    },
    check() {
      if (this.multiple && this.required && !this.modelValue.length) {
        this.errorMessage = this.$t('mandatoryField');
      } else if (!this.multiple && this.required && !this.modelValue) {
        this.errorMessage = this.$t('mandatoryField');
      }

      return !!this.errorMessage;
    },
    optionValue(option) {
      if (option.translation) {
        return this.$t(option.translation);
      } else if (option.name) {
        return option.name;
      } else {
        return option;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;

  &.error {
    .option {
      border-color: $red-color;
      color: $red-color;
    }
  }
}

.options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.option {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  transition: 0.3s;

  &.selected {
    background: var(--primary-color);
    color: $white-color;
  }

  .color {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;

    &.multicoloured {
      background: linear-gradient(
        180deg,
        #56cc6c 4.69%,
        #83cef8 19.27%,
        #991afc 33.85%,
        #ff72f9 50%,
        #ff3528 62.5%,
        #ff7121 81.77%,
        #ffc328 93.75%
      ) !important;
    }

    &.white {
      border: 1px solid $lightgrey-color;

      svg {
        color: $lightgrey-color;
      }
    }
  }

  .star {
    width: 12px;
    margin-left: 4px;
    color: $yellow-color;
  }
}
</style>
