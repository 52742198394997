<template>
  <button
    class="primary-button fullwidth"
    :class="{ loading: loading, disabled: disabled, green: greenStyle }"
    @click="$emit('action')"
  >
    {{ text }}<Loader class="loader" />
  </button>
</template>

<script>
import Loader from '@/assets/svg/loader.svg';

export default {
  name: 'LoaderButton',
  components: {
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    greenStyle: {
      type: Boolean,
    },
  },
  emits: ['action'],
};
</script>

<style scoped lang="scss">
.primary-button {
  &.green {
    background-color: $green-color;

    &.loading {
      color: $green-color;

      &::after {
        background: $darkgreen-color;
      }
    }

    &.disabled {
      background: $lightgrey-color;
    }
  }
}
</style>
