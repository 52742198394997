<template>
  <div
    class="input-container"
    :class="{ checked: modelValue }"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <label>{{ label }}</label>
    <div class="checkbox"></div>
  </div>
</template>

<script>
export default {
  name: 'SingleCheckbox',
  props: {
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 28px;

  label {
    font-size: 14px;
    line-height: 18px;
  }

  &.checked {
    font-weight: $font-bold;

    .checkbox {
      &::after {
        opacity: 1;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid var(--primary-color);
  flex-shrink: 0;

  &::after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 1px;
    background: var(--primary-color);
    opacity: 0;
    transition: 0.3s;
  }
}
</style>
