<template>
  <div class="step">
    <div class="illustration">
      <img :src="Illustration" alt="projects" />
    </div>
    <h1 class="title-1">{{ $t('onboarding.step3Title') }}</h1>
    <p>
      Associez un textile à un patron, faites votre liste de mercerie et démarrez le projet de vos
      rêves !
    </p>
  </div>
</template>

<script>
import Illustration from '@/assets/illustration/projet.jpg';

export default {
  name: 'Step3',
  data() {
    return {
      Illustration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
