import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import '@/utils/firebaseConfig';
import router from './router';
import store from './store';
import i18n from '@/i18n';
import clickoutside from '@/utils/clickoutside';
import AuthGuard from '@/utils/authGuard';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Flip from 'gsap/Flip';

const app = createApp(App);
app.use(store);
app.use(i18n);
app.use(router);
app.use(AuthGuard, { router, store });

gsap.registerPlugin(ScrollToPlugin, Flip);

app.directive('clickout', clickoutside);

app.mount('#app');
