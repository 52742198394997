<template>
  <div class="provider-auth" :class="{ hidden: !isNative() }">
    <p class="separator">{{ $t('auth.loginWith') }}</p>
    <div class="buttons-container">
      <button class="secondary-button" @click="loginGoogle">
        <GoogleLogo />
      </button>
      <button class="secondary-button" v-if="isIOS" @click="loginApple">
        <AppleLogo />
      </button>
    </div>
  </div>
</template>

<script>
import { getPlatform, isNative } from '@/utils/native';
import GoogleLogo from '@/assets/svg/logo-google.svg';
import AppleLogo from '@/assets/svg/logo-apple.svg';

export default {
  name: 'ProviderAuth',
  components: {
    GoogleLogo,
    AppleLogo,
  },
  data() {
    return {
      isNative,
    };
  },
  computed: {
    isIOS() {
      return getPlatform() === 'ios';
    },
  },
  methods: {
    async loginGoogle() {
      try {
        await this.$store.dispatch('auth/loginGoogle');
        this.$router.push({ name: 'projects' });
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    async loginApple() {
      try {
        await this.$store.dispatch('auth/loginApple');
        this.$router.push({ name: 'projects' });
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.provider-auth {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  &.hidden {
    visibility: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: 1px;
    background: $lightgrey-color;
    z-index: -1;
  }

  .separator {
    text-transform: lowercase;
    font-weight: $font-bold;
    text-align: center;
    background: $white-color;
    padding: 0 16px;
    margin-bottom: 32px;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    .secondary-button {
      width: 80px;
    }

    svg {
      margin: 0;
    }
  }
}
</style>
