import { ref, uploadBytes, getDownloadURL, deleteObject, updateMetadata } from 'firebase/storage';
import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage, analytics } from '../../utils/firebaseConfig';
import { cloneDeep } from 'lodash';
import { logEvent } from 'firebase/analytics';

let resizingPictureAttempt = 0;

export default {
  async getResizePicture({ dispatch, state }, { name, patternId }) {
    const patternRef = ref(storage, `knittingPatterns/${patternId}/${name}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      state.resizingPicture = true;
      const downloadURL = await getDownloadURL(patternRef);
      updateMetadata(patternRef, newMetadata);
      if (name === 'sizeGuide') {
        updateDoc(doc(db, 'knittingPatterns', patternId), { sizesGuidePictureUrl: downloadURL });
      } else {
        updateDoc(doc(db, 'knittingPatterns', patternId), { pictureUrl: downloadURL });
      }
      dispatch('getOnePattern', { patternId });
      state.resizingPicture = false;

      return downloadURL;
    } catch (e) {
      setTimeout(async () => {
        if (resizingPictureAttempt > 10) {
          state.resizingPicture = false;
          state.resizingPictureError = true;
          throw new Error('too many attempt');
        } else {
          resizingPictureAttempt += 1;
          await dispatch('getResizePicture', { name, patternId });
        }
      }, 1000);
    }
  },
  async storePatternPicture({ dispatch, state }, { file, patternId }) {
    const patternRef = ref(storage, `knittingPatterns/${patternId}/${file.name}.jpeg`);
    await uploadBytes(patternRef, file);
    resizingPictureAttempt = 0;
    state.resizingPictureError = false;
    await dispatch('getResizePicture', { name: file.name, patternId });
  },
  async getPicture({ dispatch }, { item, pictureName }) {
    const pictureRef = ref(storage, `knittingPatterns/${item.id}/${pictureName}_680x680.jpeg`);
    const newMetadata = {
      cacheControl: 'public,max-age=604800',
      contentType: 'image/jpeg',
    };
    try {
      const downloadURL = await getDownloadURL(pictureRef);
      const updatedPattern = { ...item };
      if (pictureName === 'sizeGuide') {
        updatedPattern.sizesGuidePictureUrl = downloadURL;
      } else {
        updatedPattern.pictureUrl = downloadURL;
      }
      updateMetadata(pictureRef, newMetadata);
      updateDoc(doc(db, 'knittingPatterns', item.id), updatedPattern);
      dispatch('getOnePattern', { patternId: item.id });
      return downloadURL;
    } catch (e) {
      return process.env.VUE_APP_DEFAULT_PROJECT_IMAGE;
    }
  },
  async deletePatternPicture(_, { patternPictureUrl }) {
    if (!patternPictureUrl) return;
    try {
      const patternRef = ref(storage, patternPictureUrl);
      await deleteObject(patternRef);
    } catch (e) {
      return '';
    }
  },
  async getNewPatternId() {
    const newDoc = await doc(collection(db, 'patterns'));
    return newDoc.id;
  },
  async createPattern({ rootState, dispatch }, { pattern, patternId }) {
    if (pattern.sewingSupply) {
      pattern.sewingSupply = [...pattern.sewingSupply].filter((value) => value.content);
    } else {
      pattern.sewingSupply = [];
    }
    if (pattern.recommandedWools) {
      pattern.recommandedWools = [...pattern.recommandedWools].filter(
        (value) => value.name && value.length
      );
    } else {
      pattern.recommandedWools = [];
    }

    await setDoc(doc(db, 'knittingPatterns', patternId), {
      ...pattern,
      id: patternId,
      createdAt: new Date().getTime(),
      userId: rootState.auth.user.uid,
    })
      .then(() => {
        dispatch('getOnePattern', { patternId });
        logEvent(analytics, 'knitting_pattern_create');
      })
      .catch((error) => {
        dispatch('deletePatternPicture', { patternPictureUrl: pattern.pictureUrl });
        dispatch('deletePatternPicture', {
          patternPictureUrl: pattern.sizesGuidePictureUrl,
        });
        throw new Error(error);
      });
  },
  async deletePattern({ dispatch, state }, { pattern }) {
    await deleteDoc(doc(db, 'knittingPatterns', pattern.id))
      .then(() => {
        dispatch('deletePatternPicture', { patternPictureUrl: pattern.pictureUrl });
        dispatch('deletePatternPicture', { patternPictureUrl: pattern.sizesGuidePictureUrl });

        const existingPatternsIndex = state.patterns.findIndex((p) => pattern.id === p.id);
        state.patterns.splice(existingPatternsIndex, 1);

        logEvent(analytics, 'knitting_pattern_delete');
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getOnePattern({ state }, { patternId }) {
    const snapshot = await getDoc(doc(db, 'knittingPatterns', patternId));

    if (snapshot.exists()) {
      let existingPatternIndex = state.patterns.findIndex((pattern) => pattern.id === patternId);

      if (existingPatternIndex >= 0) {
        state.patterns[existingPatternIndex] = snapshot.data();
      } else {
        state.patterns.unshift(snapshot.data());
      }
    }
  },
  async getPaginatedPatterns({ state, rootState }, { pageNumber, pageSize = 8 }) {
    if (!state.endFetching) {
      let request = null;

      if (pageNumber === 0) {
        state.patterns = [];
        request = query(
          collection(db, 'knittingPatterns'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          limit(pageSize)
        );
      } else {
        const lastVisible = state.patterns[state.patterns.length - 1];

        request = query(
          collection(db, 'knittingPatterns'),
          where('userId', '==', rootState.auth.user.uid),
          orderBy('createdAt', 'desc'),
          orderBy('id', 'desc'),
          startAfter(lastVisible.createdAt, lastVisible.id),
          limit(pageSize)
        );
      }
      const snapshot = await getDocs(request);
      snapshot.forEach((doc) => {
        state.patterns.push(doc.data());
      });

      state.endFetching = snapshot.size < pageSize;
    }
  },
  editPattern({ dispatch }, { pattern }) {
    if (pattern.sewingSupply) {
      pattern.sewingSupply = [...pattern.sewingSupply].filter((value) => value.content);
    } else {
      pattern.sewingSupply = [];
    }
    if (pattern.recommandedWools) {
      pattern.recommandedWools = [...pattern.recommandedWools].filter(
        (value) => value.name && value.length
      );
    } else {
      pattern.recommandedWools = [];
    }
    updateDoc(doc(db, 'knittingPatterns', pattern.id), pattern);
    dispatch('getOnePattern', { patternId: pattern.id });
  },
  async scorePattern({ dispatch, getters }, { patternId, score }) {
    const currentPattern = getters.patterns.find((pattern) => pattern.id === patternId);

    updateDoc(doc(db, 'knittingPatterns', patternId), { ...currentPattern, score });
    dispatch('getOnePattern', { patternId });
  },
  async setFilters({ state, rootState, dispatch }, filters) {
    state.filters = filters;

    let request = query(
      collection(db, 'knittingPatterns'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const patternSnap = await getCountFromServer(request);
    const patternCount = patternSnap.data().count;

    if (state.patterns.length !== patternCount) {
      dispatch('getAllPatterns');
    }
  },
  async getAllPatterns({ state, rootState }) {
    const lastVisible = state.patterns[state.patterns.length - 1];
    let request = null;

    if (lastVisible) {
      request = query(
        collection(db, 'knittingPatterns'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc'),
        startAfter(lastVisible.createdAt, lastVisible.id)
      );
    } else {
      request = query(
        collection(db, 'knittingPatterns'),
        where('userId', '==', rootState.auth.user.uid),
        orderBy('createdAt', 'desc'),
        orderBy('id', 'desc')
      );
    }
    const snapshot = await getDocs(request);

    snapshot.forEach((doc) => {
      state.patterns.push(doc.data());
    });
    state.endFetching = true;
  },
  async getPatternsByIds({ rootState }, { patternsId }) {
    const request = query(
      collection(db, 'knittingPatterns'),
      where('userId', '==', rootState.auth.user.uid),
      where('id', 'in', patternsId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(request);

    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });

    return results;
  },
  async deleteAll({ rootState, dispatch }) {
    const request = query(
      collection(db, 'knittingPatterns'),
      where('userId', '==', rootState.auth.user.uid),
      orderBy('createdAt', 'desc'),
      orderBy('id', 'desc')
    );

    const snapshot = await getDocs(request);
    for (let i = 0; i < snapshot.docs.length; i++) {
      await dispatch('deletePattern', { pattern: snapshot.docs[i].data() });
    }
  },
  reset({ state }) {
    state.patterns = [];
    state.filters = cloneDeep(state.defaultFilters);
    state.endFetching = false;
  },
};
