<template>
  <div class="view onboarding">
    <div class="content">
      <div class="steps">
        <div class="progress-step" v-for="(step, index) in 5" :key="step">
          <div
            class="progress"
            :class="{
              animating: index === currentStep,
              fill: index < currentStep,
            }"
          ></div>
        </div>
      </div>
      <div class="scroller" ref="scroller">
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />
      </div>
      <button class="primary-button" v-if="currentStep >= 4" @click.stop="skipOnboarding">
        {{ $t('onboarding.start') }}
      </button>
      <button @click.stop="skipOnboarding" class="skip-button" v-else>
        {{ $t('onboarding.skipIntroduction') }}
      </button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { setStorage } from '@/utils/native';

import Step1 from '@/components/onboarding/Step1.vue';
import Step2 from '@/components/onboarding/Step2.vue';
import Step3 from '@/components/onboarding/Step3.vue';
import Step4 from '@/components/onboarding/Step4.vue';
import Step5 from '@/components/onboarding/Step5.vue';

export default {
  name: 'Onboarding',
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  data() {
    return {
      stepDuration: 6000,
      currentStep: 0,
      timer: 0,
      interval: null,
    };
  },
  mounted() {
    this.setTimer();
    document.documentElement.addEventListener('click', this.scrollTo);
  },
  watch: {
    timer(timer) {
      if (timer % (this.stepDuration / 1000) === 0) {
        this.currentStep += 1;
        if (this.currentStep === 5) {
          clearInterval(this.interval);
        } else {
          gsap.to(this.$refs.scroller, {
            scrollTo: {
              x: document.querySelectorAll('.step')[this.currentStep],
            },
            duration: 0.628,
            onComplete: () => {
              this.setTimer();
            },
          });
        }
      }
    },
  },
  methods: {
    setTimer() {
      if (this.interval) clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.timer += 1;
      }, 1000);
    },
    scrollTo(e) {
      clearInterval(this.interval);
      const screenWidth = window.innerWidth;
      const clickPositionX = e.clientX;

      if (clickPositionX > screenWidth / 2 && this.currentStep < 4) {
        this.currentStep += 1;
      } else if (clickPositionX < screenWidth / 2 && this.currentStep > 0) {
        this.currentStep -= 1;
      }
      if (this.currentStep !== 5) {
        gsap.to(this.$refs.scroller, {
          scrollTo: {
            x: document.querySelectorAll('.step')[this.currentStep],
          },
          duration: 0.628,
          onComplete: () => {
            this.setTimer();
          },
        });
      }
    },
    async skipOnboarding() {
      await setStorage('onboardingDone', JSON.stringify(true));
      clearInterval(this.interval);
      this.$router.push({ name: 'register' });
    },
  },
  unmounted() {
    clearInterval(this.interval);
    document.documentElement.removeEventListener('click', this.scrollTo);
  },
};
</script>

<style scoped lang="scss">
.onboarding {
  background: #1d2b2a;
  color: $white-color;
  padding-top: 0;
  height: 100vh;
  overflow: hidden;
}

.steps {
  position: fixed;
  top: 64px;
  left: var(--view-padding);
  right: var(--view-padding);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;

  .progress-step {
    position: relative;
    height: 3px;
    width: calc((100% - 8px * 4) / 5);
    background: mix($darkgreen-color, $white-color, 20%);
    border-radius: 3px;
    overflow: hidden;

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0%;
      background: $darkgreen-color;

      &.fill {
        width: 100%;
      }

      &.animating {
        animation: progress 6000ms linear forwards;
      }
    }
  }
}

.scroller {
  @include hiddeScrollbar;
  display: flex;
  margin: 0 calc(#{var(--view-padding)} * -1);
  overflow: hidden;

  .step {
    padding: 0 var(--view-padding);
    flex-shrink: 0;
  }
}

.skip-button {
  position: fixed;
  right: var(--view-padding);
  bottom: 52px;
  color: $lightpink-color;
  font-weight: $font-bold;
  text-align: right;
  background: none;
}

.primary-button {
  position: fixed;
  bottom: 52px;
  left: var(--view-padding);
  right: var(--view-padding);
}

@media (min-width: $layout-breakpoint-medium) {
  .step {
    .illustration {
      height: 65vh;
    }
  }
}
</style>
