import { createRouter, createWebHistory } from 'vue-router';

import Landing from '@/views/Landing.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register';
import Onboarding from '@/views/Onboarding';

import Projects from '@/views/Projects';
const Project = () => import('@/views/sewing/Project');
const ProjectEdition = () => import('@/views/sewing/ProjectEdition');
import Collection from '@/views/Collection';

const KnittingProject = () => import('@/views/knitting/KnittingProject');
const KnittingProjectEdition = () => import('@/views/knitting/KnittingProjectEdition');

import Fabrics from '@/views/Fabrics';
const Fabric = () => import('@/views/sewing/Fabric');
const FabricEdition = () => import('@/views/sewing/FabricEdition');

const Wool = () => import('@/views/knitting/Wool');
const WoolEdition = () => import('@/views/knitting/WoolEdition');

import Patterns from '@/views/Patterns';
const Pattern = () => import('@/views/sewing/Pattern');
const PatternEdition = () => import('@/views/sewing/PatternEdition');

const KnittingPattern = () => import('@/views/knitting/KnittingPattern');
const KnittingPatternEdition = () => import('@/views/knitting/KnittingPatternEdition');

import Account from '@/views/Account';
const AccountInformations = () => import('@/views/AccountInformations');
const Measurement = () => import('@/views/Measurement');
const Legal = () => import('@/views/Legal');

import DesignSystem from '@/views/DesignSystem';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing,
    meta: {
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    meta: {
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      middleware: ['auth'],
      transitionType: 'grid',
      navbar: true,
      statusbar: true,
      sewistaSwitch: true,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/projects/:id',
    name: 'project',
    component: Project,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      hasBrand: true,
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/knitting-projects/:id',
    name: 'knittingProject',
    component: KnittingProject,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      hasBrand: true,
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/projects/:id/edition',
    name: 'projectEdition',
    component: ProjectEdition,
    meta: {
      middleware: ['auth'],
      transitionType: 'edition',
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/knitting-projects/:id/edition',
    name: 'knittingProjectEdition',
    component: KnittingProjectEdition,
    meta: {
      middleware: ['auth'],
      transitionType: 'edition',
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/collections/:id',
    name: 'collection',
    component: Collection,
    meta: {
      middleware: ['auth'],
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      transitionType: 'edition',
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/patterns',
    name: 'patterns',
    component: Patterns,
    meta: {
      middleware: ['auth'],
      transitionType: 'grid',
      navbar: true,
      statusbar: true,
      sewistaSwitch: true,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/patterns/:id/edition',
    name: 'patternEdition',
    component: PatternEdition,
    meta: {
      middleware: ['auth'],
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      transitionType: 'edition',
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/knitting-patterns/:id/edition',
    name: 'knittingPatternEdition',
    component: KnittingPatternEdition,
    meta: {
      middleware: ['auth'],
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      transitionType: 'edition',
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/patterns/:id',
    name: 'pattern',
    component: Pattern,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/knitting-patterns/:id',
    name: 'knittingPattern',
    component: KnittingPattern,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/fabrics',
    name: 'fabrics',
    component: Fabrics,
    meta: {
      middleware: ['auth'],
      navbar: true,
      statusbar: true,
      sewistaSwitch: true,
      transitionType: 'grid',
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/fabrics/:id',
    name: 'fabric',
    component: Fabric,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/wools/:id',
    name: 'wool',
    component: Wool,
    meta: {
      middleware: ['auth'],
      transitionType: 'detail',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/fabrics/:id/edition',
    name: 'fabricEdition',
    component: FabricEdition,
    meta: {
      middleware: ['auth'],
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      transitionType: 'edition',
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/wools/:id/edition',
    name: 'woolEdition',
    component: WoolEdition,
    meta: {
      middleware: ['auth'],
      navbar: false,
      statusbar: true,
      sewistaSwitch: false,
      transitionType: 'edition',
      routeOrder: 2,
      webenable: false,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      middleware: ['auth'],
      navbar: true,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: false,
    },
  },
  {
    path: '/account/measurements',
    name: 'measurements',
    component: Measurement,
    meta: {
      middleware: ['auth'],
      transitionType: 'edition',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/account/my-informations',
    name: 'accountInformations',
    component: AccountInformations,
    meta: {
      middleware: ['auth'],
      transitionType: 'edition',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: false,
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    meta: {
      middleware: [],
      transitionType: 'edition',
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 1,
      webenable: true,
    },
  },
  {
    path: '/design-system',
    name: 'designSystem',
    component: DesignSystem,
    meta: {
      middleware: [],
      navbar: false,
      statusbar: false,
      sewistaSwitch: false,
      routeOrder: 0,
      webenable: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (
      to.meta.transitionType !== 'grid' &&
      from.meta.transitionType !== 'grid' &&
      to.meta.transitionType !== 'edition'
    ) {
      return { top: 0 };
    }
  },
});

export default router;
