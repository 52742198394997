<template>
  <div class="step">
    <div class="illustration">
      <img :src="Illustration" alt="knitting mode" />
    </div>
    <h1 class="title-1">{{ $t('onboarding.step5Title') }}</h1>
    <p>
      Passez du mode tricot au mode couture en un clic. <br />PS : Vous pouvez gérer vos préférences
      dans votre profil.
    </p>
  </div>
</template>

<script>
import Illustration from '@/assets/illustration/switch.jpg';

export default {
  name: 'Step5',
  data() {
    return {
      Illustration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
