export default {
  beforeMount: (el, binding) => {
    el.clickoutsideEvent = (event) => {
      if (el !== event.target && !el.contains(event.target)) {
        binding.value();
      }
    };
    document.addEventListener('click', el.clickoutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickoutsideEvent);
  },
};
