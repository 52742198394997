<template>
  <div class="content">
    <h1 class="title-1">{{ $t('patterns.newPattern') }}</h1>
    <PatternForm v-model="pattern" ref="form" @formValidity="checkFormValidity" />
    <LoaderButton
      :disabled="!isFormValid"
      :text="$t('patterns.create')"
      :loading="submited"
      @action="savePattern"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import PatternInterface from '@/interfaces/pattern';
import gsap from 'gsap';

import PatternForm from '@/components/forms/PatternForm.vue';

import LoaderButton from '@/components/LoaderButton.vue';

export default {
  name: 'PatternCreation',
  components: {
    PatternForm,
    LoaderButton,
  },
  data() {
    return {
      pattern: cloneDeep({ ...PatternInterface }),
      submited: false,
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters({
      modalResolve: 'modals/modalResolve',
      resizingPicture: 'patterns/resizingPicture',
      resizingPictureError: 'patterns/resizingPictureError',
    }),
  },
  methods: {
    checkFormValidity(value) {
      this.isFormValid = value;
    },
    async savePattern() {
      if (this.submited) return;
      this.submited = true;

      const error = this.$refs.form.checkForm();
      if (error) {
        this.submited = false;
        gsap.to('.modal .content', {
          scrollTo: { y: '.error', offsetY: 42 },
          duration: 0.3,
          ease: 'power1.inOut',
        });
        return;
      }

      const patternFile = this.$refs.form.patternFile;
      const sizeGuideFile = this.$refs.form.sizeGuideFile;
      const patternId = await this.$store.dispatch('patterns/getNewPatternId');

      try {
        await this.$store.dispatch('patterns/createPattern', {
          patternId,
          pattern: this.pattern,
        });

        if (patternFile) {
          await this.$store.dispatch('patterns/storePatternPicture', {
            file: patternFile,
            patternId: patternId,
          });
        }

        if (sizeGuideFile) {
          await this.$store.dispatch('patterns/storePatternPicture', {
            file: sizeGuideFile,
            patternId: patternId,
          });
        }

        const imageInterval = setInterval(() => {
          if (!this.resizingPicture) {
            clearInterval(imageInterval);

            if (this.resizingPictureError) {
              this.$store.dispatch('toasts/setToast', {
                type: 'error',
                content: this.$t('toasts.pictureError'),
              });
            } else {
              this.$store.dispatch('toasts/setToast', {
                type: 'success',
                content: this.$t('toasts.createPatternSuccess'),
              });
            }

            this.modalResolve(true);
          }
        }, 1000);
      } catch (e) {
        this.$store.dispatch('toasts/setToast', {
          type: 'error',
          content: this.$t('toasts.createPatternError'),
        });
        this.submited = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
