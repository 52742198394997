<template>
  <div class="input-container">
    <label class="label">{{ label }}</label>
    <div
      class="item"
      v-for="(item, index) in items.length"
      :key="`${index}_${new Date().getTime()}`"
    >
      <Select
        class="select"
        :options="options"
        v-model="items[index]"
        :placeholder="placeholder"
        optionName="name"
      />
      <button
        class="action-button secondary-button delete"
        v-if="items[index] && index !== items.length - 1"
        @click="deleteItem(items[index])"
      >
        <Trash />
      </button>
    </div>
  </div>
</template>

<script>
import Select from '@/components/inputs/Select.vue';
import Trash from '@/assets/svg/poubelle.svg';

export default {
  name: 'MultipleSelectInput',
  components: {
    Select,
    Trash,
  },
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [null],
    };
  },
  created() {
    if (this.modelValue.length) {
      this.items = this.modelValue;
    }
  },
  watch: {
    items: {
      deep: true,
      handler(items) {
        this.$emit('update:modelValue', items);
        if (items[items.length - 1]) {
          this.addItem();
        }
      },
    },
  },
  methods: {
    addItem() {
      this.items.push(null);
    },
    deleteItem(id) {
      this.items = this.items.filter((item) => item !== id);
      this.$emit('update:modelValue', this.items);
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  .select {
    width: 100%;
  }

  .action-button {
    width: 56px;
    padding: unset;

    svg {
      margin: 0;
    }

    &.delete {
      border-color: $red-color;
      color: $red-color;
    }
  }
}
</style>
