<template>
  <div class="input-container">
    <label class="label">{{ label }}</label>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in items"
        :key="item.content"
        :class="{ checked: item.checked, placeholder: !item.content }"
      >
        <div class="checkbox" @click="checkItem(index)"></div>
        <input
          type="text"
          v-model.lazy="item.content"
          :placeholder="$t('listPlaceholder')"
          @focus="addItem(index)"
          :disabled="disabled"
          v-if="!disabled"
        />
        <p v-else class="content">{{ item.content }}</p>
        <Cross class="delete" v-if="item.content && !disabled" @click="deleteItem(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import Cross from '@/assets/svg/cross.svg';

export default {
  name: 'CheckListInput',
  components: {
    Cross,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'checkItem'],
  computed: {
    items: {
      get() {
        if (this.disabled) {
          return this.modelValue.filter((item) => item.content);
        } else {
          return this.modelValue;
        }
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        return newValue;
      },
    },
  },
  created() {
    if (!this.disabled) {
      this.addItem(this.modelValue.length - 1);
    }
  },
  methods: {
    addItem(index) {
      if (index === this.items.length - 1) {
        this.items.push({ content: '', checked: false });
        this.$emit('update:modelValue', this.items);
      }
    },
    deleteItem(item) {
      this.items = this.items.filter((i) => i.content !== item.content);
    },
    checkItem(index) {
      this.items[index].checked = !this.items[index].checked;
      if (this.disabled) {
        this.$emit('checkItem');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  @include input;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
    border: 2px solid var(--primary-color);

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--primary-color);
      opacity: 0;
      transition: 0.3s;
    }
  }

  input {
    appearance: none;
    border: none;
    background: none;
    color: var(--primary-color);
    font-size: 16px;
    flex: 1;
    margin-right: 4px;

    &:disabled {
      opacity: 1;
    }
  }

  .content {
    color: var(--primary-color);
    font-size: 16px;
  }

  .delete {
    width: 16px;
    margin-left: auto;
    transform: rotate(45deg);
  }

  &.checked {
    input,
    .content {
      text-decoration-line: line-through;
    }

    .checkbox {
      &::after {
        opacity: 1;
      }
    }
  }

  &.placeholder {
    input {
      color: mix($text-color, $white-color, 30%);
    }

    .checkbox {
      border-color: mix($text-color, $white-color, 30%);
    }
  }

  &:focus,
  &.placeholder:focus-within {
    input {
      color: var(--primary-color);
    }

    .checkbox {
      border-color: var(--primary-color);
    }
  }
}
</style>
